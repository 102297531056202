import { useRef, useState } from 'react'

import { gql, useQuery } from '@apollo/client'
import { FormControlLabel, Switch, FormHelperText } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'

import { Controller } from 'react-hook-form'

import TurndownService from 'turndown'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { TemplateButton } from 'components/cases/report/text_entry/TemplateButton'
import LiquidMarkdownEditor from 'components/library/LiquidMarkdownEditor'
import { EditorApi } from 'components/library/TypeAheadEditor'
import TypeAheadEditor from 'components/library/TypeAheadEditor/TypeAheadEditor'

import { useAutomationAdminOperations } from '../../../hooks/AutomationAdminHelpers'

import { DOMAIN_TYPE_SMART_VALUES } from '../smartValues'

import SmartValuesMenu from './SmartValuesMenu'

import {
  NarrativePrepopulationTemplatesQuery,
  NarrativePrepopulationTemplatesQueryVariables,
} from './__generated__/NarrativePrepopulation.generated'

import type { FormSchemaReturnType } from '../../formSchema'

import type { Api as LiquidEditorApi } from 'components/library/LiquidMarkdownEditor'
import type { Theme } from 'types/hb'

const NARRATIVE_TEMPLATES_QUERY = gql`
  query NarrativePrepopulationTemplates {
    narrativeTemplates {
      token
      name
      content
    }
  }
`

// Caution: Do not change the tag here without also changing `RichText` in the
// backend.
const isRichText = /^<body data-hb-rich-text="true">[\s\S]+<\/body>$/
const forWhat = new TurndownService({ bulletListMarker: '-' })

const useStyles = makeStyles((theme: Theme) => ({
  narrative: {
    padding: theme.spacing(2, 2, 4, 2),
  },
  narrativePlaceholder: {
    top: 56,
  },
  smartValuesMenuButton: {
    padding: theme.spacing(0, 1),
  },
  smartValuesToggleBar: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  templateButton: {
    position: 'relative',
    bottom: theme.spacing(1),
  },
}))

export default function NarrativePrepopulation({
  paramsPath,
  form,
}: {
  paramsPath: string
  form: FormSchemaReturnType
}) {
  const classes = useStyles()

  const { watch, getValues } = form
  const domainType = watch('domain.type')
  const ruleOrgToken = getValues('organizationToken')
  const isAdminAutomationRule = useAutomationAdminOperations(ruleOrgToken) && !!ruleOrgToken

  // TODO(KP): smarter types than `string` cause tsc to OOM
  const smartValuesEnabled = watch(`${paramsPath}.smartValuesEnabled` as any)
  const smartValuesMenuButtonRef = useRef<HTMLButtonElement>(null)
  const [smartValuesMenuOpen, setSmartValuesMenuOpen] = useState(false)

  const variables = domainType ? [DOMAIN_TYPE_SMART_VALUES[domainType]] : []
  const menuVariables = domainType
    ? DOMAIN_TYPE_SMART_VALUES[domainType].properties
        .filter((p) => p.type === 'value')
        .map((p) => ({
          label: p.label,
          insert: `{{${DOMAIN_TYPE_SMART_VALUES[domainType].name}.${p.name}}}`,
        }))
    : []

  const liquidEditorRef = useRef<LiquidEditorApi>(null)
  const editorApi = useRef<EditorApi>(null)

  function onSelectSmartValue(value: string) {
    liquidEditorRef.current?.replaceSelection(value)
    setSmartValuesMenuOpen(false)
  }

  const narrativeTemplateRes = useQuery<
    NarrativePrepopulationTemplatesQuery,
    NarrativePrepopulationTemplatesQueryVariables
  >(NARRATIVE_TEMPLATES_QUERY)

  return (
    <>
      {smartValuesEnabled ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <HbButton
            ref={smartValuesMenuButtonRef}
            label={
              <HbText bold size="s">
                Smart values
              </HbText>
            }
            variant="textSecondary"
            dropdownCaret
            onClick={() => setSmartValuesMenuOpen((o) => !o)}
            className={classes.smartValuesMenuButton}
          />
          <SmartValuesMenu
            anchorEl={smartValuesMenuButtonRef.current}
            open={smartValuesMenuOpen}
            onClose={() => setSmartValuesMenuOpen(false)}
            variables={menuVariables}
            onSelect={(entry) => {
              onSelectSmartValue(entry.insert)
              setSmartValuesMenuOpen(false)
            }}
          />
        </Box>
      ) : null}
      <Controller
        name={`${paramsPath}.narrative`}
        render={({ field: { value, onChange } }) =>
          smartValuesEnabled && !isAdminAutomationRule ? (
            <>
              <Box
                sx={(theme) => ({
                  borderRadius: theme.spacing(0.5),
                  padding: theme.spacing(1),
                  border: `1px solid ${theme.palette.styleguide.borderDark}`,
                  margin: theme.spacing(0.5, 0),
                })}
              >
                <LiquidMarkdownEditor
                  ref={liquidEditorRef}
                  initialValue={isRichText.test(value) ? forWhat.turndown(value) : value}
                  variables={variables}
                  domain={domainType}
                  onChange={onChange}
                />
              </Box>
              <FormHelperText>
                {smartValuesEnabled && !isAdminAutomationRule ? (
                  <>
                    Add smart values between {'{{ and }}'}.{' '}
                    <a
                      href="https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta/automations/using-smart-values-in-your-automations"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more about Smart Values
                    </a>
                  </>
                ) : null}
              </FormHelperText>
            </>
          ) : (
            <TypeAheadEditor
              editViewClassName={classes.narrative}
              placeholderClassName={classes.narrativePlaceholder}
              initialValue={value}
              onChange={onChange}
              placeholder="Write a narrative..."
              richText
              ref={editorApi}
            />
          )
        }
      />
      <Box
        sx={(theme) => ({ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: theme.spacing(1) })}
      >
        {smartValuesEnabled ? null : (
          <TemplateButton
            templates={narrativeTemplateRes.data?.narrativeTemplates ?? []}
            onSelect={(template) => {
              const parsed = new DOMParser().parseFromString(template.content, 'text/html')
              const valueIsRichText = parsed.body.dataset.hbRichText === 'true'

              if (valueIsRichText) {
                editorApi.current?.insertHtml(template.content)
              } else {
                editorApi.current?.insertText(`${template.content}\n`)
              }
            }}
            disabled={(narrativeTemplateRes.data?.narrativeTemplates.length ?? 0) === 0}
            loading={narrativeTemplateRes.loading}
            className={classes.templateButton}
          />
        )}
        <Controller
          name={`${paramsPath}.smartValuesEnabled`}
          render={({ field }) => (
            <FormControlLabel
              className={classes.smartValuesToggleBar}
              control={
                <Switch
                  onChange={(_, val) => field.onChange(val)}
                  onBlur={() => field.onBlur()}
                  checked={field.value}
                />
              }
              labelPlacement="start"
              label={<HbText bold>Smart values</HbText>}
            />
          )}
        />
      </Box>
    </>
  )
}
