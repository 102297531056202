import React, { ReactNode, useContext, useEffect, useImperativeHandle, useState } from 'react'

import { AddRounded, EditRounded } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { useFormikContext } from 'formik'

import { useSelector, useDispatch } from 'actions/store'

import { usePendingChanges } from 'components/cases/Tabs/PendingChanges'
import { FormConfigurationContext } from 'components/material/Form/FormConfiguration'
import { canEditFormContext, setEditingFormContext } from 'helpers/stateHelpers'
import { Theme } from 'types/hb'

import { HbButton, Props as HbButtonProps } from '../HbComponents/HbButton'

export type Mode = 'read' | 'edit'

export interface EditModeParams {
  mode: Mode
}

export interface Props {
  saveDisabled?: boolean
  save: () => void
  addItem?: () => void
  canAdd?: boolean
  addLabel?: string
  displayAddWhenEmpty?: boolean
  autoAddNew?: boolean
  isEmpty?: boolean
  name?: string
  className?: string
  children: (params: EditModeParams) => ReactNode
}

export interface EditModeRef {
  setMode: (mode: Mode) => void
}

const useStyles = makeStyles((theme: Theme) => {
  const background = theme.palette.styleguide.backgroundLight
  return {
    root: {
      overflow: 'hidden',
      padding: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      position: 'relative',
      '&$rootCanEdit:hover': {
        background,
        '& $readModeButton': {
          display: 'flex',
        },
        '& $readModeButtons::before': {
          visibility: 'visible',
        },
      },
    },
    rootEditMode: {
      background,
    },
    rootCanEdit: {},
    editModeButtons: {
      display: 'flex',
      marginTop: theme.spacing(2),
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      '& > div': {
        display: 'flex',
        gap: theme.spacing(2),
      },
    },
    readModeButton: {},
    readModeButtons: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 0,
      padding: theme.spacing(1.25, 1.25, 0, 0),
      alignItems: 'flex-start',
      gap: theme.spacing(1),
      '&::before': {
        visibility: 'hidden',
        content: "''",
        position: 'absolute',
        inset: theme.spacing(-3, -3, -1, -1),
        background,
        borderRadius: '0 0 0 8px',
        boxShadow: `-16px 16px 16px ${background}`,
      },
      '& > button': {
        height: 'fit-content',
        display: 'none',
      },
    },
    fitContent: {
      width: 'fit-content',
    },
  }
})

function AddNewButton(props: Pick<HbButtonProps, 'label' | 'onClick' | 'className' | 'size' | 'testId'>) {
  return <HbButton Icon={AddRounded} variant="textPrimary" {...props} />
}

const Buttons = (
  props: Props & { classes: ReturnType<typeof useStyles>; mode: Mode; toggleMode: () => void; setRead: () => void }
) => {
  const { addItem, canAdd, addLabel, saveDisabled, save, isEmpty, classes, mode, toggleMode, setRead } = props
  if (mode === 'edit') {
    return (
      <div className={classes.editModeButtons}>
        {!!addItem && canAdd ? <AddNewButton label={addLabel} onClick={addItem} /> : <div />}
        <div>
          <HbButton label="Cancel" variant="textPrimary" disabled={saveDisabled} onClick={setRead} />
          <HbButton label="Save" disabled={saveDisabled} onClick={save} />
        </div>
      </div>
    )
  }
  if (mode === 'read') {
    return (
      <div className={classes.readModeButtons}>
        {!!addItem && canAdd && (
          <AddNewButton
            className={classes.readModeButton}
            key="add-new-read-mode"
            onClick={() => {
              if (!isEmpty && addItem) {
                addItem()
              }
              toggleMode()
            }}
            size="small"
            testId="add"
            label={addLabel}
          />
        )}
        <HbButton
          className={classes.readModeButton}
          onClick={toggleMode}
          variant="textPrimary"
          size="small"
          testId="edit"
          Icon={EditRounded}
          label="Edit"
        />
      </div>
    )
  }
  return null
}

function EditModeInternal(props: Props, ref: React.Ref<EditModeRef>) {
  const {
    className,
    addItem,
    canAdd = true,
    addLabel = 'Add new',
    displayAddWhenEmpty,
    autoAddNew = true,
    isEmpty,
    name,
    children,
  } = props
  const classes = useStyles()
  const { resetForm } = useFormikContext()

  const dispatch = useDispatch()
  const { pendingChanges, clearPendingChanges } = usePendingChanges<Record<string, any>>()

  const [mode, setMode] = useState<Mode>(name && pendingChanges?.[name] ? 'edit' : 'read')

  const formContext = useContext(FormConfigurationContext)
  const stateCanEdit = useSelector((state) => canEditFormContext(state, formContext.contextName))
  const canEdit = stateCanEdit && !formContext.readOnly

  useEffect(() => {
    if (name && pendingChanges?.[name]) {
      dispatch(setEditingFormContext(formContext.contextName, true))
      setMode('edit')
    }
  }, [dispatch, formContext, name, pendingChanges])

  const setModeEx = (newMode: Mode) => {
    if (canEdit || newMode === 'read') {
      dispatch(setEditingFormContext(formContext.contextName, newMode === 'edit'))

      // If there are no items, add one when switching to edit mode
      if (autoAddNew && newMode === 'edit' && addItem && isEmpty) {
        addItem()
      }

      setMode(newMode)
    }
  }

  useImperativeHandle(ref, () => ({
    setMode: setModeEx,
  }))

  const toggleMode = () => {
    setModeEx(mode === 'read' ? 'edit' : 'read')
  }

  const setRead = () => {
    resetForm()
    setModeEx('read')
    clearPendingChanges()
  }

  // Store mode and context in ref to ensure cleanup function only runs on unmount instead of dependency changes
  const canEditCleanupRef = React.useRef({ contextName: formContext.contextName, mode })
  useEffect(() => {
    canEditCleanupRef.current = { contextName: formContext.contextName, mode }
  }, [formContext.contextName, mode])

  // if unmounting in edit mode, ensure form active state is cleared from redux so that edit mode is triggerable on remount.
  useEffect(() => {
    return () => {
      if (canEditCleanupRef.current.mode === 'edit') {
        dispatch(setEditingFormContext(canEditCleanupRef.current.contextName, false))
      }
    }
  }, [dispatch])

  // If we can add new items, empty read mode with displayAddWhenEmpty should show the add button
  if (mode === 'read' && !!addItem && canAdd && isEmpty && displayAddWhenEmpty) {
    return <AddNewButton className={classes.fitContent} label={addLabel} onClick={toggleMode} />
  }

  return (
    <div
      className={classnames(className, classes.root, {
        [classes.rootCanEdit]: canEdit,
        [classes.rootEditMode]: mode === 'edit',
      })}
      data-testid={name && `editable_${name}`}
    >
      {children({ mode })}
      <Buttons
        {...{ ...props, canAdd, addLabel }}
        mode={mode}
        toggleMode={toggleMode}
        setRead={setRead}
        classes={classes}
      />
    </div>
  )
}

export const EditMode = React.forwardRef(EditModeInternal)
