// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { type State, useSelector } from 'actions/store'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { getOrganizationFeatureFlag } from 'helpers/stateHelpers'
import { AutomationActionType, AutomationDomainType, AutomationRuleType, FeatureFlag } from 'types/api'
import { Theme } from 'types/hb'

import { RecipeStepIcon } from '../AutomationIcon'

import { ActionCard } from './ActionCard'

type Action = {
  title: string
  actionType: AutomationActionType
  description: string
  featureFlag?: FeatureFlag
  domainTypes: Array<AutomationDomainType>
  automationTypes: Array<AutomationRuleType>
}

// TODO: we should be able to infer this from the backend via config.rb
const allActions: Array<Action> = [
  {
    title: 'Add Custom Column to the Dashboard',
    actionType: AutomationActionType.WriteOtherInfo,
    description: 'Add a custom column to the case dashboard.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Assign Review to Queue',
    actionType: AutomationActionType.AssignReviewToQueue,
    description: 'Assign a review to a queue.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Cancel Review',
    actionType: AutomationActionType.CancelReview,
    description: 'Cancel the review.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Comment on Case',
    actionType: AutomationActionType.Comment,
    description: 'Comment on the associated case.',
    domainTypes: [AutomationDomainType.Case, AutomationDomainType.Review, AutomationDomainType.Filing],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Complete Review',
    actionType: AutomationActionType.CompleteReview,
    description: 'Complete the review.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Create Case',
    actionType: AutomationActionType.CreateInvestigation,
    description: 'Create a case that includes the associated subject.',
    domainTypes: [AutomationDomainType.Person, AutomationDomainType.Business, AutomationDomainType.Datasource],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Create Review',
    actionType: AutomationActionType.CreateReview,
    description: 'Create a review for the associated case.',
    domainTypes: [AutomationDomainType.Case, AutomationDomainType.Review, AutomationDomainType.Datasource],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Decision Review Action',
    actionType: AutomationActionType.WorkflowActionReview,
    description: 'Make a decision for a given action on the review.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger],
  },
  {
    title: 'Digest Notification',
    actionType: AutomationActionType.Notification,
    description: 'Notify users with given badges.',
    domainTypes: [
      AutomationDomainType.Case,
      AutomationDomainType.Review,
      AutomationDomainType.Filing,
      AutomationDomainType.MiddeskBusiness,
    ],
    automationTypes: [AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Do Nothing',
    actionType: AutomationActionType.NoOp,
    description: 'Log when this recipe would have executed an action.',
    domainTypes: [
      AutomationDomainType.Business,
      AutomationDomainType.Case,
      AutomationDomainType.Datasource,
      AutomationDomainType.Filing,
      AutomationDomainType.MiddeskBusiness,
      AutomationDomainType.Review,
      AutomationDomainType.Person,
    ],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Google Sheets',
    actionType: AutomationActionType.GoogleSheets,
    description: 'Send data to Google Sheets',
    domainTypes: [AutomationDomainType.Datasource],
    automationTypes: [AutomationRuleType.Trigger],
  },
  {
    title: 'Lock Case',
    actionType: AutomationActionType.LockInvestigation,
    description: 'Lock the case.',
    domainTypes: [AutomationDomainType.Review, AutomationDomainType.Filing, AutomationDomainType.Case],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Notification',
    actionType: AutomationActionType.Notification,
    description: 'Notify users with given badges.',
    domainTypes: [
      AutomationDomainType.Case,
      AutomationDomainType.Review,
      AutomationDomainType.Filing,
      AutomationDomainType.MiddeskBusiness,
      AutomationDomainType.Datasource,
    ],
    automationTypes: [AutomationRuleType.Trigger],
  },
  {
    title: 'Open Review',
    actionType: AutomationActionType.OpenReview,
    description: 'Open the review.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    // @robocop: Scheduled automations only support a single type of action right now
    title: 'Review Digest Notification',
    actionType: AutomationActionType.ReviewDigestNotification,
    description: 'Send a review digest with statistics on reviews handled in the last 7 days',
    domainTypes: [AutomationDomainType.Case],
    automationTypes: [AutomationRuleType.Schedule],
  },
  {
    title: 'Snooze Review',
    actionType: AutomationActionType.SnoozeReview,
    description: 'Snooze the review.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Tag Subject',
    actionType: AutomationActionType.Tag,
    description: 'Tag the associated subject with the given tag.',
    domainTypes: [AutomationDomainType.Person, AutomationDomainType.Business],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Unlock Case',
    actionType: AutomationActionType.UnlockInvestigation,
    description: 'Unlock the case.',
    domainTypes: [AutomationDomainType.Filing, AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger],
  },
  {
    title: 'Update Other Info',
    actionType: AutomationActionType.WriteOtherInfo,
    description: 'Update other info values.',
    domainTypes: [AutomationDomainType.Review, AutomationDomainType.Business, AutomationDomainType.Person],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
  {
    title: 'Update Review Due Date',
    actionType: AutomationActionType.UpdateReviewDueDate,
    description: 'Update due date on the review.',
    domainTypes: [AutomationDomainType.Review],
    automationTypes: [AutomationRuleType.Trigger, AutomationRuleType.ScheduleWithTrigger],
  },
]

interface Props {
  onClose: () => void
  open: boolean
  onSelectAction: (actionType: AutomationActionType) => unknown
  automationType?: AutomationRuleType
  domainType?: AutomationDomainType
}

// TODO: make this only return Array<AutomationActionType>
export function getAvailableTriggerActions(state: State): Array<Action> {
  return allActions.filter(
    (a) =>
      (!a.featureFlag || getOrganizationFeatureFlag(state, a.featureFlag)) &&
      !a.automationTypes.includes(AutomationRuleType.Schedule)
  )
}

export function getAvailableActionsForDomain(
  state: State,
  domainType: AutomationDomainType,
  automationType: AutomationRuleType
): Array<Action> {
  return getAvailableTriggerActions(state).filter(
    (a) => a.domainTypes.includes(domainType) && a.automationTypes.includes(automationType)
  )
}

export function getAvailableScheduleActionsForSchedule(state: State): Array<Action> {
  return allActions.filter(
    (a) =>
      (!a.featureFlag || getOrganizationFeatureFlag(state, a.featureFlag)) &&
      a.automationTypes.includes(AutomationRuleType.Schedule)
  )
}

export function getAvailableActionsForSchedule(state: State, domainType: AutomationDomainType): Array<Action> {
  return getAvailableScheduleActionsForSchedule(state).filter((a) => a.domainTypes.includes(domainType))
}

export const canSelectNewActionForDomainType = (
  state: State,
  domain: AutomationDomainType | null | undefined,
  automationType: AutomationRuleType | null | undefined
) => !!(domain && automationType) && getAvailableActionsForDomain(state, domain, automationType).length > 1

const useDialogStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'auto',
    gap: theme.spacing(1),
  },
}))

export default function SelectActionDialog({ open, automationType, onClose, onSelectAction, domainType }: Props) {
  const styles = useDialogStyles()

  const scheduleActions = useSelector((state) =>
    getAvailableActionsForSchedule(state, domainType || AutomationDomainType.Case)
  )
  const triggerActions = useSelector((state) =>
    getAvailableActionsForDomain(
      state,
      domainType || AutomationDomainType.Case,
      automationType || AutomationRuleType.Trigger
    )
  )

  const availableActions =
    automationType === AutomationRuleType.Trigger || automationType === AutomationRuleType.ScheduleWithTrigger
      ? triggerActions
      : scheduleActions

  return (
    <HbDialog
      id="select-action-dialog"
      confirmVariant="warning"
      loading={false}
      open={open}
      onClose={onClose}
      title="Select Action"
      actions={<>{null}</>}
      content={
        <div className={styles.content}>
          {availableActions.map((act) => (
            <ActionCard
              key={act.actionType}
              title={act.title}
              description={act.description}
              onClick={() => onSelectAction(act.actionType)}
              icon={<RecipeStepIcon type={act.actionType} size={6} />}
            />
          ))}
        </div>
      }
    />
  )
}
