import { styled } from '@mui/material'

import classnames from 'classnames'

import { NumberInputV2 } from 'components/HbComponents/Form/Inputs/NumberInputV2'
import { SelectDropdownInput } from 'components/HbComponents/Form/Inputs/SelectDropdownInput'
import { useInlineFieldStyles } from 'components/entities/Edit/FieldEdit'
import { LabeledField, SelectField, NumberField, TextField, DateField } from 'components/material/Form'
import { useFeatureFlag } from 'hooks'
import { CustomFieldDatatypeEnum, FeatureFlag } from 'types/api'

import { FormikOtherInfoDatePicker } from './FormikOtherInfoDatePicker'
import { FormikOtherInfoTextArea } from './FormikOtherInfoTextArea'

const yesnoOptions = [
  { display: 'Yes', value: 'yes' },
  { display: 'No', value: 'no' },
]

// NOTE: the following have styles applied in SuperInfoTransactionFormDialog
export const StyledSelectDropdownInput = styled(SelectDropdownInput)(() => ({}))
export const StyledNumberInputV2 = styled(NumberInputV2)(() => ({}))

interface CustomFieldValueProps {
  label: string
  inputName: string
  datatype?: CustomFieldDatatypeEnum | null
  options?: string[] | null
  disabled?: boolean
  hideLabel?: boolean
  labeledFieldVariant?: boolean
}

export const CustomFieldValue = ({
  inputName,
  label,
  datatype,
  options,
  disabled,
  hideLabel,
  labeledFieldVariant,
}: CustomFieldValueProps) => {
  const dropdownOptions = (options || []).map((l) => {
    return { display: l, value: l }
  })
  const customFieldDatatypesEnabled = useFeatureFlag(FeatureFlag.SuperInfoDataTypes)
  const { fill, second } = useInlineFieldStyles()
  const labeledFieldVariantClasses = { root: classnames(fill, second) }

  if (customFieldDatatypesEnabled && datatype === 'date') {
    if (labeledFieldVariant) {
      return (
        <LabeledField
          classes={{ root: classnames(fill, second) }}
          FieldComponent={DateField}
          name={inputName}
          placeholder="Value"
          fullWidth
          disabled={disabled}
          dateOnly
        />
      )
    }
    return <FormikOtherInfoDatePicker name={inputName} label={label} disabled={disabled} />
  }

  if (customFieldDatatypesEnabled && datatype === 'dropdown') {
    if (labeledFieldVariant) {
      return (
        <LabeledField
          name={inputName}
          FieldComponent={SelectField}
          classes={labeledFieldVariantClasses}
          options={dropdownOptions}
          placeholder="Select one..."
          disabled={disabled}
        />
      )
    }
    return (
      <StyledSelectDropdownInput
        key={label}
        label={label}
        name={inputName}
        options={dropdownOptions}
        disabled={disabled}
      />
    )
  }

  if (customFieldDatatypesEnabled && datatype === 'yesno') {
    if (labeledFieldVariant) {
      return (
        <LabeledField
          name={inputName}
          FieldComponent={SelectField}
          classes={labeledFieldVariantClasses}
          options={yesnoOptions}
          placeholder="Select one..."
          disabled={disabled}
        />
      )
    }
    return (
      <StyledSelectDropdownInput
        key={label}
        label={label}
        name={inputName}
        options={yesnoOptions}
        disabled={disabled}
      />
    )
  }

  if (customFieldDatatypesEnabled && datatype === 'number') {
    if (labeledFieldVariant) {
      return (
        <LabeledField
          name={inputName}
          FieldComponent={NumberField}
          classes={labeledFieldVariantClasses}
          disabled={disabled}
        />
      )
    }
    return <StyledNumberInputV2 key={label} label={label} name={inputName} disabled={disabled} />
  }

  if (labeledFieldVariant) {
    return (
      <LabeledField
        name={inputName}
        FieldComponent={TextField}
        classes={labeledFieldVariantClasses}
        placeholder="Value"
        disabled={disabled}
      />
    )
  }
  return <FormikOtherInfoTextArea label={label} name={inputName} disabled={disabled} hideLabel={hideLabel} />
}
