import CustomizeAssignReviewToQueueAction from './actions/CustomizeAssignReviewToQueueAction'
import CustomizeCancelReviewAction from './actions/CustomizeCancelReviewAction'
import CustomizeCommentAction from './actions/CustomizeCommentAction'
import CustomizeCreateInvestigationAction from './actions/CustomizeCreateInvestigationAction'
import CustomizeCreateReviewAction from './actions/CustomizeCreateReviewAction'
import CustomizeGoogleSheetsAction from './actions/CustomizeGoogleSheetsAction'
import CustomizeNonConfigurableAction from './actions/CustomizeNonConfigurableAction'
import CustomizeNotificationAction from './actions/CustomizeNotificationAction'
import CustomizeSnoozeReviewAction from './actions/CustomizeSnoozeReviewAction'
import CustomizeTagAction from './actions/CustomizeTagAction'
import CustomizeUpdateReviewDueDateAction from './actions/CustomizeUpdateReviewDueDateAction'
import CustomizeWorkflowActionReview from './actions/CustomizeWorkflowActionReviewAction'
import CustomizeWriteOtherInfoAction from './actions/CustomizeWriteOtherInfoAction'

import type { FormSchemaReturnType } from './formSchema'
import type { AutomationActionType } from 'types/api'

export function ActionConfig({ actionType, form }: { actionType: AutomationActionType; form: FormSchemaReturnType }) {
  switch (actionType) {
    case 'notification':
    case 'review_digest_notification':
      return <CustomizeNotificationAction form={form} />
    case 'google_sheets':
      return <CustomizeGoogleSheetsAction />
    case 'comment':
      return <CustomizeCommentAction />
    case 'tag':
      return <CustomizeTagAction form={form} />
    case 'create_investigation':
      return <CustomizeCreateInvestigationAction form={form} />
    case 'create_review':
      return <CustomizeCreateReviewAction form={form} />
    case 'cancel_review':
      return <CustomizeCancelReviewAction />
    case 'complete_review':
    case 'lock_investigation':
    case 'unlock_investigation':
    case 'no_op':
    case 'open_review':
      return <CustomizeNonConfigurableAction />
    case 'snooze_review':
      return <CustomizeSnoozeReviewAction form={form} />
    case 'assign_review_to_queue':
      return <CustomizeAssignReviewToQueueAction form={form} />
    case 'update_review_due_date':
      return <CustomizeUpdateReviewDueDateAction form={form} />
    case 'write_other_info':
      return <CustomizeWriteOtherInfoAction form={form} />
    case 'workflow_action_review':
      return <CustomizeWorkflowActionReview form={form} />
    default:
      return null
  }
}
