import React from 'react'

import { AddRounded } from '@mui/icons-material'
import { BoxProps, Box } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButton, Props as HbButtonProps } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useToggle } from 'hooks'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => {
  const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  return {
    root: {
      ...flexCenter,
      flex: 1,
      maxWidth: 600,
      textAlign: 'center',
      flexDirection: 'column',
      textWrap: 'balance',
      boxSizing: 'border-box',
      margin: 'auto',
    },
    iconWrapper: {
      ...flexCenter,
      width: 50,
      height: 50,
      mixBlendMode: 'multiply',
      background: theme.palette.background.medium,
      marginBottom: theme.spacing(2),
      borderRadius: '50%',
    },
    icon: {
      color: theme.palette.text.secondary,
      width: 18,
      height: 18,
    },
    details: {
      whiteSpace: 'pre-line',
      marginTop: theme.spacing(2),
    },
    message: {
      marginTop: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(3),
    },
  }
})

export type SharedProps = {
  title: string
  message: string
  details?: string
  Icon?: React.ComponentType<{ className?: string }>
  startOpen?: boolean
} & Pick<BoxProps, 'padding'>

export type WithButtonProps = {
  buttonLabel: string
  onButtonClick?: (event?: React.MouseEvent) => void
  ButtonIcon?: HbButtonProps['Icon']
  noButtonIcon?: boolean
  buttonRef?: React.MutableRefObject<HTMLButtonElement | null>
}

export type WithoutButonProps = { [Property in keyof WithButtonProps]?: undefined }

export type HbNonIdealStateProps = SharedProps & (WithButtonProps | WithoutButonProps)

export function HbNonIdealState(props: HbNonIdealStateProps) {
  const {
    Icon,
    title,
    details,
    message,
    buttonLabel,
    onButtonClick,
    ButtonIcon,
    noButtonIcon,
    buttonRef,
    padding = 2,
    startOpen = false,
  } = props
  const classes = useStyles()
  const { value: showDetails, toggle: toggleShowDetails } = useToggle(startOpen)
  return (
    <Box className={classes.root} padding={padding}>
      {Icon && (
        <div className={classes.iconWrapper}>
          <Icon className={classes.icon} />
        </div>
      )}
      <HbText tag="h2" bold block>
        {title}
      </HbText>
      {message ? (
        <HbText className={classes.message} color="secondary" block>
          {message}
        </HbText>
      ) : null}
      {details ? (
        <Box mt={2}>
          <HbButton
            size="small"
            variant="textPrimary"
            onClick={toggleShowDetails}
            label={showDetails ? 'Hide details' : 'Show details'}
          />
          {showDetails && (
            <HbText className={classes.details} color="secondary" block>
              {details}
            </HbText>
          )}
        </Box>
      ) : null}
      {onButtonClick && buttonLabel && (
        <HbButton
          className={classes.button}
          variant="primary"
          label={buttonLabel}
          Icon={noButtonIcon ? undefined : ButtonIcon || AddRounded}
          onClick={onButtonClick}
          ref={buttonRef}
        />
      )}
    </Box>
  )
}
