import React, { Suspense } from 'react'

import { ThemeProvider, StyledEngineProvider, styled } from '@mui/material'
import { ThemeProvider as StylesThemeProvider } from '@mui/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { Redirect, Switch } from 'react-router-dom'

import ErrorBoundary from 'components/ErrorBoundary'
import { Feature } from 'components/Feature'
import { PageLoader } from 'components/HbComponents/PageLoader'
import { AttachmentViewerWithOverlay } from 'components/attachment/AttachmentViewerWithOverlay'
import { AttachmentSummarySnackbars } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummarySnackbars'
import { InvestigationDownloadDocument } from 'components/cases/report/summary/GenerateDocument'
import { YamlKitchen } from 'components/editor/YamlKitchen'
import { useIsCustomerDomain } from 'components/external/useIsCustomerDomain'
import { LoginBanner } from 'components/header/globals/LoginBanner'
import { theme as redesignTheme } from 'components/themeRedesign'
import { useBatchActionsSnackbars } from 'dashboards/shared/hooks/useBatchActions'
import { UsageArea } from 'helpers/SessionTracking/UsageTracker'
import { FeatureFlag } from 'types/api'
import { Theme } from 'types/hb'

import { useBroadcastChannelMessageHandler } from 'utils/broadcastChannel'

import { useIntercom } from 'utils/intercom'

import { useWootricIntegration } from 'utils/wootricIntegration'

import { HbNotistackProvider } from './HbComponents/HbNotistack/HbNotistackProvider'
import useReduxSnackbars from './HbComponents/HbNotistack/useReduxSnackbars'
import MultiFactorAuth from './auth/MultiFactorAuth'
import SignIn from './auth/SignIn'

import { MiddeskSnackbars } from './cases/Tabs/Middesk/MiddeskSnackbars'
import { ScreeningSnackbars } from './cases/Tabs/SanctionsScreening/ScreeningSnackbars'
import { AppNav } from './header/AppNav'
import { HbRoute } from './library/Page/HbRoute'
import WelcomeDialog from './onboarding/account/WelcomeDialog'
import { DownloadsPage } from './pages/DownloadsPage'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// for AuthenticatedRoutes
const AlertSuppressionRulePage = React.lazy(() => import('components/pages/AlertSuppressionRulePage'))
const AutomationsPage = React.lazy(() => import('./pages/automations/AutomationsPage'))
const AppsPage = React.lazy(() => import('./pages/apps/AppsPage'))
const Case = React.lazy(() => import('./cases/Case'))
const CaseReview = React.lazy(() => import('./cases/CaseReview'))
const DatasourceListPage = React.lazy(() => import('./pages/datasources/DatasourceListPage'))
const DatasourcePage = React.lazy(() => import('./pages/datasources/DatasourcePage'))
const Dashboards = React.lazy(() => import('./dashboard/Dashboards'))
const HbGraphiql = React.lazy(() => import('./Graphiql'))
const LibraryObjectPage = React.lazy(() => import('components/entities/LibraryObject/LibraryObjectPage'))
const OrganizationAlertPage = React.lazy(() => import('components/pages/OrganizationAlertPage'))
const SearchPage = React.lazy(() => import('./library/Search/SearchPage'))
const Settings = React.lazy(() => import('./settings/Settings'))
const Subprocessors = React.lazy(() => import('./pages/subprocessors/SubprocessorsPage'))

// for non-AuthenticatedRoutes
const InformationRequest = React.lazy(() => import('./external/information_requests/InformationRequestRecipientPage'))
const OnboardAccount = React.lazy(() => import('./onboarding/account/OnboardAccount'))
const RequestForInformation = React.lazy(() => import('./external/rfi/RequestForInformationForm'))
const ResetPassword = React.lazy(() => import('./auth/ResetPassword'))
const TipIntakeFormFromQuery = React.lazy(() => import('./external/tips/TipIntakeFormFromQuery'))
const UpdatePassword = React.lazy(() => import('./auth/UpdatePassword'))
const CustomDomainRouter = React.lazy(() => import('./external/CustomDomainRouter'))

const Parent = styled('div')({
  height: '100%',
  display: 'flex',
})

const Main = styled('div')({
  height: '100%',
  flexGrow: 1,
  minWidth: 0,
})

const AppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  background: theme.palette.background.secondary,
}))

const AppContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 0,
  height: '100%',
  boxSizing: 'border-box',
  overflowY: 'auto',
})

const PageContent = styled('div')({
  // 'absolute' position elements in pages should not fall outside of the page container
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minHeight: 0,
})

// If you are adding a new route here and want the refresh to stay on that route, then add it also to config/routes.rb
const AuthenticatedRoutes = () => {
  useBroadcastChannelMessageHandler()
  useBatchActionsSnackbars()
  useIntercom()
  useWootricIntegration()

  const content = (
    <>
      <AppNav />
      <AppContent>
        <LoginBanner />
        <AttachmentViewerWithOverlay />
        <PageContent>
          <Switch>
            <HbRoute exact path="/settings" render={() => <Redirect to="/settings/user-settings/personal" />} />

            <HbRoute path="/automations">
              <Feature
                name={FeatureFlag.EnableOrganizationAutomationsFrontend}
                otherwise={<Redirect to="/dashboard" />}
              >
                <AutomationsPage />
              </Feature>
            </HbRoute>

            <HbRoute exact path="/datasources">
              <Feature name={FeatureFlag.EnableDatasources} otherwise={<Redirect to="/dashboard" />}>
                <DatasourceListPage />
              </Feature>
            </HbRoute>

            <HbRoute exact path="/datasources/:token">
              <Feature name={FeatureFlag.EnableDatasources} otherwise={<Redirect to="/dashboard" />}>
                <DatasourcePage />
              </Feature>
            </HbRoute>

            <HbRoute exact path="/subprocessors" component={Subprocessors} />
            <HbRoute exact path="/search" component={SearchPage} />
            <HbRoute path="/editor" component={YamlKitchen} />
            {import.meta.env.DEV ? <HbRoute path="/graphiql" component={HbGraphiql} /> : null}
            <HbRoute exact path="/settings/:action" component={Settings} />
            <HbRoute exact path="/settings/:action/:option" component={Settings} />
            <HbRoute path="/settings/:action/:option/:subOption" component={Settings} />
            <HbRoute path="/dashboard/reviews/:reviewToken/:slug*" component={CaseReview} />
            <HbRoute path="/dashboard/cases/:caseToken/transactions" component={Case} />
            <HbRoute path="/dashboard/cases/:caseToken" component={Case} />
            <HbRoute path="/dashboard/library/:type/:token" component={LibraryObjectPage} />
            <HbRoute path="/dashboard/alert_snooze_rules/:token" component={AlertSuppressionRulePage} />
            <HbRoute path="/dashboard/organization_alerts/:token" component={OrganizationAlertPage} />
            <HbRoute path="/dashboard" component={Dashboards} />
            <HbRoute path="/apps" component={AppsPage} />
            <HbRoute path="/downloads/investigation-documents/:token" component={InvestigationDownloadDocument} exact />
            <HbRoute path="/downloads/:token">
              <Feature name={FeatureFlag.EnableDownloadsPage} otherwise={<Redirect to="/dashboard" />}>
                <DownloadsPage />
              </Feature>
            </HbRoute>
          </Switch>
        </PageContent>
      </AppContent>
      <MiddeskSnackbars />
      <AttachmentSummarySnackbars />
      <ScreeningSnackbars />
    </>
  )

  return <AppContainer>{content}</AppContainer>
}

const Routes = () => {
  useReduxSnackbars()
  const isCustomerDomain = useIsCustomerDomain()

  if (isCustomerDomain) {
    return (
      <Switch>
        <HbRoute
          path="/"
          component={CustomDomainRouter}
          wrapper={{ Component: UsageArea, props: { eventPrefix: 'CustomDomainForm' } }}
        />
      </Switch>
    )
  }

  return (
    <Switch>
      <HbRoute exact path="/auth/sign-in" component={SignIn} />
      <HbRoute exact path="/auth/mfa" component={MultiFactorAuth} />
      <HbRoute exact path="/auth/password/new" component={ResetPassword} />
      <HbRoute exact path="/auth/password/edit" component={UpdatePassword} />
      <HbRoute exact path="/onboard/account/:step" component={OnboardAccount} />
      {/* We should consider removing the wrapper in favor of modifying the HbRoute component to natively handle
       UsageAreas and possibly expose an eventPrefix property directly. */}
      <HbRoute
        exact
        path="/information-requests/:externalToken"
        component={InformationRequest}
        wrapper={{ Component: UsageArea, props: { eventPrefix: 'InformationRequestRecipientForm' } }}
      />
      <HbRoute
        exact
        path="/rfi-v2/:externalToken"
        component={RequestForInformation}
        wrapper={{ Component: UsageArea, props: { eventPrefix: 'LegacyRfiRecipientForm' } }}
      />
      <HbRoute
        exact
        path="/organizations/:organizationUrlSlug/tips/:tipIntakeSlug"
        component={TipIntakeFormFromQuery}
      />
      <HbRoute path="/" disableTimeTracking component={AuthenticatedRoutes} />
    </Switch>
  )
}

const MainContainer = () => {
  return (
    <ErrorBoundary fullPage>
      <HbNotistackProvider>
        <Parent>
          <Main>
            <Suspense fallback={<PageLoader />}>
              <Routes />
            </Suspense>
          </Main>
        </Parent>
        <div id="hb-page-portal" />
        <WelcomeDialog />
      </HbNotistackProvider>
    </ErrorBoundary>
  )
}

export const WrappedRoutes = () => {
  return (
    <StyledEngineProvider injectFirst>
      {/* Two theme providers (one for @mui/material and one for @mui/styles),
      because there is some sort of communication issue between the two
      libraries and they're not using the same React context. */}
      <ThemeProvider theme={redesignTheme}>
        <StylesThemeProvider theme={redesignTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MainContainer />
          </LocalizationProvider>
        </StylesThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default WrappedRoutes
