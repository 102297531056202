import { useEffect, useState, useCallback, forwardRef, ForwardedRef } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import { getIn } from 'formik'
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import { BaseAutocomplete, AutocompleteProps } from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'
import { useIsErroneous } from 'components/HbComponents/Form/Inputs/useIsErroneous'

import { useHbFormikContext } from 'components/HbComponents/Form/useHbFormikContext'

import { CustomFieldDatatypeEnum, OtherInfoLabelTypeEnum } from 'types/api'

import {
  OtherInfoLabelSearchQuery,
  OtherInfoLabelSearchQueryVariables,
} from './__generated__/OtherInfoLabelInput.generated'

const SEARCH_QUERY = gql`
  query OtherInfoLabelSearch($labelType: OtherInfoLabelTypeEnum!, $query: String, $useVisibilitySettings: Boolean) {
    otherInfoLabelSearch: otherInfoLabelSearch(
      labelType: $labelType
      query: $query
      useVisibilitySettings: $useVisibilitySettings
    ) {
      token
      label
      datatype
      options
    }
  }
`

type LabelSearchData = {
  token: string
  label: string
  datatype: CustomFieldDatatypeEnum
  options: string[]
}

type Props = {
  type: OtherInfoLabelTypeEnum
  onChange: (label: string, labelData?: LabelSearchData) => void
  value: string
  useVisibilitySettings?: boolean
  disableSearch?: boolean
} & Omit<AutocompleteProps<string>, 'options' | 'value' | 'onChange'>

function OtherInfoLabelInputInner(
  { type, value, onChange, useVisibilitySettings = false, disableSearch, ...restProps }: Props,
  ref: ForwardedRef<unknown>
) {
  const [_options, setOptions] = useState<string[]>([])
  const [labelSearchData, setLabelSearchData] = useState<LabelSearchData[]>([])
  const options = disableSearch ? [] : _options
  const [searchQuery] = useLazyQuery<OtherInfoLabelSearchQuery, OtherInfoLabelSearchQueryVariables>(SEARCH_QUERY, {
    fetchPolicy: 'network-only',
  })

  const handleSearch = useCallback(
    async (query: string) => {
      // OtherInfoLabelInput is used in 2 places:
      // 1. In the ReviewTypeEditPage for the Reportable Label
      // 2. In the Automation editor for the OtherInfoLabel
      // For #2, we want to populate the drop down with BOTH Review and ReviewData labels. Instead of modifying params
      // to intake multiple label types, we'll just make 2 queries if the type is Review. Otherwise, we'd have to modify
      // the params for the query & schema to accept multiple types and allow domain types in automations to map
      // to multiple label types, which is more work-intensive. As we mature other info, this is something we'll want
      // to consider down the road.
      const { data } = await searchQuery({
        variables: { query: query || null, labelType: type, useVisibilitySettings },
      })
      const otherInfoLabels = data?.otherInfoLabelSearch.map((result) => result.label) || []
      setOptions(otherInfoLabels)
      setLabelSearchData(data?.otherInfoLabelSearch || [])
    },
    [searchQuery, type, useVisibilitySettings]
  )

  const onInputChange = (newLabel: string) => {
    const currentOptionsData = labelSearchData.find((o) => o.label === newLabel) as LabelSearchData

    onChange(newLabel, currentOptionsData)
  }
  useEffect(() => {
    if (!disableSearch) {
      handleSearch(value)
    }
  }, [value, handleSearch, disableSearch])

  return (
    <BaseAutocomplete
      onInputChange={onInputChange}
      value={value}
      options={options}
      setValueOnBlur
      freeSolo
      {...restProps}
      // We want to rely on onInputChange to update the value
      onChange={() => {}}
      ref={ref}
    />
  )
}

const OtherInfoLabelInput = forwardRef(OtherInfoLabelInputInner)

export default OtherInfoLabelInput

export function RHFOtherInfoLabelInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  onChange,
  label,
  ...rest
}: UseControllerProps<TFieldValues, TName> & Omit<Props, 'onChange' | 'value'> & { onChange?: Props['onChange'] }) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <OtherInfoLabelInput
          {...rest}
          {...field}
          label={label}
          errorMessage={fieldState.error?.message}
          onChange={(value: string) => {
            field.onChange(value)
            if (onChange) {
              onChange(value)
            }
          }}
        />
      )}
    />
  )
}

export function FormikOtherInfoLabelInput({ name, ...props }: Omit<Props, 'onChange' | 'value'> & { name: string }) {
  const formik = useHbFormikContext({ autosave: true })
  const labelInputName = `${name}.label`
  const { clientError } = useIsErroneous({ name: labelInputName, autosave: true })

  const handleChange = (newLabel: string, newLabelData?: LabelSearchData) => {
    if (newLabelData) {
      formik.setFieldValue(labelInputName, newLabel, true)
      formik.setFieldValue(`${name}.datatype`, newLabelData.datatype, true)
      formik.setFieldValue(`${name}.options`, newLabelData.options, true)
    } else {
      // original functionality
      formik.setFieldValue(labelInputName, newLabel, true)
    }
  }

  const inputValue = getIn(formik.values, labelInputName)

  return (
    <OtherInfoLabelInput
      {...props}
      errorMessage={Array.isArray(clientError) ? clientError[0] : clientError}
      onChange={handleChange}
      onBlur={formik.handleBlur}
      value={inputValue}
    />
  )
}
