import {
  darken,
  lighten,
  TypographyStyle,
  inputClasses,
  inputBaseClasses,
  outlinedInputClasses,
  autocompleteClasses,
  formHelperTextClasses,
  fabClasses,
  svgIconClasses,
  radioClasses,
  checkboxClasses,
  Theme as MuiTheme,
  TypographyVariant,
  PaletteOptions,
  createTheme,
  colors,
  dialogActionsClasses,
  buttonClasses,
  switchClasses,
  CssVarsThemeOptions,
} from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import type {} from '@mui/x-date-pickers/themeAugmentation'

import { opacify } from 'helpers/colors'
import { Modify } from 'types/hb'

import { Size } from './HbComponents/HbComponents.types'
import { HbColorsByHue, HBCOLORS, HBGREYS, legacyColors } from './colors'

export const HEADER_ZINDEX = 5
export const RESIZE_OVERLAY_Z_INDEX = 9999

export const FONT_SIZE = 14

/* This set of colors should be the full set of colors used across the product.
 * These should only vary based on opacity which can be done using the `opacify`
 * function from helpers/colors.
 *
 * ONLY EVER USE HEX REPRESENTATION!
 *
 * For reference checkout the design color and typography cheat sheet:
 * https://drive.google.com/file/d/1_Ad4SbDHX_RCoYjM2hvM5RMtVbypCAUx/view
 */

// Grayscale
// UI Grey Colors in Figma
// see: https://www.figma.com/file/39uAofuoRFGDFCVOyD9Wby/Interface-components?node-id=7%3A172
const greyA = '#4A4B4D'
const greyB = '#6E6F71'
const greyC = '#929394'
const greyD = '#B7B7B8'
const greyE = '#DBDBDB'
const greyF = '#EDEDED'

const primary50 = '#E7E9FC'

/**
 * This should not be accessed directly.
 * Please use the `theme` object provided by `makeStyles` instead.
 *
 * @deprecated
 */
export const COLORS = {
  greyA,
  greyB,
  greyC,
  greyD,
  greyE,
  greyF,

  primary50,

  dark: '#000000',
  nearBlack: greyA,
  darkGray: '#212428', // Legacy color, do not use
  mediumGray2: greyB,
  mediumGray1: greyD,
  lightGray4: '#DCE0E5',
  lightGray3: '#F0F4F4',
  lightGray2: '#F7F9FA',
  lightGray1: '#E1E5EB',
  lightGray5: '#F9F9F9',
  nearWhite: greyF,

  blue2: '#BCECFC',
  blue3: '#3787FB',
  lightRed: '#FCEDEB',
  lightPurple: '#F0F2FD',

  // Primary Colors
  blue: '#4361E5',
  green: '#3b9d3f',
  yellow: '#f1c714',
  orange: '#f57c00',
  red: '#db2004',
  error: '#F73210',

  ...HBCOLORS,
  ...HBGREYS,
}

const TYPOGRAPHY: Record<Size, { fontSize: string | number; lineHeight: string | number }> = {
  xs: {
    fontSize: '10px',
    lineHeight: 1.2,
  },
  s: {
    fontSize: '12px',
    lineHeight: 1.6,
  },
  md: {
    fontSize: FONT_SIZE,
    lineHeight: 1.6,
  },
  lg: {
    fontSize: '16px',
    lineHeight: 1.25,
  },
  xl: {
    fontSize: '18px',
    lineHeight: 1.6,
  },
  xxl: {
    fontSize: '28px',
    lineHeight: 1.4,
  },
}

// New designs are using a 2px unit.
// For now, keeping it at 8 for codebase compatibility.
const UNIT = 8
export const LIGHT_OPACITY = 0.1
export const HOVER_OPACITY = 0.8

export const BUTTON_SHADOW = '0px 1px 2px rgba(0, 0, 0, 0.25)'

const hbPalette = {
  // MUI overrides
  primary: {
    // MUI overrides
    main: COLORS.blue,

    // HB custom
    background: COLORS.primary50,
  },
  secondary: {
    main: COLORS.lightGray2,
  },
  grey: {
    50: colors.grey[50],
    100: colors.grey[100],
    200: colors.grey[200],
    300: HBGREYS.gray300,
    600: HBGREYS.gray600,
    800: HBGREYS.gray800,
    900: HBGREYS.gray900,
  },
  common: {
    white: COLORS.white,
    black: COLORS.black,
  },
  error: {
    // MUI overrides
    main: COLORS.red,

    // HB custom
    backgroundLight: lighten(COLORS.red, 0.95),
    backgroundHoverLight: lighten(COLORS.red, 0.9),
    background: lighten(COLORS.red, 0.85),
    backgroundHover: lighten(COLORS.red, 0.8),
    accent: COLORS.error,
  },
  warning: {
    // MUI overrides
    main: COLORS.orange,

    // HB custom
    backgroundLight: lighten(COLORS.orange, 0.95),
    backgroundHoverLight: lighten(COLORS.orange, 0.9),
    background: lighten(COLORS.orange, 0.85),
    backgroundHover: lighten(COLORS.orange, 0.8),
    darken: darken(COLORS.orange, 0.05),
  },
  info: {
    main: COLORS.blue3,
  },
  success: {
    main: COLORS.green,
  },
  background: {
    // MUI overrides
    default: COLORS.lightGray3,
    paper: COLORS.white,

    // HB custom
    dark: COLORS.dark,
    // These need to be consolidated since they're the same color,
    // but for backwards compatibility with v1 they're kept in
    contrastLight: COLORS.lightGray3,
    medium: COLORS.lightGray3,
    mediumLight: COLORS.lightGray3,
    contrastMedium: COLORS.lightGray5,
    contrastDark: COLORS.greyB,
    secondary: COLORS.greyF,
    light: COLORS.white,
    lightGray: HBGREYS.backgroundLight,
    gradient: 'linear-gradient(87deg, #B7AA68, #70AB92 20%, #487EAF 80%, #3D72B9)',
    selected: COLORS.lightGray3,
  },
  divider: COLORS.lightGray1,
  action: {
    // MUI overrides
    active: COLORS.blue,
    hover: COLORS.lightGray5,
    selected: COLORS.lightGray3,
    focus: opacify(COLORS.blue, 0.5),
    disabled: COLORS.greyD,

    // HB custom
    activeHover: legacyColors.palette.styleguide.lightBlue,
    disabledIcon: COLORS.lightGray2,
    newHighlight: '#005A7A',
  },
  text: {
    // MUI overrides
    primary: HBGREYS.black,
    secondary: HBGREYS.textGreyDark,
    disabled: HBGREYS.textGreyLight,

    // HB custom
    emphasized: HBGREYS.black,
    medium: HBGREYS.black,
    white: HBGREYS.white,
    blue: HbColorsByHue.hbBlue.medium,
    error: HBCOLORS.errorMedium,
    warning: COLORS.orange,
  },

  // HB extensions
  dividers: {
    light: COLORS.lightGray1,
    medium: COLORS.lightGray4,
  },
  input: {
    disabled: COLORS.greyE,
  },
  link: {
    primary: COLORS.blue,
  },
  accent: COLORS.blue,
  approval: COLORS.green,
  styleguide: COLORS,
  dataTypes: {
    business: COLORS.orange,
    institution: legacyColors.palette.styleguide.darkPurple,
    individual: COLORS.yellow,
    transactionImports: COLORS.green,
    paymentCard: legacyColors.palette.styleguide.teal,
    bankAccount: legacyColors.palette.styleguide.aqua,
    locations: '#42a5f5',
    device: legacyColors.palette.styleguide.pink,
    deviceFingerprint: '#ef5350',
    ipAddress: legacyColors.palette.styleguide.magenta,
    attachment: COLORS.blue,
    importTemplate: '#074c0b',
    cryptoAddress: '#14a6cc',
    product: '#ff961b',
  },
  insights: {
    credit: COLORS.green,
    debit: '#78909c',
    transfer: '#5C6BC0',
  },
  highlight: '#eef4ff',
  hues: { ...HbColorsByHue },
}

type HbTypographyVariant = TypographyVariant | 'sm' | 'md' | 'sectionHeader' | 'noWrap'

export const fontFamily =
  'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'

const hbFontWeight = {
  normal: 400,
  bold: 500,
  bolder: 600,
  extraBold: 700,
}

const hbTypography: TypographyOptions &
  Partial<Record<HbTypographyVariant, TypographyStyle>> & {
    sizes: typeof TYPOGRAPHY
  } = {
  sm: {
    ...TYPOGRAPHY.s,
  },
  md: {
    ...TYPOGRAPHY.md,
  },
  h4: {
    fontFamily,
    fontWeight: 500,
    fontSize: '1.467rem',
    letterSpacing: 0,
    lineHeight: '1.2em',
  },
  h5: {
    fontFamily,
    fontWeight: 400,
    fontSize: '1.467rem',
    letterSpacing: 0,
    lineHeight: '1.2em',
  },
  subtitle1: {
    ...TYPOGRAPHY.md,
    fontFamily,
    fontWeight: 500,
    letterSpacing: '0',
  },
  subtitle2: {
    ...TYPOGRAPHY.s,
    fontFamily,
    fontWeight: 500,
    letterSpacing: '0',
  },
  body1: {
    ...TYPOGRAPHY.md,
    fontFamily,
    fontWeight: 400,
    letterSpacing: '0',
  },
  body2: {
    ...TYPOGRAPHY.md,
    fontFamily,
    fontWeight: 400,
    letterSpacing: '0',
  },
  sectionHeader: {
    fontFamily,
    ...TYPOGRAPHY.md,
    fontWeight: hbFontWeight.bold,
  },
  overline: {
    fontFamily,
    fontWeight: 400,
    fontSize: '11px',
    letterSpacing: '1px',
    lineHeight: '1.5rem',
    'text-transform': 'uppercase',
  },
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  sizes: {
    ...TYPOGRAPHY,
  },
  fontSize: FONT_SIZE,
  fontFamily,
}

const hbShape = {
  /*
   * This border radius is already set in the default theme
   * and is used by MUI components with rounded corners.
   * Explicitly set it to match our UI spec.
   */
  borderRadius: 6,
  roundedRadius: '50%',
  largeContainer: {
    borderRadius: 10,
  },
  smallContainer: {
    borderRadius: 4,
  },
  mediumPillContainer: {
    borderRadius: 10,
  },
  largePillContainer: {
    borderRadius: 24,
  },
  countPill: {
    borderRadius: 40,
  },
}

const hbGradients = {
  /*
   * NOTE: gradients are technically images and can't be used everywhere colors can be used.
   * Thus we separate them from our `palette` to prevent future mis-use
   */
  adminOnboarding: 'linear-gradient(138.17deg, #BDD9FA 15.31%, #FDDEEB 113.78%)',
}

const hbExtensions = {
  disabledFilter: {
    background: COLORS.white,
    filter: 'grayscale(1) contrast(0.75)',
    opacity: 0.5,
    boxShadow: 'none',
  },
}

// We usually want to apply the same styles for all of these states
export const CSS_SELECTED_SELECTORS = '&:hover, &:focus, &[aria-current="true"]'

// Consider adding this to the theme object once HBv1 cleanup is complete
export const BUTTON_OUTLINE_STYLE = `2px solid ${hbPalette.action.focus}`

const sharedButtonProps = {
  sizeSmall: {
    ...hbTypography.sizes.s,
    padding: `${UNIT}px ${UNIT * 1.5}px`,
  },
  sizeLarge: {
    ...hbTypography.sizes.md,
    padding: `${UNIT * 1.375}px ${UNIT * 1.5}px`,
  },
  getContainedVariantProps: ({ color, bg1, bg2 }: { color: string; bg1: string; bg2: string }) => {
    return {
      color,
      backgroundColor: bg1,
      borderColor: bg1,
      boxShadow: 'none',
      ...sharedButtonProps.sizeLarge,

      [CSS_SELECTED_SELECTORS]: {
        color,
        boxShadow: 'none',
      },

      '&:hover, &[aria-current="true"]': {
        backgroundColor: bg2,
        borderColor: bg2,
      },
    }
  },
  getTextVariantProps: ({ color, bg1, bg2 }: { color: string; bg1: string; bg2?: string }) => {
    return {
      color,
      transition: 'none',
      ...sharedButtonProps.sizeLarge,

      '&:hover, &:focus:hover, &[aria-current="true"]': {
        color,
        backgroundColor: bg1,
        borderColor: bg1,
      },

      '&:focus': {
        color,
        backgroundColor: bg2 || bg1,
        borderColor: bg2 || bg1,
      },
    }
  },
  getOutlinedVariantProps: () => {
    return {
      color: HBGREYS.black,
      borderColor: HBGREYS.borderDark,
      transition: 'none',
      ...sharedButtonProps.sizeLarge,

      '&:hover, &[aria-current="true"]': {
        color: HBGREYS.black,
        backgroundColor: 'transparent',
        borderColor: HBGREYS.black,
      },
    }
  },
}

export const MuiSelectIconStyle = {
  color: COLORS.nearBlack,

  // If the icon is not the last child of the select, there are adornments
  // and we need to provide them more space.
  // If it is the last child, there's nothing else in the select and we can reduce the right space.
  // This is a bit hacky, unfortunately material-ui doesn't properly support adornments on selects.
  '&:last-child': {
    right: UNIT * 0.5,
  },
}

/* Inspiration for arrow tooltip drawn from: https://material-ui.com/components/tooltips/ */
const arrowGenerator = (color: string) => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.95em',
    width: '2em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${color} transparent`,
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.95em',
    width: '2em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${color} transparent transparent transparent`,
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${color} transparent transparent`,
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.95em',
    height: '2em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${color}`,
    },
  },
})

const hbComponents: CssVarsThemeOptions['components'] = {
  MuiTouchRipple: {
    styleOverrides: {
      child: {
        // backgroundColor: COLORS.blue,
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
    },
    styleOverrides: {
      input: {
        height: 'auto',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        [`& .${autocompleteClasses.option}`]: {
          [`&.${autocompleteClasses.focusVisible}`]: {
            backgroundColor: hbPalette.background.mediumLight,
          },
        },
      },
      root: {
        [`& .${autocompleteClasses.inputRoot} .${inputBaseClasses.input}.${inputBaseClasses.inputSizeSmall}`]: {
          // With padding we want a standard height of 40px
          paddingTop: UNIT * 0.5,
          paddingBottom: UNIT * 0.5,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${COLORS.lightGray1}`,
        },
        '&:after': {
          borderBottom: `1px solid ${opacify(COLORS.blue, 0.4)}`,
        },
        '&:hover:not($disabled):before': {
          borderBottom: `1px solid ${COLORS.lightGray1}`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid ${opacify(COLORS.blue, 0.4)}`,
        },
      },
      root: {
        [`&.${inputClasses.error}`]: {
          borderBottom: `1px solid ${COLORS.red}`,
          tranform: 'scaleX(0)',
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: `1px solid ${HBGREYS.borderDark}`,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: `${UNIT}px 0 0 0`,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        background: hbPalette.background.light,
        borderRadius: UNIT,
        [`&.${outlinedInputClasses.multiline}`]: {
          padding: `${UNIT * 0.75}px 0`,
          height: 'auto',

          [`& .${outlinedInputClasses.inputSizeSmall}`]: {
            // With padding we want a standard height of 40px
            paddingTop: UNIT * 0.5,
            paddingBottom: UNIT * 0.5,
          },
        },
        [`&.${outlinedInputClasses.disabled}`]: {
          backgroundColor: hbPalette.background.secondary,
          '& input': {
            textFillColor: hbPalette.styleguide.gray600,
          },
        },
        [`&.${outlinedInputClasses.error}`]: {
          backgroundColor: hbPalette.styleguide.lightRed,
        },
        [`&.${outlinedInputClasses.error} + .${formHelperTextClasses.root}`]: {
          marginLeft: 0,
          marginTop: UNIT,
        },
        [`& .${svgIconClasses.root}`]: { color: hbPalette.background.contrastDark },
      },
      inputSizeSmall: {
        // With padding we want a standard height of 40px
        padding: '10px 14px',
      },
      notchedOutline: {
        borderColor: hbPalette.styleguide.greyD,
        [`.${outlinedInputClasses.root}.${outlinedInputClasses.disabled} &`]: {
          borderColor: hbPalette.styleguide.greyD,
        },
        [`.${outlinedInputClasses.root}.${outlinedInputClasses.focused} &`]: {
          borderColor: hbPalette.primary.main,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        '& .MuiChip-icon': {
          marginRight: 4,
          marginLeft: 0,
        },
        '& .MuiChip-deleteIcon': {
          marginRight: 0,
          marginLeft: 8,
        },
      },
    },
  },
  MuiPickersPopper: {
    styleOverrides: {
      root: {
        [`& .${dialogActionsClasses.root}`]: {
          margin: UNIT,
        },
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        width: 34,
        height: 22,
        padding: 0,
        display: 'flex',
        '& .MuiSwitch-switchBase': {
          padding: 4,
          '&:not(.Mui-disabled) + .MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: hbPalette.action.active,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: hbPalette.action.active,
            border: '3px solid white',
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 14,
          height: 14,
          borderRadius: 7,
          boxSizing: 'border-box',
        },
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          backgroundColor: hbPalette.background.contrastDark,
        },
        // Styles for size="small"
        [`&.${switchClasses.sizeSmall}`]: {
          height: 18,
          width: 30,
          '& .MuiSwitch-switchBase': {
            transform: 'translateY(-1px)',
            '&.Mui-checked': {
              transform: 'translate(10px, -1px)',
            },
          },
          '& .MuiSwitch-thumb': {
            width: 12,
            height: 12,
            borderRadius: 6,
          },
          '& .MuiSwitch-track': {
            borderRadius: 18 / 2,
          },
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        [`&.${radioClasses.colorSecondary}`]: {
          [`&.${radioClasses.checked}`]: {
            color: COLORS.blue,
          },
        },
      },
      colorSecondary: {},
      checked: {},
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: COLORS.lightGray4,
        [`&.${checkboxClasses.checked}`]: {
          color: COLORS.blue,
        },
      },
      colorSecondary: {
        [`&.${checkboxClasses.checked}`]: {
          color: COLORS.blue,
        },
      },
      checked: {},
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: MuiSelectIconStyle,
      outlined: {
        borderRadius: UNIT,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: hbPalette.styleguide.greyC,
        '&$focused': {
          color: opacify(COLORS.blue, 0.4),
        },
      },
      focused: {},
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionEnd: {
        maxWidth: '36px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        boxSizing: 'border-box',
        width: 40,
        height: 40,
        color: COLORS.dark,
      },
      sizeSmall: {
        width: 32,
        height: 32,
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        [`&.${fabClasses.root}`]: {
          height: UNIT * 6,
          minWidth: UNIT * 6,
          width: 'fit-content',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 500,
        borderRadius: hbShape.borderRadius,
        boxShadow: 'none',
        ...sharedButtonProps.sizeLarge,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',

        '&:focus': {
          outline: BUTTON_OUTLINE_STYLE,
        },

        [`&:disabled:not(.${buttonClasses.outlined}):not(.${buttonClasses.text})`]: {
          borderColor: 'transparent',
        },
      },
      text: {
        // Default is same as primary
        // This corresponds to HbButton's "textPrimary" variant
        ...sharedButtonProps.getTextVariantProps({
          color: HbColorsByHue.hbBlue.medium,
          bg1: HbColorsByHue.hbBlue.light,
          bg2: HBGREYS.backgroundMedium,
        }),

        // All text buttons have the same disabled styles
        '&:disabled': {
          backgroundColor: 'transparent',
          color: hbPalette.text.disabled,
        },
      },
      textPrimary: sharedButtonProps.getTextVariantProps({
        color: HbColorsByHue.hbBlue.medium,
        bg1: HbColorsByHue.hbBlue.light,
        bg2: HBGREYS.backgroundMedium,
      }),
      textSecondary: sharedButtonProps.getTextVariantProps({ color: HBGREYS.black, bg1: HBGREYS.backgroundMedium }),
      textSizeLarge: sharedButtonProps.sizeLarge,
      textSizeSmall: sharedButtonProps.sizeSmall,
      outlined: {
        // Default, primary and secondary are the same
        // This corresponds to HbButton's "secondary" variant
        ...sharedButtonProps.getOutlinedVariantProps(),

        // All outlined buttons have the same disabled styles
        '&:disabled': {
          color: hbPalette.text.disabled,
        },
      },
      outlinedSizeLarge: sharedButtonProps.sizeLarge,
      outlinedSizeSmall: sharedButtonProps.sizeSmall,
      outlinedPrimary: sharedButtonProps.getOutlinedVariantProps(),
      outlinedSecondary: sharedButtonProps.getOutlinedVariantProps(),
      contained: {
        // Default is same as primary
        // This corresponds to HbButton's "primary" variant
        ...sharedButtonProps.getContainedVariantProps({
          color: COLORS.white,
          bg1: HbColorsByHue.hbBlue.medium,
          bg2: HbColorsByHue.hbBlue.dark,
        }),

        // All contained buttons have the same disabled styles
        '&:disabled': {
          backgroundColor: HBGREYS.backgroundMedium,
          color: HBGREYS.textGreyLight,
        },
      },
      containedSizeLarge: sharedButtonProps.sizeLarge,
      containedSizeSmall: sharedButtonProps.sizeSmall,
      containedPrimary: sharedButtonProps.getContainedVariantProps({
        color: COLORS.white,
        bg1: HbColorsByHue.hbBlue.medium,
        bg2: HbColorsByHue.hbBlue.dark,
      }),
      // This corresponds to HbButton's "supplementary" variant
      containedSecondary: sharedButtonProps.getContainedVariantProps({
        color: HBGREYS.black,
        bg1: HBGREYS.backgroundMedium,
        bg2: HBGREYS.backgroundDark,
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 16,
        minWidth: 400,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        margin: `${UNIT * 2}px ${UNIT * 2}px`,
        padding: 0,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: `${UNIT * 3}px ${UNIT * 2}px`,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: `0 ${UNIT * 2}px`,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        color: HBGREYS.textGreyDark,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: FONT_SIZE,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        ...hbTypography.sm,
        fontWeight: 600,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Vertically center content
        minHeight: '24px',
        paddingLeft: '12px',
        paddingRight: '12px',

        color: 'white',
        maxWidth: '300px',
        backgroundColor: COLORS.dark,
        borderRadius: 16,
        boxShadow: '0px 0px 4px rgba(33, 36, 40, 0.36)',
      },
      tooltipPlacementRight: {
        margin: `0 ${UNIT}px !important`, // Need to override media queries in m-ui CSS
      },
      arrow: {
        fontSize: 6,
      },
      popper: {
        opacity: 1,
        ...arrowGenerator(COLORS.dark),
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      inset: {
        marginLeft: UNIT * 1.5,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: hbPalette.background.light,
        marginTop: UNIT,
        marginBottom: UNIT,
      },
      rounded: {
        borderRadius: hbShape.largeContainer.borderRadius,
      },
      outlined: {
        border: `1px solid ${hbPalette.dividers.medium}`,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: hbShape.borderRadius,
        border: '1px solid #E9E9E9',
        boxShadow: '8px 0px 37px rgba(0, 0, 0, 0.23)',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        color: hbPalette.text.primary,

        '&:hover': {
          color: hbPalette.action.active,
          background: hbPalette.background.contrastLight,
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        display: 'grid',
        placeItems: 'center',
        marginRight: UNIT * 1.5,

        minWidth: 34,
        width: 34,
        height: 34,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: { fontWeight: 600 },
      secondary: { ...hbTypography.sizes.xs, fontWeight: 500 },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        ...hbTypography.sizes.md,
        width: 48,
        height: 48,
        fontWeight: 500,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.36)',
        '&$disabled': {
          boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.08)`,
        },
        '&:focus': {
          outline: `2px solid ${hbPalette.action.focus}`,
        },
      },
      sizeMedium: {
        width: 40,
        height: 40,
        borderRadius: 56,
      },
      primary: {
        color: hbPalette.text.white,
        backgroundColor: hbPalette.background.dark,
        '&:hover': {
          backgroundColor: hbPalette.action.active,
        },
      },
      secondary: {
        color: hbPalette.text.primary,
        backgroundColor: hbPalette.background.paper,
        '&:hover': {
          backgroundColor: hbPalette.background.secondary,
        },
      },
      extended: {
        borderRadius: 56,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
      size: 'small',
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        textAlign: 'center', // This is overriden by our reset CSS, need to restore it
      },
    },
  },
}

const hbOpacity = {
  disabled: 0.5,
} as const

export type HbTheme = Modify<
  MuiTheme,
  {
    palette: Modify<PaletteOptions, typeof hbPalette>
    typography: Modify<TypographyOptions, typeof hbTypography>
    shape: typeof hbShape
    fontWeight: typeof hbFontWeight
    opacity: typeof hbOpacity
    gradients: typeof hbGradients
    hb: typeof hbExtensions
    hbUnit: (n?: number) => number
  }
>

// Augment MUI types with our theme customizations
declare module '@mui/material/styles' {
  interface Theme {
    palette: HbTheme['palette']
    typography: HbTheme['typography']
    shape: HbTheme['shape']
    fontWeight: HbTheme['fontWeight']
    opacity: HbTheme['opacity']
    gradients: HbTheme['gradients']
    hb: HbTheme['hb']
    hbUnit: HbTheme['hbUnit']
  }
}

// See https://emotion.sh/docs/typescript#define-a-theme
declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends HbTheme {}
}

export const theme = {
  ...createTheme({
    palette: hbPalette,
    spacing: UNIT,
    typography: hbTypography,
    shape: hbShape,
    components: hbComponents,
  }),
  fontWeight: hbFontWeight,
  opacity: hbOpacity,
  hb: hbExtensions,
  gradients: hbGradients,
  hbUnit: (n: number) => (n ?? 1) * UNIT,
}
