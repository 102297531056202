import { useState, FocusEvent } from 'react'

import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'

import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'

import { useHistory } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { HbButton } from 'components/HbComponents/HbButton'

import { getAutomationEnabledPillProps } from 'components/pages/automations/AutomationEnabledPill'
import {
  checkIsRule,
  useAutomationRuleOrTemplate,
} from 'components/pages/automations/editor/AutomationRuleOrTemplateContext'
import { FormSchemaReturnType } from 'components/pages/automations/editor/formSchema'

import { useAutomationAdminOperations } from 'components/pages/automations/hooks/AutomationAdminHelpers'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useFeatureFlag } from 'hooks'

import { AutomationRuleExecutionMode, AutomationRuleType, FeatureFlag } from 'types/api'

import { ViewAutomationHeaderBase } from '../ViewAutomationHeaderBase'

import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from './__generated__/ViewAutomation.queries.generated'

interface Props {
  automationRule: AutomationRule
  form: FormSchemaReturnType
  onSubmitAndSave: () => void
  setEnablingOrDisabling: (x: boolean) => void
  loading: boolean
}

export const ViewAutomationHeader = ({
  automationRule,
  form,
  onSubmitAndSave,
  setEnablingOrDisabling,
  loading,
}: Props) => {
  const usage = useUsage()
  const history = useHistory()
  const isAdminRuleForOtherOrg = useAutomationAdminOperations(automationRule.organizationToken)
  const [ruleNameError, setRuleNameError] = useState(false)
  const ruleOrTempate = useAutomationRuleOrTemplate()

  const rule = ruleOrTempate && checkIsRule(ruleOrTempate) ? ruleOrTempate : null

  const { formState, watch } = form
  const { isDirty, errors, isValid } = formState
  const [automationType, actionType, name] = watch(['automationType', 'actionType', 'name'])

  const isPreviewModeFeatureEnabled = useFeatureFlag(FeatureFlag.EnableAutomationsPreviewModeUi)
  const isSchedulePreviewModeFeatureEnabled = useFeatureFlag(FeatureFlag.EnableAutomationScheduledPreviewModeUi)

  const canEnablePreviewMode =
    (isPreviewModeFeatureEnabled && automationType === AutomationRuleType.Trigger) ||
    (isPreviewModeFeatureEnabled &&
      isSchedulePreviewModeFeatureEnabled &&
      automationType === AutomationRuleType.ScheduleWithTrigger)

  const isRulePreviewModeEnabled =
    automationRule.enabled && automationRule.executionMode === AutomationRuleExecutionMode.Preview

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    invariant(rule)

    if (event.target.value !== rule.name) {
      usage.logEvent({
        name: 'automations:editorEditName:updated',
        data: { automationRuleToken: rule.token, newName: event.target.value ?? '', oldName: rule.name ?? '' },
      })
    }
  }

  const handleEnableOrDisable = () => {
    if (!automationRule.enabled && !name) {
      setRuleNameError(true)
    } else {
      setEnablingOrDisabling(true)
    }
  }

  const openPreviewPanel = () => {
    history.push(`/automations/${automationRule.token}/preview_mode`)
  }

  return (
    <ViewAutomationHeaderBase
      name={rule?.displayName || 'Blank automation'}
      nameError={ruleNameError}
      setNameError={setRuleNameError}
      tagProps={getAutomationEnabledPillProps(automationRule.enabled, automationRule.executionMode)}
      form={form}
      onBlur={handleOnBlur}
      actions={
        <>
          <HbButton
            variant="secondary"
            disabled={loading || !automationRule.disabled || !isDirty || Object.keys(errors).length > 0}
            label="Save changes"
            onClick={() => {
              usage.logEvent({
                name: 'automations:automationDetailSave:clicked',
                data: {
                  recipeName: automationRule.name ?? automationRule.displayName,
                },
              })
              onSubmitAndSave()
            }}
          />
          {canEnablePreviewMode &&
            (isRulePreviewModeEnabled ? (
              <HbButton
                label="Stop preview mode"
                variant="secondary"
                onClick={handleEnableOrDisable}
                Icon={StopCircleOutlinedIcon}
              />
            ) : (
              <HbButton
                label="Preview mode"
                variant="secondary"
                disabled={
                  loading ||
                  !isValid ||
                  Object.keys(errors).length > 0 ||
                  !actionType ||
                  automationRule.enabled ||
                  isDirty
                }
                onClick={openPreviewPanel}
                Icon={SlowMotionVideoIcon}
              />
            ))}
          <HbButton
            variant={automationRule.disabled ? 'confirmation' : 'primary'}
            Icon={automationRule.disabled ? PlayCircleFilledWhiteOutlinedIcon : undefined}
            disabled={
              loading ||
              !isValid ||
              Object.keys(errors).length > 0 ||
              !actionType ||
              isAdminRuleForOtherOrg ||
              isRulePreviewModeEnabled
            }
            label={
              automationRule.disabled || isRulePreviewModeEnabled
                ? `${isDirty ? 'Save and ' : ''}Enable Automation`
                : 'Disable Automation'
            }
            onClick={() => {
              usage.logEvent({
                name: 'automations:automationDetailSaveEnableDisable:clicked',
                data: {
                  recipeName: automationRule.name ?? automationRule.displayName,
                  startingState: automationRule.enabled ? 'enabled' : 'disabled',
                },
              })
              handleEnableOrDisable()
            }}
          />
        </>
      }
    />
  )
}
