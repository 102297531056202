import { HTMLProps, useRef, useState } from 'react'

import { Popover } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { HbButton } from 'components/HbComponents/HbButton'
import { MenuList, MenuListItem, MenuListItemDivider } from 'components/library/Menu'
import NarrativeTemplateDialog from 'components/narrativeTemplates/NarrativeTemplateDialog'
import { AddIcon } from 'icons'
import { TemplateSkeleton } from 'reducers/narrativeTemplatesReducer'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {
    paddingLeft: theme.spacing(0.5),

    '& svg': {
      width: 18,
      height: 18,
      marginRight: 1,
    },
  },
}))

type Props<T extends TemplateSkeleton> = {
  templates: T[]
  onSelect: (template: T) => void
  onNewTemplate?: () => void
  disabled?: boolean
  loading?: boolean
} & Pick<HTMLProps<HTMLButtonElement>, 'className'>

export function TemplateButton<T extends TemplateSkeleton>(props: Props<T>) {
  const { templates, onSelect, onNewTemplate, disabled, loading, className } = props
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)
  const [managerOpen, setManagerOpen] = useState(false)
  const closeMenu = () => setExpanded(false)

  const openManager = () => {
    closeMenu()
    setManagerOpen(true)
  }

  const onTemplateSelect = (template: T) => {
    onSelect(template)
    closeMenu()
  }

  const closeManager = () => setManagerOpen(false)

  const container = useRef(null)

  return (
    <div className={classes.root} ref={container}>
      <HbButton
        className={classnames(classes.button, className)}
        label={
          <>
            <AddIcon /> Insert template
          </>
        }
        size="small"
        variant="textPrimary"
        disabled={disabled}
        loading={loading}
        onClick={() => setExpanded(!expanded)}
      />

      <Popover open={expanded} anchorEl={container.current} onClose={closeMenu}>
        <MenuList>
          <MenuListItem placeholder>Insert Template</MenuListItem>
          {templates.map((template) => (
            <MenuListItem key={template.token} onClick={() => onTemplateSelect(template)}>
              {template.name}
            </MenuListItem>
          ))}

          <MenuListItemDivider />
          <MenuListItem link>
            <button type="button" onClick={openManager}>
              Manage Templates
            </button>
          </MenuListItem>
        </MenuList>
      </Popover>

      <NarrativeTemplateDialog open={managerOpen} onClose={closeManager} onNewTemplate={onNewTemplate} />
    </div>
  )
}
