import { ChangeEvent, useEffect, useMemo } from 'react'

import { FormControl, FormGroup, MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { DatePicker } from '@mui/x-date-pickers'

import moment from 'moment'

import { Controller } from 'react-hook-form'

import { RRule } from 'rrule'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useOrgTimeZone } from 'hooks/DateFormatHooks'
import { ChangeCircleOutlinedIcon } from 'icons'

import { Theme } from 'types/hb'

import { useIsAutomationRule } from '../AutomationRuleOrTemplateContext'
import Step from '../Step'
import { useThinControlStyles } from '../TriggerFilterEditor/styles'
import { ScheduleSchemaReturnType } from '../formSchema'

import NextScheduledRuns from './NextScheduledRuns'

import { CadenceEditor, DayOfWeekSelector } from './TimeframeComponents'
import {
  DEFAULT_DAY_OF_WEEK_OBJECT,
  DaysOfWeek,
  MONTHLY_INTERVAL_ERROR,
  SCHEDULE_CONFIG_PATH_SLUG,
  SUPPORTED_FREQUENCIES,
  SUPPORTED_SCHEDULE_HOURS,
  WEEKLY_INTERVAL_ERROR,
  defaultScheduleConfig,
  generateScheduleDescription,
  getFrequencyValue,
  parseScheduleConfigIntoScheduleRule,
} from './utils'

interface Props {
  form: ScheduleSchemaReturnType
  onChangeFromScheduled?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
  },
  dateTimeContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(),
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(1),
  },
  frequencyContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: theme.spacing(),
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
    '& p': {
      // Error messages on input box are not correctly displaying without this
      display: 'flex',
    },
  },
  intervalError: {
    margin: 0,
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    position: 'relative',
    top: 5,
  },
  onDaysSelectorContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  datePicker: { '&:input': { padding: theme.spacing() } },
}))

export default function ViewScheduledAutomationStep({ form, onChangeFromScheduled }: Props) {
  const styles = useStyles()
  const controlClasses = useThinControlStyles()

  const orgTimeZone = useOrgTimeZone()

  const { watch, setValue, register, setError, clearErrors, getFieldState, control } = form
  const { scheduleStartOn, scheduleConfig } = watch()
  const scheduleStartOnMoment = moment(scheduleStartOn)
  const nowMoment = moment().tz(orgTimeZone)

  const isRule = useIsAutomationRule()

  const schedule = useMemo(
    () => parseScheduleConfigIntoScheduleRule(scheduleConfig ?? defaultScheduleConfig, scheduleStartOnMoment.toDate()),
    [scheduleConfig, scheduleStartOnMoment]
  )

  const stepText = useMemo(() => generateScheduleDescription(schedule, orgTimeZone), [schedule, orgTimeZone])

  const minDate = scheduleStartOnMoment.isBefore(nowMoment) ? scheduleStartOnMoment : nowMoment

  const scheduleHour =
    scheduleConfig.byhour !== undefined
      ? moment(scheduleConfig.byhour, 'h:mm').format('h:mm A')
      : moment().format('h:mm A')

  const selectClasses = {
    className: controlClasses.control,
    classes: { root: controlClasses.selectRoot },
  }

  const setScheduleWeekDays = (val: DaysOfWeek | null) => {
    setValue(`${SCHEDULE_CONFIG_PATH_SLUG}.byday`, val)
  }

  const setScheduleMonthDay = (val: number | null) => {
    setValue(`${SCHEDULE_CONFIG_PATH_SLUG}.bymonthday`, val)
  }

  const setMoment = (calendarMoment: moment.Moment) => {
    return moment.tz(
      [calendarMoment.year(), calendarMoment.month(), calendarMoment.date(), scheduleConfig.byhour, 0, 0, 0],
      orgTimeZone
    )
  }

  const violatesWeeklyUpperBound = scheduleConfig.freq === RRule.WEEKLY && scheduleConfig.interval > 52
  const violatesMonthlyUpperBound = scheduleConfig.freq === RRule.MONTHLY && scheduleConfig.interval > 12

  useEffect(() => {
    if (violatesWeeklyUpperBound || violatesMonthlyUpperBound) {
      setError(`${SCHEDULE_CONFIG_PATH_SLUG}.interval`, {
        type: 'custom',
        message: violatesWeeklyUpperBound ? WEEKLY_INTERVAL_ERROR : MONTHLY_INTERVAL_ERROR,
      })
    } else if (getFieldState(`${SCHEDULE_CONFIG_PATH_SLUG}.interval`).error) {
      clearErrors(`${SCHEDULE_CONFIG_PATH_SLUG}.interval`)
    }
  }, [
    clearErrors,
    control,
    getFieldState,
    setError,
    scheduleConfig.interval,
    scheduleConfig.freq,
    violatesMonthlyUpperBound,
    violatesWeeklyUpperBound,
  ])

  return (
    <Step
      key="everyStep"
      title="Scheduled"
      text={stepText}
      type="schedule"
      showCollapseButton
      ActionButton={
        onChangeFromScheduled && (
          <HbButton
            variant="secondary"
            Icon={ChangeCircleOutlinedIcon}
            label="Remove Scheduling"
            size="small"
            onClick={onChangeFromScheduled}
          />
        )
      }
    >
      <FormGroup>
        {/* Templates don't have start dates */}
        {isRule && (
          <>
            <HbText size="s" bold color="secondary">
              Starting
            </HbText>
            <div className={styles.dateTimeContainer}>
              <DatePicker
                {...register('scheduleStartOn')}
                minDate={minDate}
                format="MM/DD/YYYY"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
                value={scheduleStartOn ? scheduleStartOnMoment : nowMoment}
                onChange={(d: moment.Moment) => {
                  const adjustedDate = setMoment(d)
                  setValue('scheduleStartOn', adjustedDate.toISOString(), { shouldDirty: true })

                  /* When the schedule frequency is monthly, we must update the schedules month-day
                   with to be the new scheduleStartOn date */
                  if (scheduleConfig.freq === RRule.MONTHLY) {
                    setScheduleMonthDay(d.date())
                  }
                }}
                className={controlClasses.textFieldRoot}
              />

              <Controller
                name="scheduleConfigV2.byhour"
                render={({ field }) => (
                  <FormControl>
                    <Select
                      inputProps={field}
                      defaultValue={scheduleHour}
                      required
                      variant="outlined"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const hour = moment(e.target.value, 'h:mm A').hour()
                        setValue(`${SCHEDULE_CONFIG_PATH_SLUG}.byhour`, hour, { shouldDirty: true })

                        const adjustedDate = setMoment(scheduleStartOnMoment)
                        setValue('scheduleStartOn', adjustedDate.toISOString(), { shouldDirty: true })
                      }}
                      {...selectClasses}
                    >
                      {SUPPORTED_SCHEDULE_HOURS.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </>
        )}
        {/* @robocop: In future this will support daily and weekly schedules as well */}
        <HbText size="s" bold color="secondary">
          Every
        </HbText>
        <div className={styles.frequencyContainer}>
          <CadenceEditor form={form} />
          <FormControl>
            <Select
              variant="outlined"
              required
              value={scheduleConfig.freq === RRule.MONTHLY ? 'month' : 'week'}
              {...selectClasses}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === 'month') {
                  setValue(`${SCHEDULE_CONFIG_PATH_SLUG}.freq`, RRule.MONTHLY, { shouldDirty: true })
                  setScheduleWeekDays(null)
                  setScheduleMonthDay(new Date(scheduleStartOn).getDate())
                } else {
                  setValue(`${SCHEDULE_CONFIG_PATH_SLUG}.freq`, RRule.WEEKLY, { shouldDirty: true })
                  setScheduleMonthDay(null)
                  setScheduleWeekDays(DEFAULT_DAY_OF_WEEK_OBJECT)
                }
              }}
            >
              {SUPPORTED_FREQUENCIES.map((freq) => (
                <MenuItem key={freq} value={freq}>
                  {getFrequencyValue(freq, scheduleConfig.interval ?? 0)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {scheduleConfig.freq === RRule.WEEKLY && (
          <div>
            <HbText size="s" bold color="secondary">
              On these days
            </HbText>
            <div className={styles.onDaysSelectorContainer}>
              <DayOfWeekSelector form={form} />
            </div>
          </div>
        )}
      </FormGroup>

      {isRule && <NextScheduledRuns schedule={schedule} timezone={orgTimeZone} />}
    </Step>
  )
}
