import React, { ReactNode, useCallback, useContext, useMemo } from 'react'

import { gql } from '@apollo/client'
import { PublicOutlined, VerifiedUser } from '@mui/icons-material'
import { styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { CSSProperties, makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { diff as deepObjectDiff } from 'deep-object-diff'
import { Field, FieldProps, useField } from 'formik'

import { parsePhoneNumber } from 'libphonenumber-js'

import { HbText } from 'components/HbComponents/Text/HbText'
import { addressGoogleMapsURL } from 'components/entities/Address/utils'
import { FormikOtherInfoLabelInput } from 'components/library/OtherInfoLabelInput'
import SARFieldAdornment from 'components/library/SARFieldAdornment'
import {
  AlphanumericField,
  CountryAndStateField,
  LabeledField,
  PhoneExtField,
  PhoneNumberField,
  SelectBase,
  SelectDropdownInput,
  SelectField,
  SelectWithOtherField,
  TextField,
} from 'components/material/Form'

import { CustomFieldValue } from 'components/otherInfo/CustomFieldValue'
import { OTHER_INFO_FIELD_NAME, sanitizeOtherInfo } from 'components/otherInfo/shared'
import {
  DataWithOrdinaryOtherInfo,
  DataWithOtherInfo,
  excludeBasicInfo,
  extractBasicInfo,
} from 'helpers/otherInfoHelpers'
import { displayAddress, displayAddressType, validPartialDate, valueIsHyperlink } from 'helpers/uiHelpers'
import { DisplayAddressAddressFragment } from 'helpers/uiHelpers/__generated__/index.generated'
import { useFeatureFlag } from 'hooks'
import { DateFormatter } from 'hooks/DateFormatHooks'
import { IdFieldIcon } from 'icons/IdFieldIcon'
import { LabelFieldIcon } from 'icons/LabelFieldIcon'
import { BusinessAddressTypeEnums, PersonAddressTypeEnums, PhoneNumberEnums, PhoneNumberStrings } from 'strings/strings'
import {
  CustomFieldDatatypeEnum,
  FeatureFlag,
  FinancialInstitutionSubtypeEnum,
  FinancialInstitutionTypeEnum,
  LibraryTypeEnum,
  OtherInfoEntry,
  OtherInfoLabelTypeEnum,
  PhoneNumber,
} from 'types/api'
import { Theme } from 'types/hb'

import { AddressMap } from '../AddressMap'
import { EntityInformationContext, EntityInformationContextValue } from '../Information/EntityInformationContext'
import { EditableSection } from '../Information/EntityInformationLayout'
import {
  createInfoField,
  Editable,
  EditProps,
  getDiffStyles,
  InfoFieldArray,
  MaybeLinkValue,
  revisionUpdateHighlightColors,
} from '../InformationFields'

import { RevisionProps } from '../InformationFields.types'

export const StringField = createInfoField<string>((field) => field)

export const validateSparseDateField = (datepart: string | null | undefined) => {
  const invalidDatePart = typeof datepart === 'string' && !validPartialDate(datepart)

  return invalidDatePart ? 'Invalid Date Provided' : undefined
}

export const stackedFieldStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
}

export const useStackedFieldStyles = makeStyles((_theme: Theme) => ({
  root: stackedFieldStyles,
}))

export const StackedFieldContainer = styled('div')(() => stackedFieldStyles)

export const useInlineFieldStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  fill: {
    flex: 1,
  },
  second: {
    marginLeft: theme.spacing(2.5),
    flexBasis: 80,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  labelSearchInputPadding: {
    '& input': {
      // Need to do this to align the label search input with the LabeledField
      padding: '1px 0px 4px !important',
    },
  },
}))

export function getLabelProp(label: string, noLabels?: boolean) {
  return noLabels ? { placeholder: label } : { label }
}

export const AddressField = createInfoField<DisplayAddressAddressFragment>(
  (address) => displayAddress(address),
  (address) => displayAddressType(address),
  {
    link: {
      enabled: true,
      to: addressGoogleMapsURL,
      variant: 'text',
    },
  }
)

export interface AddressSarAdornments {
  addressLine1: string
  addressLine2: string
  locality: string
  postalCode: string
  country: string
  administrativeDistrictLevel1: string
}

export const defaultAddressSarAdornments: AddressSarAdornments = {
  addressLine1: '11: Address',
  addressLine2: '11: Address',
  locality: '12: City',
  postalCode: '14: ZIP/Postal Code',
  country: '15: Country',
  administrativeDistrictLevel1: '13: State',
}

export type AddressEditProps = EditProps & {
  noLabels?: boolean
  useNewerCountryAndStateFieldVersion?: boolean
}
export function AddressEdit({
  name,
  noLabels,
  entityType,
  sarAdornments = defaultAddressSarAdornments,
  useNewerCountryAndStateFieldVersion,
}: AddressEditProps & { sarAdornments?: AddressSarAdornments }) {
  const { root, fill, second } = useInlineFieldStyles()

  return (
    <div>
      {entityType === LibraryTypeEnum.People || entityType === LibraryTypeEnum.Businesses ? (
        <SelectWithOtherField
          label="Address Type"
          placeholder="Select an address type..."
          options={entityType === LibraryTypeEnum.People ? PersonAddressTypeEnums : BusinessAddressTypeEnums}
          name={name('addressType')}
          otherName={name('addressTypeOther')}
          {...getLabelProp('Address Type', noLabels)}
        />
      ) : null}
      <LabeledField
        adornment={<SARFieldAdornment field={sarAdornments.addressLine1} />}
        FieldComponent={TextField}
        name={name('addressLine1')}
        {...getLabelProp('Address line 1', noLabels)}
      />
      <LabeledField
        adornment={<SARFieldAdornment field={sarAdornments.addressLine2} />}
        FieldComponent={TextField}
        name={name('addressLine2')}
        {...getLabelProp('Address line 2', noLabels)}
      />
      <div className={root}>
        <LabeledField
          classes={{ root: fill }}
          FieldComponent={TextField}
          adornment={<SARFieldAdornment field={sarAdornments.locality} />}
          name={name('locality')}
          {...getLabelProp('City', noLabels)}
        />
        <LabeledField
          classes={{ root: classnames(fill, second) }}
          adornment={<SARFieldAdornment field={sarAdornments.postalCode} />}
          name={name('postalCode')}
          {...getLabelProp('Postal code', noLabels)}
          FieldComponent={AlphanumericField}
        />
      </div>
      <CountryAndStateField
        countryAdornment={<SARFieldAdornment field={sarAdornments.country} />}
        stateAdornment={<SARFieldAdornment field={sarAdornments.administrativeDistrictLevel1} />}
        countryLabel="Country"
        countryName={name('country')}
        stateLabel="State"
        stateName={name('administrativeDistrictLevel1')}
        useNewerVersion={useNewerCountryAndStateFieldVersion}
      />
    </div>
  )
}

AddressEdit.fragments = {
  entry: gql`
    fragment AddressEdit on BaseAddress {
      ... on PersonAddress {
        personAddressType: addressType
        addressTypeOther
      }

      ... on BusinessAddress {
        businessAddressType: addressType
        addressTypeOther
      }

      addressLine1
      addressLine2
      locality
      sublocality
      administrativeDistrictLevel1
      country
      postalCode

      ...AddressGeopoint
    }
    ${AddressMap.fragments.entry}
  `,
}

export const EmailField = StringField

export function EmailEdit({ name }: EditProps) {
  return (
    <LabeledField
      name={name()}
      FieldComponent={TextField}
      placeholder="Email"
      adornment={<SARFieldAdornment field="22: E-mail address" />}
    />
  )
}

export const WebsiteField = createInfoField<string>((website) => website, undefined, {
  link: { enabled: true, hideIcon: true },
})

export function WebsiteEdit({ name }: EditProps) {
  return (
    <LabeledField
      name={name()}
      FieldComponent={TextField}
      placeholder="Website"
      adornment={<SARFieldAdornment field="22a: Website (URL) address" />}
    />
  )
}

export const ExternalIdField = StringField

export function ExternalIdEdit() {
  return <LabeledField name="externalId" FieldComponent={TextField} placeholder="ID" />
}

export function ExternalIdEditable() {
  return (
    <Editable
      name="externalId"
      placeholder="External ID"
      Icon={VerifiedUser}
      Read={ExternalIdField}
      Edit={ExternalIdEdit}
    />
  )
}

export const BasicNameField = StringField

export function BasicNameEdit() {
  return <LabeledField name="name" FieldComponent={TextField} placeholder="Name" />
}

export function BasicNameEditable({
  Icon = IdFieldIcon,
  extractedValues,
  extractedData,
  extractedDiff,
  extractedPreviousData,
}: {
  Icon?: React.ComponentType<{ className?: string }>
  extractedValues?: Record<string, unknown>
  extractedData?: Record<string, unknown>
  extractedDiff?: Record<string, unknown>
  extractedPreviousData?: Record<string, unknown>
}) {
  return (
    <Editable
      name="name"
      placeholder="Name"
      Icon={Icon}
      Read={BasicNameField}
      Edit={BasicNameEdit}
      extractedDiff={extractedDiff}
      extractedValues={extractedValues}
      extractedData={extractedData}
      extractedPreviousData={extractedPreviousData}
    />
  )
}

export const UsernameField = StringField

export function UsernameEdit({ name }: EditProps) {
  return <LabeledField name={name()} FieldComponent={TextField} placeholder="Username" />
}

export function OtherInfoEdit({
  name,
  otherInfoLabelType,
}: EditProps & {
  otherInfoLabelType?: OtherInfoLabelTypeEnum
}) {
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)
  const [{ value: managed }] = useField<boolean>(name('managed'))
  const [{ value: datatype }] = useField<string>(name('datatype'))
  const [{ value: options }] = useField<string[]>(name('options'))
  const { root, labelSearchInputPadding } = useInlineFieldStyles()

  return (
    <div className={root}>
      {otherInfoLabelType ? (
        <FormikOtherInfoLabelInput
          useVisibilitySettings
          className={classnames(labelSearchInputPadding)}
          name={name('')}
          type={otherInfoLabelType}
          label={isRenameOtherInfoEnabled ? 'Name' : 'Label'}
          placeholder={isRenameOtherInfoEnabled ? 'Name' : 'Label'}
          variant="standard"
          hideLabel
          noSpacing
          disabled={managed}
        />
      ) : (
        <LabeledField
          name={name('label')}
          FieldComponent={TextField}
          placeholder={isRenameOtherInfoEnabled ? 'Name' : 'Label'}
          disabled={managed}
        />
      )}

      <CustomFieldValue
        label="Value"
        inputName={name('value')}
        datatype={datatype as CustomFieldDatatypeEnum}
        options={options}
        labeledFieldVariant
      />
    </div>
  )
}

export const displayPhone = (info: PhoneNumber) => {
  if (info.number) {
    let num
    try {
      num = parsePhoneNumber(info.number).formatInternational()
    } catch (error) {
      // If we fail to parse, we likely failed to detect a country
      num = info.number
    }

    if (info.extension) {
      return `${num}, ${info.extension}`
    }

    return num
  }

  return null
}

export const PhoneField = createInfoField<PhoneNumber>(
  displayPhone,
  (info) => info.type && PhoneNumberStrings[info.type]
)

export function PhoneEdit({ name }: EditProps) {
  const { root, fill, second } = useInlineFieldStyles()

  return (
    <div className={root}>
      <LabeledField
        classes={{ root: fill }}
        name={name('number')}
        placeholder="Phone number"
        FieldComponent={PhoneNumberField}
        adornment={<SARFieldAdornment field="21: Phone number" />}
      />
      <LabeledField
        classes={{ root: second }}
        adornment={<SARFieldAdornment field="21: Phone number ext." />}
        placeholder="Extension"
        name={name('extension')}
        FieldComponent={PhoneExtField}
      />
      <LabeledField
        classes={{ root: second }}
        placeholder="Type"
        options={PhoneNumberEnums}
        name={name('type')}
        FieldComponent={SelectField}
        adornment={<SARFieldAdornment field="20: Type" />}
      />
    </div>
  )
}

export function displayDateOrTime(seenAt: string, formatDate: DateFormatter) {
  if (!seenAt) {
    return ''
  }

  if (seenAt.indexOf('T') === -1) {
    return formatDate(seenAt, '', 'MM/DD/YYYY')
  }

  return formatDate(seenAt, '', 'MM/DD/YYYY, hh:mm A')
}

// Formats for display when month is only one character,
// eg month=1 => month=01
const formatMonth = (value: string) => (value.length === 1 ? `0${value}` : value)

export function displayYearMonth(value: string) {
  if (!value) {
    return ''
  }

  const [year, month] = value.split('-')
  return [formatMonth(month), year].join('/')
}

const ForeignField = createInfoField<boolean>(
  (value) => (value === true ? 'Yes' : value === false ? 'No' : ''),
  () => 'Foreign?'
)

const useYesOrNoSelectFieldStyles = makeStyles((theme: Theme) => ({
  input: {
    paddingBottom: theme.spacing(2),
  },
}))

interface YesOrNoSelectFieldInnerProps {
  children?: ReactNode
  label: string
  name: string
  disabled?: boolean
}

function YesOrNoSelectFieldOld({ name, label, disabled }: YesOrNoSelectFieldInnerProps) {
  const classes = useYesOrNoSelectFieldStyles()

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<boolean>) => {
        const value = field.value?.toString()

        return (
          <SelectBase
            classes={{ root: classes.input }}
            label={label}
            placeholder="Select one..."
            defaultOptionValue={null}
            disabled={disabled}
            options={[
              { display: 'Yes', value: 'true' },
              { display: 'No', value: 'false' },
            ]}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = event.target.value === 'true' ? true : event.target.value === 'false' ? false : null
              form.setFieldValue(field.name, newValue)
            }}
          />
        )
      }}
    </Field>
  )
}

const YesOrNoSelectFieldNew = ({ label, name, disabled }: YesOrNoSelectFieldInnerProps) => {
  return (
    <SelectDropdownInput
      label={label}
      name={name}
      disabled={disabled}
      options={[
        { display: 'Yes', value: true },
        { display: 'No', value: false },
      ]}
    />
  )
}

export interface YesOrNoSelectFieldProps extends YesOrNoSelectFieldInnerProps {
  useNewerVersion?: boolean
}

export const YesOrNoSelectField = ({ useNewerVersion, ...props }: YesOrNoSelectFieldProps) => {
  return useNewerVersion ? <YesOrNoSelectFieldNew {...props} /> : <YesOrNoSelectFieldOld {...props} />
}

function ForeignEdit() {
  const { root } = useStackedFieldStyles()
  return <LabeledField classes={{ root }} FieldComponent={YesOrNoSelectField} label="Foreign?" name="foreign" />
}

export function ForeignEditable() {
  return <Editable name="foreign" placeholder="Foreign" Icon={PublicOutlined} Read={ForeignField} Edit={ForeignEdit} />
}

export interface Classification<Type, Subtype> {
  type: Type | 'OTHER'
  typeOther: string
  subtype: Subtype | 'OTHER'
  subtypeOther: string
}

export function createClassificationField<
  Type = FinancialInstitutionTypeEnum,
  Subtype = FinancialInstitutionSubtypeEnum
>(
  displayType: (typeValue: Type) => string,
  displaySubtype: (subtypeValue: Subtype) => string,
  getSecondaryDisplay?: (c: Classification<Type, Subtype>) => string
) {
  return createInfoField<Classification<Type, Subtype>>((c) => {
    const { type, typeOther, subtype, subtypeOther } = c
    const typeStr = type !== 'OTHER' ? displayType(type) : typeOther
    const subtypeStr = subtype !== 'OTHER' ? displaySubtype(subtype) : subtypeOther

    if (typeStr) {
      if (subtypeStr) {
        return `${typeStr}, ${subtypeStr}`
      }

      return typeStr
    }

    return null
  }, getSecondaryDisplay)
}

const StyledMaybeLinkValue = styled(MaybeLinkValue)(({ theme }) => ({
  color: theme.palette.styleguide.textGreyDark,
}))

export const OtherInfoLabel = styled(HbText)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}))

export const OtherInfoValue = styled(HbText)(() => ({}))

const OtherInfoContainer = styled('div')<RevisionProps>(
  ({ theme, hasDeletedDataInRevision, hasUpdatedDataInRevision }) => ({
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(),
    ...getDiffStyles({ theme, hasDeletedDataInRevision, hasUpdatedDataInRevision }),
    [`&&& ${OtherInfoLabel}`]: hasDeletedDataInRevision
      ? {
          color: theme.palette.styleguide.textGreyDark,
        }
      : hasUpdatedDataInRevision
      ? {
          fontWeight: theme.fontWeight.bold,
          color: revisionUpdateHighlightColors.color,
        }
      : {},
  })
)

export interface OtherInfoFieldProps extends RevisionProps {
  className?: string
  data: Pick<OtherInfoEntry, 'label' | 'value'>
  hideLabel?: boolean
}

export const OtherInfoField = ({
  className,
  data,
  hideLabel = false,
  hasDeletedDataInRevision,
  hasUpdatedDataInRevision,
}: OtherInfoFieldProps) => {
  const { label, value } = data
  const isValueLink = valueIsHyperlink(value)
  const valueEl = <StyledMaybeLinkValue hideLinkIcon value={value} to={isValueLink ? value : undefined} />

  return (
    <OtherInfoContainer
      data-has-revision={hasDeletedDataInRevision || hasUpdatedDataInRevision}
      className={className}
      hasDeletedDataInRevision={hasDeletedDataInRevision}
      hasUpdatedDataInRevision={hasUpdatedDataInRevision}
    >
      {!hideLabel && (
        <>
          <OtherInfoLabel>{label}</OtherInfoLabel>
          <br />
        </>
      )}
      <OtherInfoValue color="secondary">{valueEl}</OtherInfoValue>
    </OtherInfoContainer>
  )
}

const getOtherInfoDiffKey = (value: OtherInfoEntry) => value.label

export function OtherInfoEditable<Entity extends DataWithOtherInfo>({
  otherInfoLabelType,
}: {
  otherInfoLabelType?: OtherInfoLabelTypeEnum
}) {
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)
  const { data, previousData, values } =
    useContext<EntityInformationContextValue<Entity, Entity & DataWithOrdinaryOtherInfo>>(EntityInformationContext)

  const otherInfoData = data.otherInfo
  const previousOtherInfoData = previousData?.otherInfo

  const EditComponent = useCallback(
    (editProps: EditProps) => <OtherInfoEdit otherInfoLabelType={otherInfoLabelType} {...editProps} />,
    [otherInfoLabelType]
  )

  const transformOtherInfoBeforeSave = useCallback(
    (updated) => {
      // Recombine all other info before submitting, including any basic info
      // not just the updated ordinary other info values.
      // This will affect the order of saved items,
      // at least the first time a basic info field is sent to the end of the list.
      const reconstructedOtherInfo: OtherInfoEntry[] = [
        ...updated,
        ...extractBasicInfo(otherInfoData).map(sanitizeOtherInfo),
      ]
      return reconstructedOtherInfo
    },
    [otherInfoData]
  )

  const extractedData = useMemo(() => ({ otherInfo: excludeBasicInfo(otherInfoData) }), [otherInfoData])

  const extractedPreviousData = useMemo(() => {
    if (!previousOtherInfoData) return undefined
    return { otherInfo: excludeBasicInfo(previousOtherInfoData) }
  }, [previousOtherInfoData])

  const extractedDiff = useMemo(() => {
    if (!extractedPreviousData) return undefined
    const diff = deepObjectDiff(extractedPreviousData.otherInfo, extractedData.otherInfo)
    return { otherInfo: diff }
  }, [extractedData, extractedPreviousData])

  const extractedValues = { otherInfo: excludeBasicInfo(values.otherInfo) }

  return (
    <EditableSection
      extractedData={extractedData}
      extractedPreviousData={extractedPreviousData}
      extractedValues={extractedValues}
      extractedDiff={extractedDiff}
      name={OTHER_INFO_FIELD_NAME}
      title={isRenameOtherInfoEnabled ? 'Additional Details' : 'Other'}
      transformValues={transformOtherInfoBeforeSave}
    >
      {(params) => {
        return (
          <InfoFieldArray<OtherInfoEntry>
            getDiffKey={getOtherInfoDiffKey}
            isDeleteDisabled={(item) => !!item.managed}
            placeholder={isRenameOtherInfoEnabled ? 'Additional Details' : 'Other Information'}
            params={params}
            Icon={LabelFieldIcon}
            Read={OtherInfoField}
            Edit={EditComponent}
          />
        )
      }}
    </EditableSection>
  )
}
