import { ChangeEvent, ComponentProps } from 'react'

import {
  InputProps as MaterialInputProps,
  TextField,
  TextFieldProps,
  outlinedInputClasses,
  styled,
} from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import Big from 'big.js'
import { connect as formikConnect, FormikContextType, getIn } from 'formik'

import { isEmpty } from 'lodash'

import { Control, Controller, FieldPathByValue, FieldValues, Path } from 'react-hook-form'

import { useSelector } from 'actions/store'
import { BaseAutocomplete } from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'
import { EnumField } from 'components/material/Form/EnumField'
import { HbMoneyInput, HbMoneyInputProps, MoneyFormattedInput } from 'components/material/Form/MoneyInput'
import { getEnum } from 'helpers/stateHelpers'
import { HbMoneyInput as HbMoneyInputType } from 'types/api'
import { Theme } from 'types/hb'

import { LabeledField } from './FieldElements'

const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: {
      width: '100%',
      marginLeft: theme.spacing(2),
    },
  }
})

export function MoneyInputField({ InputProps = {}, ...props }: ComponentProps<typeof TextField>) {
  return (
    <TextField
      InputProps={{
        inputComponent: MoneyFormattedInput,
        ...InputProps,
        inputProps: { fixedDecimalScale: false, decimalScale: 12, autoComplete: 'off', ...InputProps.inputProps },
      }}
      {...props}
    />
  )
}

export interface Props {
  name: string
  fiatOnly?: boolean
  InputProps?: MaterialInputProps
  variant?: TextFieldProps['variant']
  size?: 'small' | 'medium'
}

const formikNewEventPayload = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, currency: string) => {
  const eventValue = event.target.value
  const newValue = {
    amount: isEmpty(eventValue) ? null : eventValue,
    currency: currency || 'USD',
  }
  return {
    target: {
      name: event.target.name,
      value: newValue,
    },
  }
}

const getCurrencyType = (fiatOnly: boolean) => (fiatOnly ? 'fiatCurrencyInputCode' : 'currencyInputCode')

const getAmount = (value: string | Big) => {
  let amount = value
  if (typeof amount !== 'string') {
    // maybe a Big or undefined
    amount = amount?.toString()
  }
  return amount
}

const useFormikCurrency = (formik: FormikContextType<unknown>, name: string, fiatOnly: boolean) => {
  const value = getIn(formik.values, name)
  const error = getIn(formik.errors, name)

  const amount = getAmount(value ? value.amount : value)
  const currency = value ? value.currency : 'USD'

  const currencyType = getCurrencyType(fiatOnly)

  return { amount, currency, currencyType, error }
}

export function FormikMoneyWithCurrencyPickerInput({
  formik,
  name,
  fiatOnly = false,
  variant,
  size,
  ...props
}: Props & {
  formik: FormikContextType<unknown>
}) {
  const { amount, currency, currencyType, error } = useFormikCurrency(formik, name, fiatOnly)
  const hasValue = !!amount
  const classes = useStyles()
  return (
    <>
      <MoneyInputField
        onChange={(event) => formik.handleChange(formikNewEventPayload(event, currency))}
        value={amount}
        name={name}
        variant={variant}
        size={size}
        error={error}
        {...props}
      />
      {/*
      NOTE: we are using amount in the key for EnumField to force the component to re-initialize.
      Otherwise, change to amount are lost when the currency changes.
      */}
      <EnumField
        enum={currencyType}
        key={`${name}.currency.${amount}`}
        name={`${name}.currency`}
        label="Currency"
        fullWidth
        required={hasValue}
        classes={{ root: classes.root }}
        variant={variant}
        size={size}
        InputProps={{
          onChange: (e) => {
            formik.handleChange({ target: { name, value: { amount, currency: e.target.value } } })
          },
        }}
      />
    </>
  )
}

export const MoneyWithCurrencyPickerField = formikConnect<Props>(FormikMoneyWithCurrencyPickerInput)

export function FormikMoneyWithCurrencyAutocompleteInput({
  formik,
  name,
  fiatOnly = false,
  variant,
  size,
  ...props
}: Props & {
  formik: FormikContextType<unknown>
}) {
  const { amount, currency, currencyType, error } = useFormikCurrency(formik, name, fiatOnly)
  const options = useSelector((state) => getEnum(state, currencyType))
  const optionLabels = options.map((o) => o.display)
  const classes = useStyles()
  return (
    <>
      <MoneyInputField
        onChange={(event) => formik.handleChange(formikNewEventPayload(event, currency))}
        value={amount}
        name={name}
        variant={variant}
        size={size}
        error={error}
        {...props}
        InputProps={{
          ...props.InputProps,
          onBlur: (e) => {
            props.InputProps?.onBlur?.(e)
            formik.handleBlur(e)
          },
        }}
      />
      <LabeledField
        FieldComponent={BaseAutocomplete}
        name={`${name}.currency`}
        label="Currency"
        classes={{ root: classes.root }}
        onInputChange={(newCurrencyValue: string) => {
          const newValue = { amount, currency: newCurrencyValue }
          const newEvent = { target: { name, value: newValue } }
          formik.handleChange(newEvent)
        }}
        onBlur={formik.handleBlur}
        value={currency}
        options={optionLabels}
        onChange={() => {}} // We want to rely on onInputChange to update the value
        hideInputContainer
        setValueOnBlur
        freeSolo
      />
    </>
  )
}

export const MoneyWithCurrencyAutocompleteField = formikConnect<Props>(FormikMoneyWithCurrencyAutocompleteInput)

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(2),
}))

const StyledHbMoneyInput = styled(({ className, ...props }: HbMoneyInputProps) => (
  <HbMoneyInput inputContainerClassName={className} {...props} />
))(() => ({
  flex: 1,
  [`& .${outlinedInputClasses.input}`]: {
    paddingLeft: 0,
  },
}))

const StyledAutocomplete = styled(BaseAutocomplete)(() => ({
  flex: 1,
}))

interface HbRHFMoneyWithCurrencyAutocompleteInputProps<
  FormValues extends FieldValues,
  FieldName extends FieldPathByValue<FormValues, HbMoneyInputType>
> {
  amountLabel: string
  className?: string
  control: Control<FormValues>
  fiatOnly?: boolean
  InputProps?: MaterialInputProps
  name: FieldName
}

export const HbRHFMoneyWithCurrencyAutocompleteInput = <
  FormValues extends FieldValues,
  FieldName extends FieldPathByValue<FormValues, HbMoneyInputType>
>({
  amountLabel,
  className,
  control,
  name,
  fiatOnly = false,
  ...props
}: HbRHFMoneyWithCurrencyAutocompleteInputProps<FormValues, FieldName>) => {
  const currencyType = getCurrencyType(fiatOnly)
  const options = useSelector((state) => getEnum(state, currencyType))
  const optionLabels = options.map((o) => o.display)

  return (
    <Container className={className}>
      <Controller
        control={control}
        name={`${name}.amount` as Path<FormValues>}
        render={({ field, fieldState }) => {
          return (
            <StyledHbMoneyInput
              label={amountLabel}
              errors={fieldState.error?.message}
              {...field}
              value={field.value}
              inputRef={field.ref}
              {...props}
              InputProps={{
                ...props.InputProps,
                onBlur: (e) => {
                  props.InputProps?.onBlur?.(e)
                  field.onBlur()
                },
              }}
            />
          )
        }}
      />
      <Controller
        control={control}
        name={`${name}.currency` as Path<FormValues>}
        render={({ field, fieldState }) => (
          <StyledAutocomplete
            {...field}
            errorMessage={fieldState.error?.message}
            freeSolo
            label="Currency"
            options={optionLabels}
          />
        )}
      />
    </Container>
  )
}
