import { GeneratedDocumentType } from 'types/api'

import { assertUnreachable } from 'types/hb'

import { CSV_ATTACHMENT_TYPE, PDF_ATTACHMENT_TYPE } from './AttachmentViewerInternal.types'

export function contentTypeForReviewDocument(type: GeneratedDocumentType): string {
  switch (type) {
    case GeneratedDocumentType.Summary:
    case GeneratedDocumentType.CtrSummary:
    case GeneratedDocumentType.InformationRequestResponse:
    case GeneratedDocumentType.FintracStrSummary:
    case GeneratedDocumentType.FintracStrSummaryPreview:
    case GeneratedDocumentType.RequestForInformationResponse:
    case GeneratedDocumentType.TipSubmission:
      return PDF_ATTACHMENT_TYPE
    case GeneratedDocumentType.TransactionsCsv:
    case GeneratedDocumentType.TransactionsCsvAll:
      return CSV_ATTACHMENT_TYPE
    case GeneratedDocumentType.CtrXml:
    case GeneratedDocumentType.CtrResponse:
    case GeneratedDocumentType.SarResponse:
    case GeneratedDocumentType.FintracStrJson:
    case GeneratedDocumentType.FintracStrJsonPreview:
    case GeneratedDocumentType.GoamlXml:
    case GeneratedDocumentType.InvestigationAttachment:
    case GeneratedDocumentType.InvestigationDocuments:
    case GeneratedDocumentType.SarPdf:
    case GeneratedDocumentType.SarXml:
    case GeneratedDocumentType.SurveyFile:
      return 'not_supported_for_preview'
    default:
      return assertUnreachable(type)
  }
}
