import { useMemo, useRef } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Box, styled } from '@mui/material'

import { FormProvider, useForm } from 'react-hook-form'

import { z } from 'zod'

import { useSelector } from 'actions/store'
import HbRHFDropdown from 'components/HbComponents/Form/HbInputs/HbDropdown/HbRHFDropdown'
import HbRHFTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbRHFTextInput'
import { HbButton } from 'components/HbComponents/HbButton'
import TypeAheadEditor, { EditorApi } from 'components/library/TypeAheadEditor'
import { getOrganizationReviewTypes } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { TrashOutlineIcon } from 'icons'
import { Template, TemplateError } from 'reducers/narrativeTemplatesReducer'
import { FeatureFlag } from 'types/api'

interface Props {
  template: Template
  error: TemplateError
  onSubmit: (template: Template) => void
  onDelete?: (template: Template) => void
  onCancel: () => void
}

const ScrollContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  overflowY: 'auto',
}))

const StyledTypeAheadEditor = styled(TypeAheadEditor)(() => ({
  flex: 1,
}))

export const formSchema = z.object({
  token: z.string().optional(),
  name: z.string(),
  applicableReviewTypeToken: z.string().optional(),
  content: z.string(),
})

type FormSchema = z.infer<typeof formSchema>

function NarrativeTemplateForm(props: Props) {
  const { template, onSubmit, onDelete, onCancel, error } = props
  const reviewTypes = useSelector(getOrganizationReviewTypes)
  const reviewTypeOptions = useMemo(
    () => [{ value: 'any', display: 'Any' }].concat(reviewTypes.map((t) => ({ value: t.token, display: t.name }))),
    [reviewTypes]
  )
  const editorApi = useRef<EditorApi>(null)
  const liquidEnabled = useFeatureFlag(FeatureFlag.EnableSmartValuesInNarrativeTemplates)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: template,
    mode: 'onChange',
  })
  const { control, handleSubmit, setValue, formState } = form

  return (
    <FormProvider {...form}>
      {error && <div>{error}</div>}
      <ScrollContainer>
        <Box display="flex" flexDirection="row" gap={2} pb={2}>
          <HbRHFTextInput label="Template Name" name="name" value={template.name} />
          <HbRHFDropdown
            fullWidth
            control={control}
            variant="outlined"
            label="Review Type"
            name="applicableReviewTypeToken"
            value={template.applicableReviewTypeToken}
            options={reviewTypeOptions}
            defaultOptionValue="any"
          />
        </Box>

        <StyledTypeAheadEditor
          initialValue={template.content}
          placeholder="Write out your narrative template here..."
          onChange={(value: string) => setValue('content', value)}
          ref={editorApi}
          richText={{ liquid: liquidEnabled }}
        />
      </ScrollContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        px={3}
        py={2}
        position="sticky"
        bottom={0}
        zIndex={3}
        boxShadow="0px -4px 16px 0px #0000001A"
      >
        {onDelete && (
          <HbButton label="Delete" onClick={() => onDelete(template)} Icon={TrashOutlineIcon} variant="secondary" />
        )}
        <Box flexGrow={1} />
        <HbButton label="Cancel" onClick={onCancel} variant="secondary" />
        <HbButton
          label="Save"
          disabled={formState.isSubmitting || !formState.isValid}
          onClick={handleSubmit(onSubmit)}
          color="primary"
        />
      </Box>
    </FormProvider>
  )
}

export default NarrativeTemplateForm
