import React, {
  ComponentProps,
  ReactNode,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import { css } from '@emotion/css'
import { Box, IconButton, Typography, styled, Theme, CSSObject } from '@mui/material'

import { DiffType } from 'deep-object-diff'
import { FieldArray, FieldArrayRenderProps, Formik, FormikHelpers, FormikProps } from 'formik'
import { identity, isEmpty } from 'lodash'

import { HbText, Props as HbTextProps } from 'components/HbComponents/Text/HbText'
import { usePendingChanges } from 'components/cases/Tabs/PendingChanges'
import { EditMode, EditModeParams, EditModeRef } from 'components/library/EditMode'
import ExternalLink, { Props as ExternalLinkProps } from 'components/library/ExternalLink'
import { FormConfigurationContext } from 'components/material/Form/FormConfiguration'
import { valueIsHyperlink } from 'helpers/uiHelpers'
import { DateFormatter, useDateFormatter } from 'hooks/DateFormatHooks'
import { CircleRemoveIcon } from 'icons'
import { LibraryTypeEnum } from 'types/api'

import { PreserveFormState } from './Edit/PreserveFormState'
import { EntityInformationContext, EntityInformationContextValue } from './Information/EntityInformationContext'
import {
  getArrayHasDeletedDataInRevision,
  getArrayHasUpdatedDataInRevision,
  getArrayHasUpdatedMetadataInRevision,
  getHasDeletedDataInRevision,
  getHasUpdatedDataInRevision,
  getHasUpdatedMetadataInRevision,
  getReadDataWithDeletionsIncluded,
} from './InformationFields.helpers'
import { BaseMetadata, BaseMetadataWithValue, GetArrayFieldDiffKey, RevisionProps } from './InformationFields.types'
import {
  dataTypeOfEntry,
  findLibraryInfoByKey,
  LibraryDataTypeKey,
  libraryDataTypeKeyFromSnakeDataTypeKey,
} from './LibraryQueries'

export interface EditProps {
  name: (key?: string) => string // Gets the property name that formik fields should bind to
  entityType?: LibraryTypeEnum
}

// Gets the specified name value out of the full values object, in order
// to scope the form values object to that key.
export function extractSubvalues<V extends Record<string, unknown>>(
  name: string,
  values: V,
  saveAsRecordAttributes = false
): Partial<V> {
  const currentValue = values[name]

  // If legacy entity structure, cast subvalues in expected entity format
  if (saveAsRecordAttributes && currentValue && typeof currentValue === 'object') {
    const inputEntity = currentValue as { type: string; token: string }
    return { [name]: { type: inputEntity.type, key: { token: inputEntity.token } } } as Partial<V>
  }

  return { [name]: currentValue } as Partial<V>
}

function isValueEmpty(value: unknown): boolean {
  if (value === undefined || value === null || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  if (value && typeof value === 'object') {
    // Check whether object is empty, except the __typename that all GQL objects have
    return Object.entries(value).every(([key, keyValue]) => key === '__typename' || isValueEmpty(keyValue))
  }

  return false
}

export const getAliasedDiffField = <T extends Record<string, unknown>>(
  name: string,
  aliasedName: keyof T,
  diff: DiffType<Partial<T>, Partial<T>> | null | undefined
) => (diff && aliasedName in diff ? { [name]: diff[aliasedName as keyof typeof diff] } : undefined)

export const getAliasedPreviousDataField = <T extends Record<string, unknown>>(
  name: string,
  aliasedName: keyof T,
  previousData: T | undefined
) =>
  previousData && aliasedName in previousData
    ? { [name]: previousData[aliasedName as keyof typeof previousData] }
    : undefined

interface EditableProps<V, T, FinalValues> {
  // Key used to retrieve data from values (for edit-mode) and data (for read-mode).
  // Follows the Formik pattern.
  name: string
  // If extractedDiff is provided, it's used as the data source
  // for which values have changed between revisions
  extractedDiff?: V
  extractedValues?: V // If extractedValues is provided, it's given to Formik as initialValues
  extractedData?: V // If extractedData is provided, it's used as the data source for read-mode
  // If extractedPreviousData is provided, it's used as the data source
  // for showing previously existing values that have been deleted:
  extractedPreviousData?: V

  placeholder: string

  // If set, used to transform values before saving
  transformValues?: (values: V) => FinalValues

  // Indicates a relationship is being edited, rather than an attribute.
  // If set, will output values to the root of the transaction entry, rather than the attributes.
  saveAsRecordAttributes?: boolean

  additionalTopLevelData?: Record<string, unknown>

  Icon?: React.ComponentType<{ className?: string }>
  Read: React.ComponentType<InfoFieldProps<T>>
  Edit: React.ComponentType<Partial<EditProps>>

  children?: (params: EditModeParams) => ReactNode
  childrenFirst?: boolean
}

export type EditableArrayParams = EditModeParams &
  FieldArrayRenderProps & {
    defaultValue: unknown
    values: Record<string, unknown>
    data: Record<string, unknown>
    diff?: DiffType | null
    previousData?: Record<string, unknown>
  }

export type EditableArrayProps<V> = {
  additionalTopLevelData?: Record<string, unknown>
  // Whether to allow adding a new value to the field array
  canAdd?: boolean
  className?: string
  // Key used to retrieve data from values (for edit-mode) and data (for read-mode).
  // Follows the Formik pattern.
  name: string
  // If extractedDiff is provided, it's used as the data source
  // for which values have changed between revisions
  extractedDiff?: V
  extractedValues?: V // If extractedValues is provided, it's given to Formik as initialValues
  extractedData?: V // If extractedData is provided, it's used as the data source for read-mode
  // If extractedPreviousData is provided, it's used as the data source
  // for showing previously existing values that have been deleted:
  extractedPreviousData?: V
  // Indicates a relationship is being edited, rather than an attribute.
  // If set, will output values to the root of the transaction entry, rather than the attributes.
  saveAsRecordAttributes?: boolean

  // Indicates that the resulting mutation will place the relevant attribute values into the root
  // of the transaction entry.
  saveAsTopLevelObject?: boolean

  /**
   * Indicates that the data should be marked as archived when removed, rather than deleted
   */
  archiveOnRemove?: boolean

  /**
   * Gets the default value when adding a new value to the array
   */
  getDefaultValue?: (formProps: FormikProps<Partial<V>>) => unknown

  validationSchema?: unknown
  children: (params: EditableArrayParams) => ReactNode
  defaultValue?: unknown
  addLabel?: string
  displayAddWhenEmpty?: boolean
  autoAddNew?: boolean
  transformValues?: (v: unknown[]) => unknown[]
}

type RelationshipDefinition = { key: { token?: string }; type: string }
type RelationshipInput = { key?: unknown; type?: string; token?: string }

// Separates values representing library relationships from values that represent entity attributes.
function splitAttributesForType(valueObject: Record<string, RelationshipInput>) {
  const attributes: Record<string, unknown> = {}
  const relationships: Record<string, RelationshipInput> = {}
  Object.keys(valueObject).forEach((attribute) => {
    if (findLibraryInfoByKey(attribute as LibraryDataTypeKey)) {
      // must be a relationship-indicating key-value
      relationships[attribute] = valueObject[attribute]
    } else if (attribute !== 'token') {
      // must be an actual attribute
      attributes[attribute] = valueObject[attribute]
    }
  })
  return { attributes, relationships }
}

// Ensures that relationship definitions contain only a key and type, for sending back to the server via a mutation.
function transformRelationships(relationships: { [relation: string]: RelationshipInput }): {
  [relation: string]: RelationshipDefinition
} {
  const newRelationships: { [relation: string]: RelationshipDefinition } = {}
  Object.keys(relationships).forEach((attrName) => {
    const relationship = relationships[attrName]
    if (relationship) {
      if (!relationship.key) {
        // eslint-disable-next-line no-param-reassign
        newRelationships[attrName] = {
          key: { token: relationship.token },
          type: findLibraryInfoByKey(attrName as LibraryDataTypeKey).category,
        }
      } else if (relationship.key && relationship.type) {
        newRelationships[attrName] = {
          key: relationship.key as { token: string },
          type: relationship.type,
        }
      }
    }
  })
  return newRelationships
}

// Transforms form values into root-level values
function transformValuesIntoMutation(cleanValues: Record<string, any>, name: string, data: Record<string, unknown>) {
  const mainFormObjectDataTypeKey = libraryDataTypeKeyFromSnakeDataTypeKey(dataTypeOfEntry(data))
  const mainFormObjectKey: Record<string, unknown> = {
    key: { token: data.token },
    type: findLibraryInfoByKey(mainFormObjectDataTypeKey).category,
  }

  const valueForName = cleanValues[name]
  // eslint-disable-next-line no-restricted-syntax
  for (const i in valueForName) {
    const cleanValue = valueForName[i] as Record<string, RelationshipInput>
    const { attributes, relationships } = splitAttributesForType(cleanValue)
    valueForName[i] = {
      attributes,
      ...transformRelationships(relationships),
      // Retain connection from this sub array element to the main form object
      [mainFormObjectDataTypeKey]: mainFormObjectKey,
    }

    if (cleanValue.token) {
      ;(valueForName[i] as RelationshipInput).key = {
        token: cleanValue.token,
      }
    }
  }
}

const defaultTransformValues = (v: unknown) => v

export function EditableArray<V extends Record<string, unknown>>(props: EditableArrayProps<V>) {
  const {
    additionalTopLevelData,
    className,
    name,
    extractedValues,
    extractedData,
    extractedPreviousData,
    extractedDiff,
    saveAsRecordAttributes = false,
    saveAsTopLevelObject = false,
    defaultValue = {},
    validationSchema,
    addLabel,
    displayAddWhenEmpty,
    canAdd = true,
    archiveOnRemove = false,
    getDefaultValue,
    autoAddNew = true,
    transformValues = defaultTransformValues,
    children,
  } = props
  const editMode = useRef<EditModeRef | null>(null)
  const { readOnly } = useContext(FormConfigurationContext)
  const { pendingChanges, clearPendingChanges } = usePendingChanges<V>()

  const {
    values,
    data,
    diff: contextDiff,
    previousData: contextPreviousData,
    save,
  } = useContext(EntityInformationContext as React.Context<EntityInformationContextValue<Record<string, unknown>, V>>)

  const initialValues = { ...(extractedValues ?? extractSubvalues(name, values)), ...(pendingChanges || {}) }
  const derivedData = extractedData ?? data

  const diff = extractedDiff ?? contextDiff
  const previousData = extractedPreviousData ?? contextPreviousData

  const onSave = useCallback(
    async (newValues: V, actions: FormikHelpers<V>) => {
      const transform = (v: unknown[] | undefined) => {
        const filtered = v?.filter((o: unknown) => !isEmpty(o)) as unknown[]
        return transformValues(filtered)
      }
      // Remove any completely empty values from the array and do transform
      const fieldValues = newValues[name] as unknown[] | undefined
      const cleanValues = {
        ...newValues,
        [name]: transform(fieldValues),
      }

      // Sometimes the UI allows the customer to edit objects that aren't the main form object
      // (like editing Library Signatories through a Library Bank Account form) but we still want
      // to extract and submit those objects as root-level entries in the resulting mutation.
      if (saveAsTopLevelObject) {
        transformValuesIntoMutation(cleanValues, name, data)
      }

      actions.setSubmitting(true)

      if (save) {
        let success = false
        if (saveAsRecordAttributes) {
          // in this case we're operating on a relationship that was fetched from the server, which needs to be
          // transformed by wrapping the token in a key and dropping unnecessary attributes
          success = await save({}, cleanValues)
        } else if (saveAsTopLevelObject) {
          success = await save({}, {}, cleanValues)
        } else {
          success = await save(cleanValues, undefined, additionalTopLevelData)
        }

        if (success) {
          editMode.current?.setMode('read')
          clearPendingChanges()
        }
      }

      actions.setSubmitting(false)
    },
    [
      additionalTopLevelData,
      clearPendingChanges,
      data,
      name,
      save,
      saveAsRecordAttributes,
      saveAsTopLevelObject,
      transformValues,
    ]
  )

  const noCurrentData =
    isValueEmpty(initialValues[name]) && isValueEmpty((derivedData as Record<string, unknown>)[name])

  const noPreviousData = isValueEmpty(previousData?.[name])

  const noData = noCurrentData && noPreviousData

  if (readOnly && noData) {
    return null
  }

  return (
    <Formik<Partial<V>>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange
      onSubmit={onSave}
    >
      {(formikRenderProps) => {
        const { submitForm, isSubmitting, values: currentValues, dirty } = formikRenderProps
        const fieldData = (currentValues as any)[name] ?? []
        const noFieldData = fieldData.length === 0

        return (
          <>
            <FieldArray name={name}>
              {(fieldArrayParams) => {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
                const archiveOrRemoveItem = <T extends unknown>(index: number): T | undefined => {
                  if (archiveOnRemove) {
                    const currentValue = fieldData[index]
                    fieldArrayParams.replace(index, {
                      ...currentValue,
                      archived: true,
                    })
                    return undefined
                  }
                  return fieldArrayParams.remove(index)
                }

                const _defaultValue = getDefaultValue?.(formikRenderProps) || defaultValue

                return (
                  <EditMode
                    className={className}
                    ref={editMode}
                    saveDisabled={isSubmitting}
                    save={submitForm}
                    isEmpty={noFieldData && noPreviousData}
                    addLabel={addLabel}
                    name={name}
                    displayAddWhenEmpty={displayAddWhenEmpty}
                    canAdd={canAdd}
                    autoAddNew={autoAddNew}
                    addItem={() => fieldArrayParams.push(_defaultValue)}
                  >
                    {(params) =>
                      children({
                        ...params,
                        ...fieldArrayParams,
                        remove: archiveOrRemoveItem,
                        defaultValue,
                        values: currentValues,
                        data: derivedData as Record<string, unknown>,
                        diff,
                        previousData,
                      })
                    }
                  </EditMode>
                )
              }}
            </FieldArray>
            <PreserveFormState currentValues={currentValues} initialValues={initialValues} dirty={dirty} />
          </>
        )
      }}
    </Formik>
  )
}

export function EditableRelationshipArray<V extends Record<string, unknown>>(props: EditableArrayProps<V>) {
  return <EditableArray {...props} saveAsRecordAttributes />
}

export function EditableTopLevelObjectArray<V extends Record<string, unknown>>(props: EditableArrayProps<V>) {
  return <EditableArray {...props} saveAsTopLevelObject />
}

export const useScrollToChangedField = <Element extends HTMLElement>({
  hasDeletedDataInRevision,
  hasUpdatedDataInRevision,
}: RevisionProps) => {
  const ref = useRef<Element | null>(null)

  useEffect(() => {
    if (!(hasUpdatedDataInRevision || hasDeletedDataInRevision) || !ref.current) return
    ref.current.scrollIntoView()
  }, [hasDeletedDataInRevision, hasUpdatedDataInRevision])

  return {
    ref,
  }
}

export const createGetSingleActiveDefaultMetadataValue = <
  Name extends string,
  Value extends BaseMetadata,
  Values extends Record<Name, Value[]> & { [key: string]: unknown }
>(
  name: Name,
  valueInMetadata: unknown = null
) => {
  const getSingleActiveMetadataDefaultValue = ({ values: currentFormValues }: FormikProps<Partial<Values>>) => {
    const fieldData = currentFormValues[name] ?? []
    const activeValues = fieldData.filter(({ active }) => active)
    const firstItemShouldBeActive = !activeValues.length
    return { active: firstItemShouldBeActive, value: valueInMetadata }
  }
  return getSingleActiveMetadataDefaultValue
}

const StyledExternalLink = styled(ExternalLink)(() => ({}))
const StyledInfoFieldText = styled(HbText)(() => ({}))

const FieldSeparatorText = styled(HbText)(({ theme }) => ({
  padding: theme.spacing(0.25, 1),
}))

const SubtitleText = (props: HbTextProps) => <HbText color="secondary" size="s" {...props} />

const InfoFieldSubtitle = styled(SubtitleText)(() => ({
  flexShrink: 0,
}))

const InfoFieldsetTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeight.bold,
}))

const InfoFieldsetSubtitle = styled(Typography)(() => ({}))

const revisionHighlightChildSelectors = `& ${StyledInfoFieldText}, & ${FieldSeparatorText}, & ${InfoFieldSubtitle}, & ${InfoFieldsetTitle}, & ${InfoFieldsetSubtitle}`

export const revisionUpdateHighlightColors = {
  background: '#E9F5FE',
  color: '#088AF5',
}

interface DiffStylesProps extends RevisionProps {
  theme: Theme
}

export const getDiffStyles = ({
  theme,
  hasDeletedDataInRevision,
  hasUpdatedDataInRevision,
}: DiffStylesProps): CSSObject =>
  hasDeletedDataInRevision
    ? {
        padding: theme.spacing(0.75, 1.5),
        position: 'relative',
        borderRadius: theme.shape.largeContainer.borderRadius,
        background: theme.palette.styleguide.backgroundMedium,
        left: theme.spacing(-0.5),
        [`&&, ${revisionHighlightChildSelectors}`]: {
          textDecoration: 'line-through',
          color: theme.palette.styleguide.textGreyLight,
        },
      }
    : hasUpdatedDataInRevision
    ? {
        padding: theme.spacing(0.75, 1.5),
        position: 'relative',
        borderRadius: theme.shape.largeContainer.borderRadius,
        background: revisionUpdateHighlightColors.background,
        left: theme.spacing(-0.5),
        [`&&, ${revisionHighlightChildSelectors}`]: {
          fontWeight: theme.fontWeight.bold,
          color: revisionUpdateHighlightColors.color,
        },
      }
    : {}

const InfoFieldRoot = styled('div')<RevisionProps>(({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  wordBreak: 'break-word',
  ...getDiffStyles({ theme, hasDeletedDataInRevision, hasUpdatedDataInRevision }),
}))

export const InfoFieldSeparator = (props: HbTextProps) => (
  <FieldSeparatorText bold color="secondary" size="lg" tag="span" {...props}>
    ·
  </FieldSeparatorText>
)

export const StyledSecondaryNameText = styled(HbText)<RevisionProps>(
  ({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }) =>
    getDiffStyles({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme })
)

export const InfoFieldPlaceholder = styled(Typography)(() => ({
  opacity: 0.4,
}))

export interface InfoFieldProps<T> {
  className?: string
  data: T
  hasUpdatedDataInRevision?: boolean
  hasDeletedDataInRevision?: boolean
  previousData?: Partial<T>
}

interface InfoFieldBaseProps {
  className?: string
  hasUpdatedDataInRevision?: boolean
  hasDeletedDataInRevision?: boolean
  title: ReactNode
  subtitle?: string | ReactNode
}

export function InfoFieldBase(props: InfoFieldBaseProps) {
  const { className, hasDeletedDataInRevision, hasUpdatedDataInRevision, title, subtitle } = props

  const { ref } = useScrollToChangedField<HTMLDivElement>({ hasDeletedDataInRevision, hasUpdatedDataInRevision })

  return (
    // Align with center of icons by applying paddingTop.
    <InfoFieldRoot
      ref={ref}
      className={className}
      style={{ paddingTop: 5 }}
      hasUpdatedDataInRevision={hasUpdatedDataInRevision}
      hasDeletedDataInRevision={hasDeletedDataInRevision}
    >
      {title}
      {subtitle && (
        <>
          <InfoFieldSeparator />
          <InfoFieldSubtitle>{subtitle}</InfoFieldSubtitle>
        </>
      )}
    </InfoFieldRoot>
  )
}

interface InfoFieldOptions<T> {
  link?: {
    enabled?: boolean | 'auto'
    to?: (data: T) => string
  } & Pick<ComponentProps<typeof ExternalLink>, 'hideIcon' | 'variant'>
}

const linkValueStyles = {
  root: css({
    textDecoration: 'none',
  }),
  icon: css({
    height: 16,
    width: 16,
    position: 'relative',
    top: 3,
  }),
}

interface MaybeLinkValueProps {
  className?: string
  hideLinkIcon?: boolean
  linkVariant?: ExternalLinkProps['variant']
  value: string | null | undefined
  to?: string
}

export const MaybeLinkValue = ({ className, hideLinkIcon, linkVariant, to, value }: MaybeLinkValueProps) => {
  return to ? (
    <StyledExternalLink classes={linkValueStyles} variant={linkVariant} to={to} hideIcon={hideLinkIcon}>
      {value}
    </StyledExternalLink>
  ) : (
    <StyledInfoFieldText className={className}>{value}</StyledInfoFieldText>
  )
}

export function createInfoField<T>(
  getValue: (data: T, formatDate: DateFormatter) => string | null | undefined,
  getSubtype?: (
    data: T,
    formatDate: DateFormatter,
    { hasUpdatedDataInRevision, hasDeletedDataInRevision }: RevisionProps
  ) => string | ReactNode,
  options?: InfoFieldOptions<T>
) {
  return function InfoField({
    className,
    data,
    hasDeletedDataInRevision,
    hasUpdatedDataInRevision,
  }: InfoFieldProps<T>) {
    const formatDate = useDateFormatter()
    const value = getValue(data, formatDate)
    const isLink = options?.link?.enabled === true || (options?.link?.enabled === 'auto' && valueIsHyperlink(value))

    const to = isLink ? (options?.link?.to ? options.link.to(data) : value ?? '') : undefined

    const title = (
      <MaybeLinkValue
        hideLinkIcon={!!options?.link?.hideIcon}
        linkVariant={options?.link?.variant}
        to={to}
        value={value}
      />
    )

    return (
      <InfoFieldBase
        className={className}
        hasDeletedDataInRevision={hasDeletedDataInRevision}
        hasUpdatedDataInRevision={hasUpdatedDataInRevision}
        title={title}
        subtitle={getSubtype && getSubtype(data, formatDate, { hasDeletedDataInRevision, hasUpdatedDataInRevision })}
      />
    )
  }
}

const InfoFieldsetRoot = styled('div')<RevisionProps>(
  ({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }) => ({
    ...getDiffStyles({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }),
    marginBottom: theme.spacing(2),
  })
)

const InfoFieldsetDetailsContainer = styled('div')<RevisionProps>(
  ({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    ...getDiffStyles({ hasDeletedDataInRevision, hasUpdatedDataInRevision, theme }),
  })
)

interface FieldSetDetail {
  title?: string | null
  externalLink?: boolean
}

export function createInfoFieldSet<T>(
  getTitle: (data: T) => string | ReactNode,
  getSubtitles: (data: T) => (string | null | undefined)[],
  getDetails: (data: T) => FieldSetDetail[]
) {
  return (props: InfoFieldProps<T>) => {
    const { data, hasDeletedDataInRevision, hasUpdatedDataInRevision } = props

    // Get details and remove any empty or null items
    const details = getDetails(data).filter((d) => d && !!d.title)

    const { ref } = useScrollToChangedField<HTMLDivElement>({ hasDeletedDataInRevision, hasUpdatedDataInRevision })

    return (
      <InfoFieldsetRoot
        ref={ref}
        hasDeletedDataInRevision={hasDeletedDataInRevision}
        hasUpdatedDataInRevision={hasUpdatedDataInRevision}
      >
        <InfoFieldsetTitle key="title" variant="body2">
          {getTitle(data)}
        </InfoFieldsetTitle>
        {getSubtitles(data).map((subtitle) => (
          <InfoFieldsetSubtitle key={subtitle} variant="body2">
            {subtitle}
          </InfoFieldsetSubtitle>
        ))}
        <InfoFieldsetDetailsContainer>
          {details.map((detail, idx) => (
            <React.Fragment key={detail.title}>
              {detail.externalLink && detail.title ? (
                <ExternalLink to={detail.title} size="sm" hideIcon>
                  {detail.title}
                </ExternalLink>
              ) : (
                <InfoFieldSubtitle>{detail.title}</InfoFieldSubtitle>
              )}
              {idx < details.length - 1 && <InfoFieldSeparator />}
            </React.Fragment>
          ))}
        </InfoFieldsetDetailsContainer>
      </InfoFieldsetRoot>
    )
  }
}

const FieldWithIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: 26,
  columnGap: theme.spacing(2),
}))

const FieldIcon = styled('span')<{ hasData: boolean; disabled?: boolean }>(({ hasData, disabled, theme }) => ({
  width: 20,
  height: 20,
  padding: 6,

  '& > path': {
    fill: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    opacity: hasData ? 1 : 0.4,
  },
}))

interface FieldWithIconProps {
  Icon?: React.ComponentType<{ className?: string }>
  hasData: boolean
  children: ReactNode
}

export const FieldWithIcon = forwardRef<HTMLDivElement, FieldWithIconProps>((props, ref) => {
  const { children, Icon, hasData } = props

  return (
    <FieldWithIconContainer ref={ref}>
      {Icon && <FieldIcon as={Icon} hasData={hasData} />}
      <Box flex={1} ml={Icon ? 2 : 0} pt={0.25}>
        {children}
      </Box>
    </FieldWithIconContainer>
  )
})

export function FieldPlaceholder({ placeholder }: { placeholder: string }) {
  return (
    // Align with center of icons by applying paddingTop.
    <InfoFieldRoot style={{ paddingTop: 5 }}>
      <InfoFieldPlaceholder variant="body2" color="textPrimary">
        {placeholder}
      </InfoFieldPlaceholder>
    </InfoFieldRoot>
  )
}

export function Editable<V extends Record<string, unknown>, T, FinalValues extends Record<string, unknown>>(
  props: EditableProps<V, T, FinalValues>
) {
  const {
    extractedDiff,
    extractedValues,
    extractedData,
    extractedPreviousData,
    transformValues = (v) => v,
    name,
    placeholder,
    Icon,
    Read,
    Edit,
    saveAsRecordAttributes = false,
    additionalTopLevelData,
    children,
    childrenFirst,
  } = props
  const editMode = useRef<EditModeRef | null>(null)
  const formContext = useContext(FormConfigurationContext)
  const {
    data: contextData,
    diff: contextDiff,
    previousData: contextPreviousData,
    values,
    save,
  } = useContext(EntityInformationContext)

  const { pendingChanges, clearPendingChanges } = usePendingChanges<V>()
  const onSave = async (newValues: V, actions: FormikHelpers<V>) => {
    const finalValues = transformValues(newValues)
    actions.setSubmitting(true)

    if (save) {
      const relationships = undefined
      const success = saveAsRecordAttributes
        ? await save({}, finalValues)
        : await save(finalValues, relationships, additionalTopLevelData)
      if (success) {
        editMode.current?.setMode('read')
        clearPendingChanges()
      }
    }

    actions.setSubmitting(false)
  }

  const initialValues = {
    ...(extractedValues ?? extractSubvalues(name, values, saveAsRecordAttributes)),
    ...(pendingChanges || {}),
  }

  const data = extractedData ?? contextData
  const previousData = extractedPreviousData ?? contextPreviousData

  const diff = extractedDiff ?? contextDiff
  const fieldDiff = diff?.[name]

  const hasUpdatedDataInRevision = getHasUpdatedDataInRevision(fieldDiff)
  const hasDeletedDataInRevision = !!diff && name in diff && getHasDeletedDataInRevision(fieldDiff)

  const noCurrentData = isValueEmpty(initialValues[name]) && isValueEmpty(data[name])
  const noPreviousData = isValueEmpty(previousData?.[name])

  const noData = noCurrentData && noPreviousData

  const { ref: readRef } = useScrollToChangedField<HTMLDivElement>({
    hasDeletedDataInRevision,
    hasUpdatedDataInRevision,
  })

  if (formContext.readOnly && noData) {
    return null
  }

  const getName = (key: string) => (key ? `${name}.${key}` : name)

  const readData = hasDeletedDataInRevision && !!previousData ? previousData[name] : data[name]

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSave}>
      {({ submitForm, isSubmitting, values: currentValues, dirty }) => (
        <>
          <EditMode ref={editMode} saveDisabled={isSubmitting} save={submitForm} name={name}>
            {(params) => (
              <FieldWithIcon
                Icon={Icon}
                hasData={!noData || params.mode === 'edit'}
                ref={params.mode === 'read' ? readRef : undefined}
              >
                {childrenFirst && children?.(params)}

                {params.mode === 'read' ? (
                  noData ? (
                    <FieldPlaceholder placeholder={placeholder} />
                  ) : (
                    <Read
                      data={readData}
                      hasDeletedDataInRevision={hasDeletedDataInRevision}
                      hasUpdatedDataInRevision={hasUpdatedDataInRevision}
                    />
                  )
                ) : (
                  <Edit name={getName} entityType={data.type as LibraryTypeEnum} />
                )}

                {!childrenFirst && children?.(params)}
              </FieldWithIcon>
            )}
          </EditMode>
          <PreserveFormState currentValues={currentValues} initialValues={initialValues} dirty={dirty} />
        </>
      )}
    </Formik>
  )
}

export function EditableRelationship<V extends Record<string, unknown>, T, FinalValues extends Record<string, unknown>>(
  props: EditableProps<V, T, FinalValues>
) {
  return <Editable {...props} saveAsRecordAttributes />
}

export const StyledRemoveButton = styled(IconButton)(() => ({
  width: 32,
  height: 32,
}))

interface RemoveButtonProps {
  index: number
  removeItem: (index: number) => void
  disabled?: boolean
}

export const RemoveButton = ({ index, removeItem, disabled }: RemoveButtonProps) => (
  <StyledRemoveButton size="small" aria-label="Remove" onClick={() => removeItem(index)} disabled={disabled}>
    <FieldIcon as={CircleRemoveIcon} hasData disabled={disabled} />
  </StyledRemoveButton>
)

export const getValueTokenDiffKey = (value: { token: string }) => value.token

interface InfoFieldArrayProps<T> {
  getDiffKey?: GetArrayFieldDiffKey<T>
  params: EditableArrayParams
  placeholder?: string
  Read: React.ComponentType<InfoFieldProps<T>>
  Edit: React.ComponentType<EditProps>
  Icon?: React.ComponentType<{ className?: string }>
  children?: ReactNode
  childrenFirst?: boolean
  isDeleteDisabled?: (item: T) => boolean
}

const EMPTY_DATA: unknown[] = []
export function InfoFieldArray<T>(props: InfoFieldArrayProps<T>) {
  const { children, childrenFirst, getDiffKey, isDeleteDisabled, placeholder, params, Read, Edit, Icon } = props

  const { name, mode, remove, values, data, diff, previousData } = params
  const readData = ((data as any)[name] ?? EMPTY_DATA) as T[]
  const previousReadData = (previousData?.[name] ?? EMPTY_DATA) as T[]
  const writeData = ((values as any)[name] ?? EMPTY_DATA) as T[]

  const removeItem = (index: number) => {
    remove(index)
  }

  const fieldDiff = diff?.[name as keyof typeof diff]
  const arrayHasUpdatedDataInRevision = getArrayHasUpdatedDataInRevision(fieldDiff)
  const arrayHasDeletedDataInRevision = !!diff && name in diff && getArrayHasDeletedDataInRevision(fieldDiff)
  const { ref: readRef } = useScrollToChangedField<HTMLDivElement>({
    hasDeletedDataInRevision: arrayHasUpdatedDataInRevision,
    hasUpdatedDataInRevision: arrayHasDeletedDataInRevision,
  })

  const readDataWithMaybeDeletionsIncluded = useMemo(() => {
    if (!previousReadData || !getDiffKey) return readData
    return getReadDataWithDeletionsIncluded({ getDiffKey, previousReadData, readData })
  }, [getDiffKey, previousReadData, readData])
  const deletedItemsCount = readDataWithMaybeDeletionsIncluded.length - readData.length
  if (mode === 'edit') {
    return (
      <>
        {childrenFirst && children}
        {writeData.map((item, idx) => {
          if (item && typeof item === 'object' && 'archived' in item && item.archived) {
            return null
          }

          const baseName = `${name}[${idx}]`
          const getName = (key: string) => (key ? `${baseName}.${key}` : baseName)
          return (
            <FieldWithIconContainer as="fieldset" key={idx}>
              <RemoveButton index={idx} removeItem={removeItem} disabled={isDeleteDisabled?.(item)} />
              <Box flex={1} pt={0.25}>
                <Edit name={getName} entityType={data.type as LibraryTypeEnum} />
              </Box>
            </FieldWithIconContainer>
          )
        })}
        {!childrenFirst && children}
      </>
    )
  }

  const displayData = readDataWithMaybeDeletionsIncluded
  const hasData = displayData.length !== 0

  return (
    <FieldWithIcon Icon={Icon} hasData={hasData || !!children} ref={readRef}>
      {childrenFirst && children}
      {!hasData && placeholder && <FieldPlaceholder placeholder={placeholder} />}
      {displayData.map((item, idx) => {
        const isUpdatedItem = getHasUpdatedDataInRevision(fieldDiff?.[idx])
        const isDeletedItem = !isUpdatedItem && deletedItemsCount > 0 && idx >= displayData.length - deletedItemsCount
        return (
          <Read
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            data={item}
            hasDeletedDataInRevision={isDeletedItem}
            hasUpdatedDataInRevision={isUpdatedItem}
          />
        )
      })}
      {!childrenFirst && children}
    </FieldWithIcon>
  )
}

const MetadataFieldWithIcon = styled('fieldset')(() => ({
  display: 'flex',
  minHeight: 26,
}))

interface MetadataEditProps extends EditProps {
  active?: boolean
}

type MetadataFieldEditComponentType = React.ComponentType<MetadataEditProps>
type RemoveItem<T = unknown> = (idx: number) => T | undefined

interface MetadataFieldProps<T extends BaseMetadata> {
  active?: boolean
  canRemove?: boolean
  Edit: MetadataFieldEditComponentType
  getFieldName: (key?: string) => string
  idx: number
  removeItem: RemoveItem<T>
}

const MetadataField = <T extends BaseMetadata>({
  active,
  canRemove,
  Edit,
  getFieldName,
  idx,
  removeItem,
}: MetadataFieldProps<T>) => {
  return (
    <MetadataFieldWithIcon key={idx}>
      {canRemove && <RemoveButton index={idx} removeItem={removeItem} />}
      <Box flex={1} ml={canRemove ? 2 : 0} pt={0.25}>
        <Edit active={active} name={getFieldName} />
      </Box>
    </MetadataFieldWithIcon>
  )
}

const MetadataInfoFieldArrayEditSecondaryDataContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0, 0),
}))

const MetadataInfoFieldArrayEditSecondaryDataHeading = styled(HbText)(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 6),
  color: theme.palette.styleguide.textGreyLight,
  textTransform: 'uppercase',
}))

type IconPropType = React.ComponentType<{ className?: string }>

interface MetadataInfoFieldArrayEditProps<T extends BaseMetadata> {
  allowRemovingActive?: boolean
  data: T[]
  Icon?: IconPropType
  Edit: MetadataFieldEditComponentType
  name: string
  removeItem: RemoveItem<T>
}

const MetadataInfoFieldArrayEdit = <T extends BaseMetadata>({
  allowRemovingActive,
  data,
  Edit,
  Icon,
  name,
  removeItem,
}: MetadataInfoFieldArrayEditProps<T>) => {
  const hasData = !!data.length
  const primaryData = data.filter(({ active }) => !!active)
  const hasPrimaryData = !!primaryData.length
  const secondaryData = data.filter(({ active }) => !active)
  const hasSecondaryData = !!secondaryData.length
  const _secondaryIdxOffset = primaryData.length
  const primaryFieldsEl = (
    <>
      {primaryData.map((_, idx) => {
        const baseName = `${name}[${idx}]`
        const getFieldName = (key?: string) => (key ? `${baseName}.${key}` : baseName)
        return (
          <MetadataField
            active
            canRemove={allowRemovingActive}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            Edit={Edit}
            getFieldName={getFieldName}
            idx={idx}
            removeItem={removeItem}
          />
        )
      })}
    </>
  )
  return (
    <>
      {hasPrimaryData && (
        <>
          {allowRemovingActive ? (
            <div>{primaryFieldsEl}</div>
          ) : (
            <FieldWithIcon Icon={Icon} hasData={hasData}>
              {primaryFieldsEl}
            </FieldWithIcon>
          )}
        </>
      )}
      {hasSecondaryData && (
        <>
          {hasPrimaryData && (
            <MetadataInfoFieldArrayEditSecondaryDataHeading size="s" color="secondary" tag="h5">
              Secondary values
            </MetadataInfoFieldArrayEditSecondaryDataHeading>
          )}
          <MetadataInfoFieldArrayEditSecondaryDataContainer>
            {secondaryData.map((_, _idx) => {
              const offsetIdx = _secondaryIdxOffset + _idx
              const baseName = `${name}[${offsetIdx}]`
              const getFieldName = (key?: string) => (key ? `${baseName}.${key}` : baseName)

              return (
                // eslint-disable-next-line react/no-array-index-key
                <MetadataField
                  canRemove
                  key={offsetIdx}
                  Edit={Edit}
                  getFieldName={getFieldName}
                  idx={offsetIdx}
                  removeItem={removeItem}
                />
              )
            })}
          </MetadataInfoFieldArrayEditSecondaryDataContainer>
        </>
      )}
    </>
  )
}

export const createGetMetadataDiffKey =
  <T extends BaseMetadata>(parseValue: GetArrayFieldDiffKey<T['value']>) =>
  (d: T) =>
    `${d.active ? 'primary' : 'secondary'}_${parseValue(d.value)}`

export const getStringValueMetadataDiffKey = createGetMetadataDiffKey<BaseMetadataWithValue<string>>(identity)

interface MetadataInfoFieldArrayProps<T extends BaseMetadata> {
  allowRemovingActive?: boolean
  getDiffKey?: GetArrayFieldDiffKey<T>
  params: EditableArrayParams
  placeholder?: string
  Read: React.ComponentType<InfoFieldProps<T>>
  Edit: MetadataFieldEditComponentType
  Icon?: IconPropType
}

export const MetadataInfoFieldArray = <T extends BaseMetadata>(props: MetadataInfoFieldArrayProps<T>) => {
  const { allowRemovingActive, getDiffKey, placeholder, params, Read, Edit, Icon } = props

  const { name, mode, remove, values, data, diff, previousData } = params
  const readData = ((data as any)[name] ?? EMPTY_DATA) as T[]
  const fieldDiff = diff?.[name as keyof typeof diff]
  const previousReadData = (previousData?.[name] ?? EMPTY_DATA) as T[]
  const writeData = ((values as any)[name] ?? EMPTY_DATA) as T[]

  const arrayHasUpdatedDataInRevision = getArrayHasUpdatedMetadataInRevision(fieldDiff)
  const arrayHasDeletedDataInRevision = !!diff && name in diff && getArrayHasDeletedDataInRevision(fieldDiff)
  const { ref: readRef } = useScrollToChangedField<HTMLDivElement>({
    hasUpdatedDataInRevision: arrayHasUpdatedDataInRevision,
    hasDeletedDataInRevision: arrayHasDeletedDataInRevision,
  })

  const readDataWithMaybeDeletionsIncluded = useMemo(() => {
    if (!previousReadData || !getDiffKey) return readData
    return getReadDataWithDeletionsIncluded({ getDiffKey, previousReadData, readData })
  }, [getDiffKey, previousReadData, readData])
  const deletedItemsCount = readDataWithMaybeDeletionsIncluded.length - readData.length

  if (mode === 'edit') {
    return (
      <MetadataInfoFieldArrayEdit
        allowRemovingActive={allowRemovingActive}
        data={writeData}
        Edit={Edit}
        Icon={Icon}
        name={name}
        removeItem={remove}
      />
    )
  }

  const displayData = readDataWithMaybeDeletionsIncluded
  const hasData = displayData.length !== 0

  return (
    <FieldWithIcon Icon={Icon} hasData={hasData} ref={readRef}>
      {!hasData && placeholder && <FieldPlaceholder placeholder={placeholder} />}
      {displayData.map((item, idx) => {
        const isUpdatedItem = getHasUpdatedMetadataInRevision(fieldDiff?.[idx])
        const isDeletedItem = deletedItemsCount > 0 && idx >= displayData.length - deletedItemsCount
        return (
          <Read
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            data={item}
            hasDeletedDataInRevision={isDeletedItem}
            hasUpdatedDataInRevision={isUpdatedItem}
          />
        )
      })}
    </FieldWithIcon>
  )
}
