import { ListItemNode, ListNode } from '@lexical/list'

import { $isElementNode, LexicalEditor, TextNode } from 'lexical'

import { CommentMarkNode } from './CommentMarkNode'
import { LiquidNode } from './LiquidPlugin'
import MentionNode from './MentionNode'

export const editorNodes = [MentionNode]
export const richEditorNodes = [MentionNode, ListNode, ListItemNode, CommentMarkNode]
export const richEditorWithLiquidNodes = [...richEditorNodes, LiquidNode]

const { exportDOM } = TextNode.prototype

// Lexical currently doesn't support exporting underlines in HTML. The
// following code is a fix from
// https://github.com/facebook/lexical/issues/2452#issuecomment-1189672038

// Temporary definitions as these aren't exported from Lexical:
// https://github.com/facebook/lexical/issues/2452#issuecomment-1208441053
/* eslint-disable no-bitwise */
const IS_BOLD = 1
const IS_ITALIC = 1 << 1
const IS_UNDERLINE = 1 << 3

TextNode.prototype.exportDOM = function HbTextNodeExportDom(editor: LexicalEditor) {
  const tags = []

  if (this.__format & IS_UNDERLINE) {
    tags.push('u')
  }

  if (this.__format & IS_ITALIC) {
    tags.push('i')
  }

  if (this.__format & IS_BOLD) {
    tags.push('b')
  }

  // If we have multiple standard formatting on this Lexical TextNode, export
  // the complete dom structure
  if (tags.length > 0) {
    const text = document.createTextNode(this.__text)

    const inner = tags.reduce((prev: HTMLElement, tag: string) => {
      const el = document.createElement(tag)
      el.appendChild(prev)
      return el
    }, text as unknown as HTMLElement)

    const element = document.createElement('span')
    element.appendChild(inner)

    return { element }
  }

  // Otherwise just follow the standard behavior from Lexical
  return exportDOM.apply(this, [editor])
}
/* eslint-enable no-bitwise */

// Override the default getTextContent to return lists with single line breaks instead of double line breaks and indentation
ListNode.prototype.getTextContent = function HbListNodeGetTextContent(...args) {
  let textContent = ''
  const children = this.getChildren()
  const childrenLength = children.length

  const numbered = this.getListType() === 'number'

  for (let i = 0; i < childrenLength; i += 1) {
    const child = children[i]
    const isParentListItem = child.getChildren()[0]?.getType() === 'list'
    if (!isParentListItem) {
      textContent += numbered ? `${child.getValue()}. ` : '- '
    }
    textContent += child.getTextContent(...args)

    if ($isElementNode(child) && i !== childrenLength - 1 && !child.isInline()) {
      textContent += '\n'
    }
  }

  if (this.getParent().getType() === 'listitem') {
    return textContent
      .split('\n')
      .map((s) => `  ${s}`)
      .join('\n')
  }

  return textContent
}
