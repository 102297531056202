import { useMemo } from 'react'

import { gql } from '@apollo/client'

import { zodResolver } from '@hookform/resolvers/zod'

import { isString } from 'lodash'

import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import { deepTransformValues } from 'helpers/objHelpers'
import { useFeatureFlag } from 'hooks'
import { useHbMutation } from 'hooks/ApolloHelpers'
import { AutomationRuleType, FeatureFlag } from 'types/api'

import { UPDATE_AUTOMATION_RULE_TEMPLATE } from '../AutomationRuleTemplate/AutomationRuleTemplate.queries'
import {
  UpdateAutomationRuleTemplateMutation,
  UpdateAutomationRuleTemplateMutationVariables,
} from '../AutomationRuleTemplate/__generated__/AutomationRuleTemplate.queries.generated'
import { useAutomationAdminOperations } from '../hooks/AutomationAdminHelpers'

import {
  scheduleStringToScheduleConfig,
  scheduleConfigToCronString,
  generateScheduleDescription,
  parseScheduleConfigIntoScheduleRule,
} from './AutomationEditorSteps/utils'
import { checkIsRule } from './AutomationRuleOrTemplateContext'
import {
  UpdateAutomationRuleMutation,
  UpdateAutomationRuleMutationVariables,
  UpdateAutomationRuleAsAdminMutationVariables,
  UpdateAutomationRuleAsAdminMutation,
} from './__generated__/useAutomationRuleOrTemplateForm.generated'
import {
  RuleFields,
  RuleOrTemplateScheduledWithTriggerFields,
  RuleOrTemplateTriggeredFields,
  TemplateFields,
} from './editor.types'
import {
  FormSchema,
  FormSchemaForRule,
  FormSchemaForTemplate,
  fixingFormSchemaForRule,
  fixingFormSchemaForTemplate,
  formSchemaForRule,
  formSchemaForTemplate,
} from './formSchema'
import { transformTriggerFilters } from './util'

const CANONICAL_AUTOMATION_RULE_BASE_FIELDS = gql`
  fragment CanonicalAutomationRuleBaseFields on CanonicalAutomationRuleBase {
    token
    organizationToken
    name
    displayName
    actionText
    enabled
    actionType
    sampleRate
    disabled
    failureNotificationSetting
    actionParams {
      googleSheetsParams {
        sheetId
      }
      commentParams {
        text
        smartValuesEnabled
      }
      notificationParams {
        badgeTokens
        accountTokens
        recipientEmails {
          name
          email
        }
        sendToReviewAssignee
        smartValuesEnabled
        subject
        body
      }
      reviewDigestNotificationParams {
        badgeTokens
        accountTokens
        recipientEmails {
          name
          email
        }
      }
      tagParams {
        tagDefinitionToken
      }
      createInvestigationParams {
        reviewTypeCanonicalId
        description
        name
        activityTags
        narrative
        smartValuesEnabled
      }
      createReviewParams {
        reviewTypeCanonicalId
        dueDateRelativeIncrementUnit
        dueDateRelativeIncrementValue
        assigneeToken
        queueToken
        narrative
        activityTags
        smartValuesEnabled
        transferReviewAssignee
        otherInfoEntries: actionOtherInfoLabels {
          label
          value
        }
      }
      cancelReviewParams {
        cancellationReason
      }
      snoozeReviewParams {
        snoozeUntilDateRelativeIncrementValue
        snoozeUntilDateRelativeIncrementUnit
        description
        snoozeTimingPolicy
      }
      assignReviewToQueueParams {
        queueToken
        unassignAccount
      }
      updateReviewDueDateParams {
        updateToDateRelativeIncrementValue
        updateToDateRelativeIncrementUnit
        relativeToDueDate
        relativeToCreatedDate
      }
      workflowActionReviewParams {
        choiceKey
        actionSlug
        canonicalReviewTypeId
      }
      writeOtherInfoParams {
        otherInfoEntries: actionOtherInfoLabels {
          label
          value
        }
        overwrite
      }
    }
  }
`
const TRIGGER_AUTOMATION_RULE_FIELDS = gql`
  fragment TriggerAutomationRuleFields on TriggerAutomationRule {
    primaryRuleTrigger {
      description
      domainType
      triggerFilters
      datasource @include(if: $datasourcesEnabled) {
        token
      }
    }
  }
`

const SCHEDULE_WITH_TRIGGER_AUTOMATION_RULE_FIELDS = gql`
  fragment ScheduleWithTriggerAutomationRuleFields on ScheduleWithTriggerAutomationRule {
    primaryRuleTrigger {
      description
      domainType
      triggerFilters
      datasource @include(if: $datasourcesEnabled) {
        token
      }
    }
    scheduleStartOn
    scheduleConfig
  }
`

const SCHEDULE_AUTOMATION_RULE_FIELDS = gql`
  fragment ScheduleAutomationRuleFields on ScheduleAutomationRule {
    scheduleStartOn
    scheduleConfig
  }
`

export const fragment = gql`
  fragment UseAutomationRuleFormAutomationRule on CanonicalAutomationRule {
    __typename
    ...CanonicalAutomationRuleBaseFields
    ...TriggerAutomationRuleFields
    ...ScheduleAutomationRuleFields
    ...ScheduleWithTriggerAutomationRuleFields
  }
  ${CANONICAL_AUTOMATION_RULE_BASE_FIELDS}
  ${TRIGGER_AUTOMATION_RULE_FIELDS}
  ${SCHEDULE_AUTOMATION_RULE_FIELDS}
  ${SCHEDULE_WITH_TRIGGER_AUTOMATION_RULE_FIELDS}
`

const UPDATE_AUTOMATION_RULE = gql`
  mutation UpdateAutomationRule($input: UpdateAutomationRuleInput!, $datasourcesEnabled: Boolean!) {
    updateAutomationRule(input: $input) {
      automationRule {
        ...UseAutomationRuleFormAutomationRule
      }
    }
  }
  ${fragment}
`

const UPDATE_AUTOMATION_RULE_AS_ADMIN = gql`
  mutation UpdateAutomationRuleAsAdmin($input: UpdateAutomationRuleAsAdminInput!, $datasourcesEnabled: Boolean!) {
    updateAutomationRuleAsAdmin(input: $input) {
      automationRule {
        ...UseAutomationRuleFormAutomationRule
      }
    }
  }
  ${fragment}
`

const now = () => new Date().toISOString()

function getScheduleWithTriggerAutomationRuleFormValues(ruleOrTemplate: RuleOrTemplateScheduledWithTriggerFields) {
  if (ruleOrTemplate.__typename === 'ScheduleWithTriggerAutomationRule') {
    const { primaryRuleTrigger, ...rest } = ruleOrTemplate
    const triggerFilters =
      primaryRuleTrigger?.triggerFilters && transformTriggerFilters(primaryRuleTrigger.triggerFilters)

    return {
      ...rest,
      automationType: AutomationRuleType.ScheduleWithTrigger,
      eventText: primaryRuleTrigger?.description || '',
      domain: {
        type: primaryRuleTrigger?.domainType || null,
        datasourceToken: primaryRuleTrigger?.datasource?.token || null,
      },
      scheduleConfig: scheduleStringToScheduleConfig(ruleOrTemplate.scheduleConfig),
      triggerFilters,
    }
  }
  const { automationTriggerTemplate, ...rest } = ruleOrTemplate
  const triggerFilters =
    automationTriggerTemplate?.snapshotFilters && transformTriggerFilters(automationTriggerTemplate.snapshotFilters)

  return {
    ...rest,
    automationType: AutomationRuleType.ScheduleWithTrigger,
    eventText: automationTriggerTemplate?.description || '',
    domain: {
      type: automationTriggerTemplate?.domainType || null,
      datasourceToken: null,
    },
    scheduleConfig: scheduleStringToScheduleConfig(ruleOrTemplate.scheduleConfig),
    scheduleStartOn: now(),
    triggerFilters,
  }
}

function getTriggerAutomationRuleFormValues(ruleOrTemplate: RuleOrTemplateTriggeredFields) {
  if (ruleOrTemplate.__typename === 'TriggerAutomationRule') {
    const { primaryRuleTrigger, ...rest } = ruleOrTemplate

    const triggerFilters =
      primaryRuleTrigger?.triggerFilters && transformTriggerFilters(primaryRuleTrigger.triggerFilters)

    return {
      ...rest,
      automationType: AutomationRuleType.Trigger,
      eventText: primaryRuleTrigger?.description || '',
      domain: {
        type: primaryRuleTrigger?.domainType || null,
        datasourceToken: primaryRuleTrigger?.datasource?.token || null,
      },
      triggerFilters,
    }
  }
  const { automationTriggerTemplate, ...rest } = ruleOrTemplate

  const triggerFilters =
    automationTriggerTemplate?.snapshotFilters && transformTriggerFilters(automationTriggerTemplate.snapshotFilters)

  return {
    ...rest,
    automationType: AutomationRuleType.Trigger,
    eventText: automationTriggerTemplate?.description || '',
    domain: {
      type: automationTriggerTemplate?.domainType || null,
      datasourceToken: null,
    },
    triggerFilters: triggerFilters || null,
  }
}

function getFormValuesForTemplate(template: TemplateFields): FormSchemaForTemplate {
  const baseTemplate = {
    ...template,
    categoryToken: template.automationTemplateCategory?.token || null,
  }

  let result
  switch (baseTemplate.__typename) {
    case 'TriggeredRuleTemplate':
      return fixingFormSchemaForTemplate.parse(getTriggerAutomationRuleFormValues(baseTemplate))
    case 'ScheduledRuleTemplate':
      return fixingFormSchemaForTemplate.parse({
        ...baseTemplate,
        automationType: AutomationRuleType.Schedule,
        scheduleStartOn: now(),
        scheduleConfig: scheduleStringToScheduleConfig(baseTemplate.scheduleConfig),
      })
    case 'ScheduledWithTriggerRuleTemplate':
      return fixingFormSchemaForTemplate.parse(getScheduleWithTriggerAutomationRuleFormValues(baseTemplate))
    case 'EmptyRuleTemplate':
      result = fixingFormSchemaForTemplate.parse({
        automationType: null,
        domain: null,
        ...baseTemplate,
      })
      return result
    default: {
      return fixingFormSchemaForTemplate.parse({ automationType: null, ...baseTemplate })
    }
  }
}

function getFormValuesForRule(rule: RuleFields): FormSchemaForRule {
  switch (rule.__typename) {
    case 'TriggerAutomationRule':
      return fixingFormSchemaForRule.parse(getTriggerAutomationRuleFormValues(rule))
    case 'ScheduleAutomationRule':
      return fixingFormSchemaForRule.parse({
        ...rule,
        automationType: AutomationRuleType.Schedule,
        scheduleStartOn: rule.scheduleStartOn,
        scheduleConfig: scheduleStringToScheduleConfig(rule.scheduleConfig),
      })
    case 'ScheduleWithTriggerAutomationRule':
      return fixingFormSchemaForRule.parse(getScheduleWithTriggerAutomationRuleFormValues(rule))
    default: {
      return fixingFormSchemaForRule.parse({ automationType: null, ...rule })
    }
  }
}

export function useAutomationTemplateForm(template: TemplateFields) {
  const formValues = useMemo(() => getFormValuesForTemplate(template), [template])

  const form = useForm<FormSchemaForTemplate>({
    resolver: zodResolver(formSchemaForTemplate()),
    values: formValues,
  })

  const [updateAutomationRuleTemplate] = useHbMutation<
    UpdateAutomationRuleTemplateMutation,
    UpdateAutomationRuleTemplateMutationVariables
  >(UPDATE_AUTOMATION_RULE_TEMPLATE, {
    flashError: true,
    onCompleted: () => {
      enqueueSnackbar('Automation template successfully updated', { variant: 'success', autoHideDuration: 2000 })
    },
    refetchQueries: ['AutomationRuleTemplate'],
  })

  const { reset, getValues } = form

  const handleSaveAutomationTemplate: (values: FormSchemaForTemplate) => Promise<boolean> = async (values) => {
    const sanitizedActionParams = deepTransformValues(values.actionParams, (value) =>
      isString(value) && value.length === 0 ? null : value
    ) as FormSchemaForTemplate['actionParams']

    const scheduleConfigObject = getValues('scheduleConfig')
    const domain = getValues('domain')

    const input: UpdateAutomationRuleTemplateMutationVariables['input'] = {
      actionParams: sanitizedActionParams as any, // TODO
      actionText: values.actionText,
      actionType: values.actionType,
      automationType: values.automationType,
      domainType: domain ? domain.type : null,
      name: values.name || null,
      sampleRate: values.sampleRate,
      scheduleConfig: scheduleConfigToCronString(scheduleConfigObject),
      token: template.token,
      categoryToken: values.categoryToken,
      humanReadableSchedule: scheduleConfigObject
        ? generateScheduleDescription(parseScheduleConfigIntoScheduleRule(scheduleConfigObject, new Date()))
        : '',
      description: values.description,
      setup: values.setup,
      triggerDescription: values.eventText,
      triggerName: values.eventText,
      snapshotFilters: getValues('triggerFilters') || null,
    }

    const result = await updateAutomationRuleTemplate({
      variables: { input },
    })

    if (result.data?.updateAutomationTemplate?.automationTemplate) {
      reset(getFormValuesForTemplate(result.data?.updateAutomationTemplate?.automationTemplate))
    }

    return !result.errors
  }

  return { form, handleSaveAutomationTemplate, defaultValues: formValues }
}

export function useAutomationRuleForm(rule: RuleFields) {
  const formValues = useMemo(() => getFormValuesForRule(rule), [rule])

  const isAutomationAdminRule = useAutomationAdminOperations(formValues.organizationToken)
  const datasourcesEnabled = useFeatureFlag(FeatureFlag.EnableDatasources)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchemaForRule()),
    values: formValues,
  })

  const [updateAutomationRuleAsAdmin] = useHbMutation<
    UpdateAutomationRuleAsAdminMutation,
    UpdateAutomationRuleAsAdminMutationVariables
  >(UPDATE_AUTOMATION_RULE_AS_ADMIN, {
    flashError: true,
    onCompleted: () => {
      enqueueSnackbar('Automation successfully updated as admin user', { variant: 'success', autoHideDuration: 2000 })
    },
  })

  const [updateAutomationRule] = useHbMutation<UpdateAutomationRuleMutation, UpdateAutomationRuleMutationVariables>(
    UPDATE_AUTOMATION_RULE,
    {
      flashError: true,
      onCompleted: () => {
        enqueueSnackbar('Automation successfully updated', { variant: 'success', autoHideDuration: 2000 })
      },
    }
  )

  const { reset, getValues } = form

  const handleSaveAutomationRule: (values: FormSchemaForRule) => Promise<boolean> = async (values) => {
    if (!checkIsRule(rule)) {
      return true
    }

    const sanitizedActionParams = deepTransformValues(values.actionParams, (value) =>
      isString(value) && value.length === 0 ? null : value
    ) as FormSchemaForRule['actionParams']

    const scheduleConfigObject = getValues('scheduleConfig')
    const domain = getValues('domain')

    const input: UpdateAutomationRuleMutationVariables['input'] = {
      actionParams: sanitizedActionParams,
      actionText: values.actionText,
      actionType: values.actionType,
      automationType: values.automationType,
      domainType: domain ? domain.type : null,
      datasourceToken: domain ? domain.datasourceToken : null,
      name: values.name || null,
      sampleRate: values.sampleRate,
      scheduleConfig: scheduleConfigToCronString(scheduleConfigObject),
      token: rule.token,
      eventText: values.eventText,
      scheduleStartOn: getValues('scheduleStartOn'),
      triggerFilters: getValues('triggerFilters') || null,
    }

    if (isAutomationAdminRule) {
      const result = await updateAutomationRuleAsAdmin({
        variables: { input: { ...input, organizationToken: rule.organizationToken }, datasourcesEnabled },
      })

      if (result.data?.updateAutomationRuleAsAdmin?.automationRule) {
        reset(getFormValuesForRule(result.data.updateAutomationRuleAsAdmin.automationRule))
      }
      return !result.errors
    }

    const result = await updateAutomationRule({
      variables: { input, datasourcesEnabled },
    })

    if (result.data?.updateAutomationRule?.automationRule) {
      reset(getFormValuesForRule(result.data.updateAutomationRule.automationRule))
    }

    return !result.errors
  }

  return { form, handleSaveAutomationRule, defaultValues: formValues }
}
