import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { AnyAction, combineReducers, Reducer } from 'redux'

import { reducer as transactionsReducer } from 'actions/transactionsActions'
import { reducer as exportReducer } from 'dashboards/shared/react/export'

import MiddeskReducer from './MiddeskReducer'
import TRClearReducer from './TRClearReducer'
import applicationReducer from './applicationReducer'
import AttachmentSummaryReducer from './attachmentSummary.reducer'
import { batchActionsReducer } from './batchActions/batchActions.reducer'
import dashboardsReducer from './dashboards'
import editReviewTypeReducer from './editReviewType.reducer'
import errorsReducer from './errorsReducer'
import generateDocReducer from './generateDocReducer'
import importingReducer from './importing'
import investigatingReducer from './investigatingReducer'
import { investigationsReducer } from './investigationsReducer'
import metricsReducer from './metricsReducer'
import narrativeTemplatesReducer from './narrativeTemplatesReducer'
import notificationsReducer from './notificationsReducer'
import organizationReducer from './organizationReducer'
import reviewsReducer from './reviewsReducer'
import ScreeningReducer from './screening.reducer'
import settingsReducer from './settingsReducer'
import { subjectsFiltersReducer } from './subjectsFiltersReducer'
import { tabReducer } from './tabReducer'
import userSettingsReducer from './userSettingsReducer'
import viewReducer from './viewReducer'

const rootReducerMap = {
  application: applicationReducer,
  editReviewType: editReviewTypeReducer,
  errors: errorsReducer,
  generateDoc: generateDocReducer,
  investigating: investigatingReducer,
  investigations: investigationsReducer,
  importing: importingReducer,
  metrics: metricsReducer,
  narrativeTemplates: narrativeTemplatesReducer,
  notifications: notificationsReducer,
  organization: organizationReducer,
  reviews: reviewsReducer,
  batchActions: batchActionsReducer,
  dashboards: dashboardsReducer,
  export: exportReducer,
  settings: settingsReducer,
  subjectsFilters: subjectsFiltersReducer,
  transactions: transactionsReducer,
  userSettings: userSettingsReducer,
  view: viewReducer,
  tabs: tabReducer,
  TRClear: TRClearReducer,
  Middesk: MiddeskReducer,
  attachmentSummaries: AttachmentSummaryReducer,
  screening: ScreeningReducer,
}

const reducers = (history: History<unknown>, asyncReducers: Record<string, Reducer<unknown, AnyAction>> = {}) =>
  combineReducers({
    ...rootReducerMap,
    ...asyncReducers,
    router: connectRouter(history),
  })

export default reducers
