import { useMemo, useRef, useState } from 'react'

import { FormControlLabel, FormHelperText, Switch } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller, useFormContext } from 'react-hook-form'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import AccountMenuItem from 'components/collaboration/AccountMenuItem'

import LiquidMarkdownEditor, { type Api } from 'components/library/LiquidMarkdownEditor'
import TypeAheadEditor from 'components/library/TypeAheadEditor/TypeAheadEditor'
import { getOrganizationTeammates } from 'helpers/stateHelpers'
import { Theme } from 'types/hb'

import { useAutomationAdminOperations } from '../../hooks/AutomationAdminHelpers'
import { useIsAutomationRule } from '../AutomationRuleOrTemplateContext'
import { FormSchema } from '../formSchema'

import SmartValuesMenu from './components/SmartValuesMenu'
import { DOMAIN_TYPE_SMART_VALUES } from './smartValues'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  editor: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.styleguide.borderDark}`,
  },
  smartValuesToggleBar: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  editorHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
  },
  smartValuesMenuButton: {
    padding: theme.spacing(0, 1),
  },
}))

export default function CustomizeCommentAction() {
  const classes = useStyles()
  const teammates = useSelector(getOrganizationTeammates)
  const isRule = useIsAutomationRule()
  const teamSuggestions = useMemo(() => {
    return isRule
      ? teammates.map((a) => ({
          value: a.username,
          additionalSearch: [a.firstName, a.lastName],
          type: 'mention' as const,
          renderListItem: () => <AccountMenuItem account={a} />,
          info: a.fullName,
        }))
      : []
  }, [teammates, isRule])

  const { watch, getValues } = useFormContext<FormSchema>()
  const ruleOrgToken = getValues('organizationToken')
  const isAdminAutomationRule = useAutomationAdminOperations(ruleOrgToken) && !!ruleOrgToken
  const smartValuesEnabled = watch('actionParams.commentParams.smartValuesEnabled')

  const domainType = watch('domain.type')

  const variables = domainType ? [DOMAIN_TYPE_SMART_VALUES[domainType]] : []
  const menuVariables = domainType
    ? DOMAIN_TYPE_SMART_VALUES[domainType].properties
        .filter((p) => p.type === 'value')
        .map((p) => ({
          label: p.label,
          insert: `{{${DOMAIN_TYPE_SMART_VALUES[domainType].name}.${p.name}}}`,
        }))
    : []

  const smartValuesMenuButtonRef = useRef<HTMLButtonElement>(null)
  const [smartValuesMenuOpen, setSmartValuesMenuOpen] = useState(false)

  const liquidEditorRef = useRef<Api>(null)

  function onSelectSmartValue(value: string) {
    liquidEditorRef.current?.replaceSelection(value)
    setSmartValuesMenuOpen(false)
  }

  return (
    <Controller
      name="actionParams.commentParams.text"
      render={({ field: { value, onChange }, fieldState }) => (
        <div className={classes.root}>
          <div className={classes.editorHeader}>
            <HbText bold size="s" color="secondary">
              Comment
            </HbText>
            {smartValuesEnabled ? (
              <>
                <HbButton
                  ref={smartValuesMenuButtonRef}
                  label={
                    <HbText bold size="s">
                      Smart values
                    </HbText>
                  }
                  variant="textSecondary"
                  dropdownCaret
                  onClick={() => setSmartValuesMenuOpen((o) => !o)}
                  className={classes.smartValuesMenuButton}
                />
                <SmartValuesMenu
                  anchorEl={smartValuesMenuButtonRef.current}
                  open={smartValuesMenuOpen}
                  onClose={() => setSmartValuesMenuOpen(false)}
                  variables={menuVariables}
                  onSelect={(entry) => {
                    onSelectSmartValue(entry.insert)
                    setSmartValuesMenuOpen(false)
                  }}
                />
              </>
            ) : null}
          </div>
          <div className={classes.editor}>
            {smartValuesEnabled && !isAdminAutomationRule ? (
              <LiquidMarkdownEditor
                ref={liquidEditorRef}
                initialValue={value}
                variables={variables}
                mentions={teamSuggestions}
                domain={domainType}
                onChange={onChange}
              />
            ) : (
              <TypeAheadEditor
                editViewClassName={classes.root}
                autoFocus
                initialValue={value}
                suggestions={teamSuggestions}
                onChange={onChange}
                placeholder="Write a comment..."
              />
            )}
          </div>
          <FormHelperText error={fieldState.invalid}>
            {fieldState.error?.message ||
              (smartValuesEnabled ? (
                <>
                  Add smart values between {'{{ and }}'} or mention users using @.{' '}
                  <a
                    href="https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta/automations/using-smart-values-in-your-automations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more about Smart Values
                  </a>
                </>
              ) : isRule ? (
                'Use @ to tag users. Max length 500 characters'
              ) : (
                ''
              ))}
          </FormHelperText>
          <Controller
            name="actionParams.commentParams.smartValuesEnabled"
            render={({ field }) => (
              <FormControlLabel
                className={classes.smartValuesToggleBar}
                control={
                  <Switch
                    onChange={(_, val) => field.onChange(val)}
                    onBlur={() => field.onBlur()}
                    checked={field.value}
                  />
                }
                labelPlacement="start"
                label={<HbText bold>Smart values</HbText>}
              />
            )}
          />
        </div>
      )}
    />
  )
}
