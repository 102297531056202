/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useRef, MouseEvent, ReactEventHandler, useEffect } from 'react'

import classnames from 'classnames'

import { isFunction } from 'lodash'

import { MINIMUM_BATCH_COLUMN_WIDTH, TableRowOverrides, useStyles } from 'components/library/Table/TableRow.styles'

import { scrollToDOMElement, valueIsHyperlink } from 'helpers/uiHelpers'
import { DashboardDensity } from 'reducers/userSettingsReducer'

import ExternalLink from '../ExternalLink'

import { TableColumnElement } from './TableColumn'
import { TableColWidthsState, getIsSecondColTooLongToBeSticky } from './helpers'

const getField = (column: TableColumnElement) => column.props.field
export function getCellValue<Row extends { [key: string]: any }>(column: TableColumnElement, row: Row) {
  const { field, value } = column.props

  if (isFunction(value)) {
    // @ts-expect-error this existed before strictBindCallApply was turned on. "Fixing" requires a more thorough investigation.
    return value.call(row, row, row[field])
  }

  if (value) {
    return value
  }

  if (field) {
    return row[field]
  }

  return ''
}

interface Props<Row> {
  allColsWidth?: number
  availableWidth: number | undefined
  columnResizingEnabled?: boolean
  density?: DashboardDensity
  focused?: boolean
  selectable?: boolean
  isSelected: boolean
  batchColumnEnabled?: boolean
  onClick: (event: MouseEvent) => void
  onDoubleClick: (event: MouseEvent) => void
  row: Row
  columns: TableColumnElement<Row>[]
  rightStickyColumn?: TableColumnElement<Row>
  uniqueKey: string
  styleOverrides?: TableRowOverrides
  scrollToFocused?: boolean
  tableColWidths?: TableColWidthsState
}

function TableRow<Row extends { [key: string]: any }>(props: Props<Row>) {
  const {
    allColsWidth,
    availableWidth,
    columnResizingEnabled: columnResizingEnabledForTable = false,
    density,
    focused,
    selectable,
    onClick,
    onDoubleClick,
    row,
    columns,
    rightStickyColumn,
    uniqueKey,
    styleOverrides,
    isSelected,
    batchColumnEnabled = false,
    scrollToFocused = true,
    tableColWidths,
  } = props

  const batchColWidth = columnResizingEnabledForTable ? tableColWidths?.batch : MINIMUM_BATCH_COLUMN_WIDTH
  const trStyle = columnResizingEnabledForTable ? { width: allColsWidth } : undefined

  const classes = { ...useStyles(), ...styleOverrides }
  const isCompactDensity = density === 'compact'

  const getCellClass = (column: TableColumnElement<Row>, index: number) => {
    const { align, cellClassName, multiline, field } = column.props
    const fieldName = getField(column)

    const isSecondTooLongToBeSticky = getIsSecondColTooLongToBeSticky(
      batchColumnEnabled,
      columns.length,
      tableColWidths?.[fieldName] ?? 0,
      index,
      availableWidth,
      batchColWidth
    )

    return classnames('hb-table__cell', classes.bodyCell, cellClassName, `hb-table__cell--${align}`, {
      [classes.bodyCellBatchColumnEnabled]: batchColumnEnabled && !isSecondTooLongToBeSticky,
      'hb-table__cell--multiline': multiline,
      'hb-table__cell--selectable': selectable, // isFunction(onRowClick),
      'hb-table__cell--selected': isSelected,
      'hb-table__cell--batch': field === 'batch',
      [classes.bodyCellCompact]: isCompactDensity,
    })
  }
  const getCellOnClick = (column: TableColumnElement<Row>): ReactEventHandler | undefined => {
    const { handleClick } = column.props
    if (handleClick) {
      return (e: React.MouseEvent) => handleClick(e, column, row)
    }
    return undefined
  }

  const stickyCellClasses = classnames(classes.bodyCell, classes.stickyCell)

  const cellContentClasses = classnames(classes.cellContent, { [classes.cellContentCompact]: isCompactDensity })
  const rowClass = classnames(classes.root, {
    [classes.selectable]: selectable,
    [classes.keyboardFocused]: focused,
    [classes.selected]: isSelected,
  })

  const ref = useRef<HTMLTableRowElement | null>(null)
  useEffect(() => {
    if (focused && scrollToFocused) {
      scrollToDOMElement(ref.current)
    }
  }, [focused, scrollToFocused])

  const secondColumnOffsetStyles = !batchColumnEnabled ? undefined : { left: batchColWidth }

  return (
    <tr
      data-testid="table-row"
      className={classnames(rowClass)}
      ref={ref}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={trStyle}
    >
      {columns.map((column, index) => {
        const fieldName = getField(column)
        const colWidth = tableColWidths?.[fieldName]

        const isSecondTooLongToBeSticky = getIsSecondColTooLongToBeSticky(
          batchColumnEnabled,
          columns.length,
          colWidth ?? 0,
          index,
          availableWidth,
          batchColWidth
        )

        const value = getCellValue(column, row)
        const valueIsLink = valueIsHyperlink(value)

        const widthStyles = !columnResizingEnabledForTable ? undefined : { width: colWidth, maxWidth: colWidth }

        return (
          <td
            key={`${fieldName}-${uniqueKey}`}
            className={getCellClass(column, index)}
            style={
              index === 1 && !isSecondTooLongToBeSticky ? { ...secondColumnOffsetStyles, ...widthStyles } : widthStyles
            }
            onClick={getCellOnClick(column)}
          >
            {valueIsLink ? (
              <ExternalLink classes={{ root: cellContentClasses }} to={value} hideIcon style={widthStyles}>
                {value}
              </ExternalLink>
            ) : (
              <div className={cellContentClasses} style={widthStyles}>
                {value}
              </div>
            )}
          </td>
        )
      })}
      {rightStickyColumn && (
        <td className={stickyCellClasses}>
          <div className={cellContentClasses}>{getCellValue(rightStickyColumn, row)}</div>
        </td>
      )}
    </tr>
  )
}

export default TableRow
