import { gql } from '@apollo/client'

export const CUSTOM_FIELD_LABEL_SEARCH_QUERY = gql`
  query CustomFieldLabelSearch($labelType: OtherInfoLabelTypeEnum!) {
    customFieldLabelSearch(labelType: $labelType) {
      token
      label
      managed
      datatype
      options
    }
  }
`

export const CUSTOM_FIELD_LABEL_ROW_FRAGMENT = gql`
  fragment CustomFieldLabelRow on OtherInfoLabel {
    token
    label
    type
    datatype
    displayAs
    description
    dashboardVisible
    hidden
    managed
    options
    createdAt
    updatedAt
  }
`

export const CUSTOM_FIELD_ENTRY_FRAGMENT = gql`
  fragment CustomFieldEntryRow on OtherInfoEntry {
    token
    label
    value
    managed
    displayAs
    datatype
    options
  }
`
