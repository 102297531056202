import { useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'
import { DatePicker, DatePickerProps, DateValidationError } from '@mui/x-date-pickers'

import classnames from 'classnames'
import { useField } from 'formik'
import { isNil, omit } from 'lodash'
import moment, { Moment } from 'moment-timezone'

import { getInputTestId } from 'components/HbComponents/Form/Inputs/getInputTestId'
import { FormikDatePicker, FormikDatePickerProps } from 'components/material/Form'
import { formatDateTimeValidationError } from 'helpers/uiHelpers'

import { useHbFormikContext } from '../useHbFormikContext'

import { InputContainer, getHelperTextId } from './InputContainer'
import { InputResponse } from './InputResponse'
import { InputProps } from './InputTypes'
import { useIsErroneous } from './useIsErroneous'

const useStyles = makeStyles(() => ({
  redesignDatePickerRoot: {
    margin: 0,
  },
}))

/**
 * @description This is a Formik-specific date input
 */
export const DateInput = (props: InputProps & FormikDatePickerProps & { useContainer?: boolean }) => {
  const {
    readOnly,
    label,
    sublabel,
    name,
    disabled,
    autosave,
    isErroneousChecker,
    errors,
    testId,
    inputContainerClassName,
    className: classNameProp,
    useContainer = true,
    hideLabel = false,
    ...rest
  } = props
  const styles = useStyles()
  const [field, meta] = useField(name)
  const form = useHbFormikContext({ autosave })
  const { isErroneous, apiErrors, clientError } = useIsErroneous({ name, autosave, errors, isErroneousChecker })
  const inputTestId = getInputTestId(label, testId)

  if (readOnly) {
    return (
      <InputResponse label={label} className={classNameProp}>
        {isNil(field.value) ? '-' : moment(field.value).format('LL')}
      </InputResponse>
    )
  }

  const restProps = omit(rest, ['type', 'questionStyle', 'multivalued', 'required', 'number_props'])

  const className = classnames(classNameProp, styles.redesignDatePickerRoot)

  const datePicker = (
    <FormikDatePicker
      field={field}
      form={form}
      meta={meta}
      slotProps={{
        textField: {
          error: isErroneous,
          id: name,
          'aria-describedby': getHelperTextId(name),
          margin: 'normal',
          size: 'small',
          variant: 'outlined',
        },
      }}
      name={name}
      testId={inputTestId}
      dateOnly
      disabled={disabled}
      className={className}
      variant="outlined"
      {...restProps}
    />
  )

  if (!useContainer) return datePicker

  return (
    <InputContainer
      clientError={clientError}
      isErroneous={isErroneous}
      label={label}
      sublabel={sublabel}
      apiError={apiErrors}
      className={inputContainerClassName}
      htmlFor={name}
      testId={testId}
      hideLabel={hideLabel}
    >
      {datePicker}
    </InputContainer>
  )
}

export const BaseDateInput = (
  props: Omit<DatePickerProps<Moment>, 'onError'> & {
    onError?: (onErrorProps: { value: Moment | null; error: string | null; muiError: DateValidationError }) => void
  }
) => {
  const { label, onError, name = '', ...rest } = props
  const [readableError, setReadableError] = useState<string | null>(null)

  return (
    <InputContainer clientError={readableError || ''} isErroneous={!!readableError} label={label} htmlFor={name}>
      <DatePicker
        {...rest}
        name={name}
        onError={(muiError, value) => {
          const error = formatDateTimeValidationError(muiError, name || (typeof label === 'string' ? label : ''))
          setReadableError(error)
          if (onError) {
            onError({ value, error, muiError })
          }
        }}
      />
    </InputContainer>
  )
}
