import {
  createContext,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Button,
  ButtonProps,
  Card as BaseCard,
  CardProps as BaseCardProps,
  Collapse as BaseCollapse,
  CollapseProps as BaseCollapseProps,
} from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { mergeOverrideStyles } from 'components/utils/styles'
import { useToggle } from 'hooks'
import { Theme } from 'types/hb'

export const Context = createContext<ReturnType<typeof useToggle> & { rootId: string }>({
  rootId: '',
  value: false,
  setValue: () => {},
  toggle: () => {},
})

export type ExpandProps = {
  expanded: boolean
  setExpanded: (isExpanded: boolean) => void
  toggleExpanded: () => void
}

type ProviderProps = PropsWithChildren<
  {
    rootId: string
  } & Partial<ExpandProps>
>

/**
 * Wraps the CollapseCard in case any toggle/collapse content
 * needs to make use of the expanded state
 */
export const Provider = ({ children, expanded = false, rootId, setExpanded, toggleExpanded }: ProviderProps) => {
  const expandProps = useToggle(expanded, setExpanded, toggleExpanded)

  const { setValue, toggle, value } = expandProps

  const memoizedProps = useMemo(() => ({ rootId, setValue, toggle, value }), [rootId, setValue, toggle, value])

  useEffect(() => {
    setValue(expanded)
  }, [expanded, setValue])

  return <Context.Provider value={memoizedProps}>{children}</Context.Provider>
}

const useCardStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.styleguide.greyE}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    '&:first-of-type': {
      margin: 0,
    },
  },
}))

type CardProps = BaseCardProps

const defaultCardProps = {
  variant: 'outlined' as const,
} as Partial<BaseCardProps>

export const Card = forwardRef<HTMLDivElement, CardProps>(({ children, ...overrideCardProps }, ref) => {
  const { rootId } = useContext(Context)
  const baseCardClasses = useCardStyles()

  const { classes: overrideCardClasses, ...restOverrideCardProps } = overrideCardProps
  const classes = mergeOverrideStyles(baseCardClasses, overrideCardClasses)

  return (
    <BaseCard ref={ref} id={`${rootId}_card`} {...defaultCardProps} classes={classes} {...restOverrideCardProps}>
      {children}
    </BaseCard>
  )
})

const useExpandIconStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 20,
    height: 20,
    borderRadius: theme.shape.roundedRadius,
    color: theme.palette.text.primary,
    transition: 'background 0.125s ease-in-out',
  },
}))

interface ExpandIconProps {
  classes?: Partial<ReturnType<typeof useExpandIconStyles>>
}

export const ExpandIcon = ({ classes: overrideClasses }: ExpandIconProps) => {
  const contextProps = useContext(Context)
  const baseClasses = useExpandIconStyles()
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)
  const Icon = contextProps.value ? ExpandLess : ExpandMore
  return <Icon className={classNames('expandIcon', classes.root)} />
}

const useToggleStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    color: theme.palette.text.primary,
    '&:focus': {
      outline: 'none',
      background: 'none',
    },
    '&:hover': {
      background: 'initial',
    },
    '&:focus:hover': {
      background: 'none',
    },

    // on hover, highlight the expand icon
    '&:hover .expandIcon, &:focus .expandIcon': {
      background: theme.palette.background.contrastLight,
    },
  },
}))

type ToggleProps = ButtonProps

const defaultToggleProps = {
  disableRipple: true,
  fullWidth: true,
} as Partial<ToggleProps>

export const Toggle = ({ children, ...overrideToggleProps }: ToggleProps) => {
  const { rootId, toggle, value: isExpanded } = useContext(Context)

  const baseToggleClasses = useToggleStyles()

  const { classes: overrideToggleClasses, onClick: onToggleClick, ...restToggleProps } = overrideToggleProps
  const toggleClasses = mergeOverrideStyles<NonNullable<ButtonProps['classes']>>(
    baseToggleClasses,
    overrideToggleClasses
  )

  const handleToggleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (onToggleClick) onToggleClick(e)
      toggle()
    },
    [toggle, onToggleClick]
  )

  // id-based accessibility attributes
  const idAttrs = rootId
    ? {
        id: `${rootId}_toggle`,
        'aria-controls': `${rootId}_content`,
        'aria-expanded': isExpanded,
      }
    : {}

  return (
    <Button
      {...idAttrs}
      tabIndex={0}
      classes={toggleClasses}
      {...defaultToggleProps}
      {...restToggleProps}
      onClick={handleToggleClick}
    >
      {children}
    </Button>
  )
}

type CollapseProps = PropsWithChildren<
  BaseCollapseProps & {
    className?: string
  }
>

export const Collapse = ({ children, className, ...collapseProps }: CollapseProps) => {
  const { rootId, value: isExpanded } = useContext(Context)

  // id-based accessibility props
  const idAttrs = rootId
    ? {
        id: `${rootId}_content`,
        'aria-labelledby': `${rootId}_toggle`,
      }
    : {}

  return (
    <BaseCollapse {...collapseProps} in={isExpanded}>
      <div role="region" {...idAttrs} className={className}>
        {children}
      </div>
    </BaseCollapse>
  )
}

export interface CollapseCardProps {
  cardProps?: Omit<CardProps, 'children'> & { ref?: ForwardedRef<HTMLDivElement> }
  collapseContent: ReactNode
  collapseProps?: Omit<CollapseProps, 'children'>
  expandIconProps?: ExpandIconProps
  providerProps?: Omit<ProviderProps, 'children' | 'rootId'>
  rootId: string
  toggleContent: ReactNode
  toggleProps?: Omit<ToggleProps, 'children'>
}

const CollapseCard = ({
  cardProps,
  collapseContent,
  collapseProps,
  expandIconProps: overrideExpandIconProps,
  providerProps,
  rootId,
  toggleContent,
  toggleProps: overrideToggleProps,
}: CollapseCardProps) => (
  <Provider rootId={rootId} {...providerProps}>
    <Card {...cardProps}>
      <Toggle {...overrideToggleProps}>
        {toggleContent}
        <ExpandIcon {...overrideExpandIconProps} />
      </Toggle>
      <Collapse unmountOnExit {...collapseProps}>
        {collapseContent}
      </Collapse>
    </Card>
  </Provider>
)

export default CollapseCard
