import { ChangeEvent, useState } from 'react'

import { useSnackbar } from 'notistack'

import { SubmitHandler } from 'react-hook-form'

import { useHistory } from 'react-router-dom'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useHbMutation } from 'hooks/ApolloHelpers'

import { AutomationActionType } from 'types/api'

import { FormSchemaForTemplate, FormSchemaReturnType } from '../editor/formSchema'
import { useAutomationTemplateForm } from '../editor/useAutomationRuleOrTemplateForm'

import { setActionParams } from '../editor/util'

import {
  DELETE_AUTOMATION_RULE_TEMPLATE,
  TOGGLE_AUTOMATION_RULE_TEMPLATE_QUERY,
} from './AutomationRuleTemplate.queries'
import {
  AutomationRuleTemplateQuery,
  DeleteAutomationRuleTemplateMutation,
  DeleteAutomationRuleTemplateMutationVariables,
  ToggleAutomationRuleTemplateMutation,
  ToggleAutomationRuleTemplateMutationVariables,
  UseToggleTemplateAutomationRuleTemplateFragment as UseToggleTemplateTemplate,
} from './__generated__/AutomationRuleTemplate.queries.generated'

export const useToggleTemplate = (automationRuleTemplate: UseToggleTemplateTemplate) => {
  const { enqueueSnackbar } = useSnackbar()
  const usage = useUsage()

  const [toggleTemplate, { loading }] = useHbMutation<
    ToggleAutomationRuleTemplateMutation,
    ToggleAutomationRuleTemplateMutationVariables
  >(TOGGLE_AUTOMATION_RULE_TEMPLATE_QUERY)

  const handleToggleTemplate = async (ev: ChangeEvent<HTMLInputElement>) => {
    const enabled = ev.target.checked
    const result = await toggleTemplate({
      variables: {
        input: {
          token: automationRuleTemplate.token,
          enabled,
        },
      },
    })
    if (result.errors) {
      enqueueSnackbar('Sorry, there was an error changing the visibility of this template', { variant: 'error' })
    } else {
      usage.logEvent({
        name: 'automations:templateVisibility:updated',
        data: {
          automationRuleTemplateToken: automationRuleTemplate.token,
          visible: enabled,
        },
      })
      enqueueSnackbar(`Template visibility turned ${enabled ? 'on' : 'off'}`, {
        variant: enabled ? 'success' : 'default',
      })
    }
  }

  return { handleToggleTemplate, loading }
}

export const useFormHandlers = (automationRuleTemplate: AutomationRuleTemplateQuery['automationRuleTemplate']) => {
  const { form, handleSaveAutomationTemplate } = useAutomationTemplateForm(automationRuleTemplate)
  const usage = useUsage()
  const { handleSubmit, formState } = form
  const { isSubmitting } = formState

  const [deleteTemplate, { loading: deleteIsLoading }] = useHbMutation<
    DeleteAutomationRuleTemplateMutation,
    DeleteAutomationRuleTemplateMutationVariables
  >(DELETE_AUTOMATION_RULE_TEMPLATE)
  const history = useHistory()

  const loading = isSubmitting || deleteIsLoading

  const handleSave: SubmitHandler<FormSchemaForTemplate> = async (values) => {
    await handleSaveAutomationTemplate(values)
  }

  const { enqueueSnackbar } = useSnackbar()

  const handleDeleteTemplate = async () => {
    const result = await deleteTemplate({
      variables: {
        input: {
          token: automationRuleTemplate.token,
        },
      },
    })
    if (result.errors) {
      enqueueSnackbar('Sorry, there was an error deleting this template', { variant: 'error' })
    } else {
      enqueueSnackbar('Template successfully deleted', { variant: 'success' })
      history.push('/automations/recipes')
    }
  }

  const handleSubmitAndSave = handleSubmit(handleSave)

  const [actionModalOpen, setActionModalOpen] = useState(false)

  const handleSelectAction = (newActionType: AutomationActionType) => {
    setActionModalOpen(false)
    setActionParams({ newActionType, form: form as FormSchemaReturnType })
    usage.logEvent({
      name: 'automations:editorChangeAction:clicked',
      data: { automationRuleTemplateToken: automationRuleTemplate.token, newAction: newActionType },
    })
  }

  return {
    handleDeleteTemplate,
    handleSelectAction,
    handleSubmitAndSave,
    setActionModalOpen,
    actionModalOpen,
    form,
    loading,
  }
}
